import React, { ErrorInfo, Fragment } from 'react';
import AlertSnackbar from '../components/AlertSnackbar';

interface IProps {
    children: React.ReactNode
}

interface IState {
    error?: Error,
    errorInfo?: ErrorInfo
}

// This component allows to show error in the page instead of displaying white page
export class ErrorBoundary extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { error: undefined , errorInfo: undefined };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    handleClose() {
        this.setState({
            error: undefined,
            errorInfo: undefined
        }); 
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <AlertSnackbar 
                    open={this.state.error!= undefined} 
                    handleClose={this.handleClose} 
                    message={this.state.error?.message ?? ""}
                    detail={this.state.error?.stack ?? ""}
                />
            );
        }
        return this.props.children;
    }
}