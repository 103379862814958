import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useStorageState<T>(storageKey: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    const storedValue = sessionStorage.getItem(storageKey);
    const defaultState = storedValue != null ? JSON.parse(storedValue) as T : defaultValue;
    const [value, setValue] = useState<T>(defaultState);

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}

export function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

// export function useValidState(): [boolean, (key: string, value: boolean) => void] {
//     setMenuIsOpen({ ...menuIsOpen, [property]: !menuIsOpen[property] });
//     const [valid, setValid] = useState<{[key: string]: boolean}>({});

//     const isValid = () => {
//         return true;
//     }

//     const updateValid = (key: string, value: boolean) => {
//         const newState = valid;
//         newState[key] = value;
//         setValid({...valid, });
//     }

//     return [isValid(), updateValid];
// }