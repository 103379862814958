import { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { ulid } from 'ulid'
import throttle from 'lodash/throttle';

import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { handleTextChange } from "../../functions/fieldChangeHandlers";
import { Contract, ContractForfait, PackageCost, PriceLevelMode } from "../../models/Contract";
import ContractService from "../../services/ContractService";
import ChipInput from "../../components/ChipInput";
import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";
import ContractPackageCost from "./ContractPackageCost";
import { Enterprise } from "../../models/Enterprise";
import ReferenceService from "../../services/ReferenceService";
import ContractForfaitItem from "./ContractForfait";

interface IProps {
    open: boolean;
    handleClose: (result: boolean) => void
    contract?: Contract
}

const defaultPackageCost : PackageCost = {
  id: "standard",
  name: "Standard",
  clientDeliveryCosts: [{level: undefined, amount: undefined}],
  relayDeliveryCosts: [{level: undefined, amount: undefined}],
  clientPickupCosts: [{level: undefined, amount: undefined}],
  relayPickupCosts: [{level: undefined, amount: undefined}]
}

const ContractDialog = ({open, handleClose, contract} : IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [siret, setSiret] = useState('');
    // const [forfait, setForfait] =  useState<number | undefined>(undefined);
    // const [forfaitMode, setForfaitMode] =  useState<ForfaitMode | undefined>("None");
    const [forfaits, setForfaits] =  useState<ContractForfait[]>()
    const [priceLevelMode, setPriceLevelMode] =  useState<PriceLevelMode | undefined>("None");
    const [tours, setTours] = useState<string[]>([]);
    const [packageCosts, setPackageCosts] = useState<PackageCost[]>([defaultPackageCost]);

    //const [siret, setSiret] = useState<Enterprise | null>(null);
    //const [siretInputValue, setSiretInputValue] = useState('');

    const [enterprise, setEnterprise] = useState<Enterprise | null>(null);
    const [enterpriseName, setEnterpriseName] = useState('');
    const [enterpriseOptions, setEnterpriseOptions] = useState<Enterprise[]>([]);

    const showToursWarning = tours.length > (contract !== undefined ? contract.tours.length : 0);

    useEffect(() => {
      if(contract){
        setEnterpriseName(contract.name);
        setEmail(contract.email);
        setAddress(contract.address);
        setSiret(contract.siret);
        setForfaits(contract.forfaits);
        setPriceLevelMode(contract.priceLevelMode);
        setPackageCosts(contract.packageCosts);
        setTours(contract.tours?.map(t => t.code) ?? []);
      } else {
        razFields();
      }
    }, [contract]);

    const handleDismiss = () => {
        handleClose(false);
    }

    const handleDelete = () => {
        if(contract) {
          ContractService.deleteContract(contract.id);
          handleClose(true);
        }
    }

    const handleValidate = () => {
      if (contract) {
        return ContractService.updateContract({
          id: contract.id,
          clientId: contract.clientId,
          name: enterpriseName,
          email: email,
          address: address,
          siret: siret,
          packageCosts: packageCosts,
          forfaits: forfaits,
          priceLevelMode: priceLevelMode,
          tours: tours.map(t => ({code: t})),
          deleted: false
        }).then(() => {
          razFields();
          handleClose(true);
        });
      } else {
        return ContractService.addContract({
            id: '',
            clientId: '',
            name: enterpriseName,
            email: email,
            address: address,
            siret: siret,
            packageCosts: packageCosts,
            forfaits: forfaits,
            priceLevelMode: priceLevelMode,
            tours: tours.map(t => ({code: t})),
            deleted: false
        }).then(() => {
            razFields();
            handleClose(true);
        });
      }
    }

    const fetchEnterprises = useMemo(
      () =>
        throttle(
          (
            request: { input: string },
            callback: (results: Enterprise[]) => void,
          ) => {
            ReferenceService.searchEnterprise(request.input).then(callback);
          },
          200,
        ),
      [],
    );

    useEffect(() => {
      let active = true;
  
      if (enterpriseName === '') {
        setEnterpriseOptions(enterprise ? [enterprise] : []);
        return undefined;
      }
  
      fetchEnterprises({ input: enterpriseName }, (results: Enterprise[]) => {
        if (active) {
          let newOptions: Enterprise[] = [];
  
          if (enterprise) {
            newOptions = [enterprise];
          }
  
          if (results) {
            newOptions = [...newOptions, ...results];
          }
  
          setEnterpriseOptions(newOptions);
        }
      });
  
      return () => {
        active = false;
      };
    }, [enterprise, enterpriseName, fetch]);

    const razFields = () => {
        setEnterprise(null);
        setEnterpriseName('');
        setEmail('');
        setAddress('');
        setSiret('');
        setTours([]);
        setForfaits([]);
        setPriceLevelMode("None");
        setPackageCosts([defaultPackageCost]);
    }

    const onUpdatePackageCost = (packageCost: PackageCost) => {
      const idx = packageCosts.findIndex(p => p.id == packageCost.id);
      if(idx > -1)
      {
        const newArray = [...packageCosts];
        newArray.splice(idx, 1, packageCost);
        setPackageCosts(newArray);
      }
    }

    const onDeletePackageCost = (id: string) => {
      const idx = packageCosts.findIndex(p => p.id == id);
      if(idx > -1)
      {
        const newArray = [...packageCosts];
        newArray.splice(idx, 1);
        setPackageCosts(newArray);
      }
    }

    const onAddPackageCost = () => {
      const newPackageCost : PackageCost = {
        id: ulid(),
        name: "",
        clientDeliveryCosts: [{level: undefined, amount: undefined}],
        relayDeliveryCosts: [{level: undefined, amount: undefined}],
        clientPickupCosts: [{level: undefined, amount: undefined}],
        relayPickupCosts: [{level: undefined, amount: undefined}]
      }

      setPackageCosts([...packageCosts, newPackageCost]);
    }

    const onUpdateForfait = (idx: number, forfait: ContractForfait) => {
      if(forfaits && idx > -1)
      {
        const newArray = [...forfaits];
        newArray.splice(idx, 1, forfait);
        setForfaits(newArray);
      }
    }

    const onDeleteForfait = (idx: number) => {
      if(forfaits && idx > -1)
      {
        const newArray = [...forfaits];
        newArray.splice(idx, 1);
        setForfaits(newArray);
      }
    }

    const onAddForfait = () => {
      const newForfait : ContractForfait = {
        name: "",
        kind: "Tour",
        amount: 0
      }

      if(forfaits)
      {
        setForfaits([...forfaits, newForfait]);
      } else {
        setForfaits([newForfait]);
      }
    }

    
    const handleChangePriceLevelMode = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPriceLevelMode(((event.target as HTMLInputElement).value as unknown) as PriceLevelMode);
    }

    return (<Dialog 
      open={open}
      onClose={handleDismiss}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
        <DialogTitle>
          {intl.formatMessage({id: contract !== undefined ? 'contract.edit' :'contract.add'})}
          {contract !== undefined && <IconButton
            aria-label="delete"
            onClick={handleDelete}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <DeleteIcon />
        </IconButton>}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="name"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            renderOption={(props, option) => <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {typeof(option) === "string"  ? option : `${option.name} - ${option.id}`}
              </Box>}
            filterOptions={(x) => x}
            options={enterpriseOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            freeSolo
            value={enterpriseName}
            onChange={(event: any, newValue: string | Enterprise | null) => {
              if(typeof newValue !== 'string')
              {
                setEnterpriseOptions(newValue ? [newValue, ...enterpriseOptions] : enterpriseOptions);
                setEnterprise(newValue);
  
                if(newValue && siret === "") {
                  setSiret(newValue.id);
                }
                if(newValue && address === "") {
                  setAddress(newValue.address);
                }
              }
            }}
            onInputChange={(event, newInputValue) => {
              setEnterpriseName(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" fullWidth margin="dense" label={intl.formatMessage({id: 'contract.name'})} />
            )}
          />
          <TextField
            margin="dense"
            id="email"
            label={intl.formatMessage({id: 'contract.email'})}
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleTextChange(setEmail)}
          />
          <TextField
            margin="dense"
            id="address"
            label={intl.formatMessage({id: 'contract.address'})}
            type="address"
            fullWidth
            variant="standard"
            value={address}
            onChange={handleTextChange(setAddress)}
          />
          <TextField
            margin="dense"
            id="siret"
            label={intl.formatMessage({id: 'contract.siret'})}
            fullWidth
            variant="standard"
            value={siret}
            onChange={handleTextChange(setSiret)}
          />
          {/* <Autocomplete
            id="siret"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.siret + " - " + option.name
            }
            filterOptions={(x) => x}
            options={siretOptions}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={siret}
            onChange={(event: any, newValue: Enterprise | null) => {
              setSiretOptions(newValue ? [newValue, ...siretOptions] : siretOptions);
              setSiret(newValue);

              if(newValue && name === "") {
                setName(newValue.name);
              }
              if(newValue && address === "") {
                setAddress(newValue.address);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setSiretInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" fullWidth margin="dense" label={intl.formatMessage({id: 'contract.siret'})} />
            )}
          /> */}
          {/* <FormControl>
            <FormLabel id="forfait-mode">{intl.formatMessage({ id: 'contract.forfaitMode' })}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="forfait-mode"
              name="row-forfait-mode"
              value={forfaitMode}
              onChange={handleChangeForfaitMode}
            >
              <FormControlLabel value="None" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.none' })} />
              <FormControlLabel value="Contract" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.contract' })} />
              <FormControlLabel value="Tour" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.tour' })} />
            </RadioGroup>
          </FormControl>
          { forfaitMode && forfaitMode !== "None" && <TextField
              margin="dense"
              id="forfait"
              label={intl.formatMessage({ id: 'contract.forfait' })}
              type="number"
              fullWidth
              variant="standard"
              value={forfait}
              onChange={handleNumberChange(setForfait)}
            />
          } */}
          <Divider sx={{m: 3}}>
            {intl.formatMessage({id: 'contract.forfaits'})}
            {forfaits && forfaits.length > 0 && <IconButton aria-label="delete" size="small" onClick={() => onDeleteForfait(0)}>
              <RemoveCircleIcon/>
            </IconButton>}
          </Divider>
          {forfaits && forfaits.map((f, idx) => <ContractForfaitItem key={`forfait-${idx}`} idx={idx} handleUpdate={onUpdateForfait} handleDelete={onDeleteForfait} forfait={f}/>)}
          <Divider sx={{m: 3}}>
          <IconButton aria-label="add" size="small" onClick={onAddForfait}>
            <AddCircleIcon/>
          </IconButton>
          </Divider>
          <FormControl>
            <FormLabel id="pricelevel-mode">{intl.formatMessage({ id: 'contract.priceLevelMode' })}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="pricelevel-mode"
              name="row-pricelevel-mode"
              value={priceLevelMode}
              onChange={handleChangePriceLevelMode}
            >
              <FormControlLabel value="None" control={<Radio />} label={intl.formatMessage({ id: 'contract.priceLevelMode.none' })} />
              <FormControlLabel value="Monthly" control={<Radio />} label={intl.formatMessage({ id: 'contract.priceLevelMode.monthly' })} />
              <FormControlLabel value="Daily" control={<Radio />} label={intl.formatMessage({ id: 'contract.priceLevelMode.daily' })} />
              <FormControlLabel value="Tour" control={<Radio />} label={intl.formatMessage({ id: 'contract.priceLevelMode.tour' })} />
            </RadioGroup>
          </FormControl>
          <Divider sx={{m: 3}}>{intl.formatMessage({id: 'contract.packageKinds'})}</Divider>
          {packageCosts.map((p) => <ContractPackageCost key={p.id} handleUpdate={onUpdatePackageCost} handleDelete={onDeletePackageCost} packageCost={p}/>)}
          <Divider sx={{m: 3}}>
          <IconButton aria-label="delete" size="small" onClick={onAddPackageCost}>
            <AddCircleIcon/>
          </IconButton>
          </Divider>
          <ChipInput
            label={intl.formatMessage({id: 'contract.tours'})}
            value={tours}
            onValueChanged={(tours) => setTours(tours)}
            textFieldProps={{
              id: "tours",
              margin: "dense",
              label: intl.formatMessage({ id: "contract.tours" }),
              variant:"standard",
              fullWidth: true
            }}
          />
          {showToursWarning && <Alert severity="warning">{intl.formatMessage({ id: "contract.tours.warning" })}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismiss}>{intl.formatMessage({id: 'cancel'})}</Button>
          <AsyncButton onClick={handleValidate}>{intl.formatMessage({id: 'ok'})}</AsyncButton>
        </DialogActions>
      </Dialog>);
}

export default ContractDialog;