import { IntlShape } from "react-intl";
import { Period } from "../models/Period";

export function getMonday(d: Date) {
    const date = new Date(d);
    var day = date.getDay(),
        diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(date.setDate(diff));
}

export function getStartDate(period: Period) {
    const date = new Date();
    date.setHours(0,0,0,0);
    
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() -1);
    yesterday.setHours(0,0,0,0);

    const startOfmonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return period == "month" ? startOfmonth : 
        period == "week" ? getMonday(date): 
        period == "yesterday" ? yesterday:
        date;
}


export function getDayLabel(intl: IntlShape, day: Day) {
    const date = new Date(0);
    date.setDate(4 + day);
    return intl.formatDate(date, {weekday: "short"})
}

export function getStartOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1, 2);
}

export function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function toLocalDate(date: Date)
{
    const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
    date.setHours(hoursDiff);
    return date;
}
