import React, { Fragment, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { IconButton,  Stack, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { UserContext } from "../../functions/userContext";
import { hasAccountAccess } from "../../functions/userFunctions";

import { Account } from "../../models/Account";
import AccountService from "../../services/AccountService";

const AccountPage = () => {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();

    const user = useContext(UserContext);
    const [account, setAccount] = useState<Account>();

    useEffect(() => {
        loadAccount();
    }, []);

    const handleAccountUpdate = () => {
        loadAccount();
    }

    const loadAccount = () => {
        AccountService.get().then(setAccount);
    }

    return (
        <Fragment>
            {(location.pathname !== "/account") && <Stack direction="row">
                <IconButton onClick={() => navigate("/account")}>
                    <ChevronLeftIcon/>
                </IconButton>
                <Typography variant="subtitle2" sx={{alignSelf: "center"}}>{intl.formatMessage({id: 'account.informations'})}</Typography>
                </Stack>}
            <Outlet context={[account, handleAccountUpdate]}/>
        </Fragment>);
}


export default AccountPage;