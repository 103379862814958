import React, { Fragment, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import List from '@mui/material/List';
import { Divider } from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import EuroIcon from '@mui/icons-material/Euro';
import PieChartIcon from '@mui/icons-material/PieChart';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { useStorageState } from '../../functions/customHooks';
import Link from '../../components/Link';
import UserService from '../../services/UserService';

interface ILinksProps {
    currentPath: string;
    onClick?: VoidFunction
}

const Links = ({ currentPath, onClick }: ILinksProps) => {
    const [menuIsOpen, setMenuIsOpen] = useStorageState<{ [k: string]: boolean }>('menuState', {});

    const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
        const property = e.currentTarget.name;
        setMenuIsOpen({ ...menuIsOpen, [property]: !menuIsOpen[property] });
    };

    return (
        <Fragment>
            <List>
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={HomeOutlinedIcon}
                    label={<FormattedMessage id="nav.home" />}
                    roles={["Administrator", "Manager"]}
                    to="/"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={FolderOutlinedIcon}
                    label={<FormattedMessage id="nav.contracts" />}
                    roles={["Administrator", "Manager"]}
                    to="/contracts"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={PeopleOutlineIcon}
                    label={<FormattedMessage id="nav.people" />}
                    roles={["Administrator", "Manager", "Contributor"]}
                    to="/people"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={LocalShippingIcon}
                    label={<FormattedMessage id="nav.fleet" />}
                    roles={["Administrator", "Manager", "Contributor"]}
                    to="/fleet"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={TodayIcon}
                    label={<FormattedMessage id="nav.activity" />}
                    to="/activity"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={EuroIcon}
                    label={<FormattedMessage id="nav.expenses" />}
                    roles={["Administrator", "Manager", "Contributor"]}
                    to="/expenses"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={DateRangeIcon}
                    label={<FormattedMessage id="nav.planning" />}
                    roles={["Administrator", "Manager"]}
                    subscriptions={["pro", "privilege", "gold"]}
                    to="/planning"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={PieChartIcon}
                    label={<FormattedMessage id="nav.reports" />}
                    roles={["Administrator", "Manager"]}
                    to="/reports"
                />
            </List>
            <List sx={{position: "fixed", bottom: 0, width: 240}}>
                <Divider />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={HelpIcon}
                    label={<FormattedMessage id="nav.support" />}
                    to="/support"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={PeopleIcon}
                    label={<FormattedMessage id="nav.users" />}
                    roles={["Administrator", "Manager"]}
                    to="/users"
                />
                <Link
                    onClick={onClick}
                    currentPath={currentPath}
                    icon={SettingsIcon}
                    label={<FormattedMessage id="nav.account" />}
                    roles={["Administrator"]}
                    to="/account"
                />
                <Link
                    onClick={() => UserService.logout()}
                    currentPath={currentPath}
                    icon={LogoutIcon}
                    label={<FormattedMessage id="nav.logout" />}
                    disabled
                    to="/"
                />
            </List>
        </Fragment>
    );
};

export default Links;