
import { Box, CircularProgress, StyledEngineProvider } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

import { UserContext } from '../functions/userContext';
import { User } from '../models/User';
import AccountService from '../services/AccountService';
import UserService from '../services/UserService';
import SellTermsDialog from './Terms/SellTermsDialog';
import UsageTermsDialog from './Terms/UsageTermsDialog';


export const Configuration = (props: { children: JSX.Element }) => {
    const [fetchDone, setFetchDone] = useState(false);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        UserService.get()
        .then(res => {
            setUser(res);
            setFetchDone(true);
        });
    }, []);

    const Loader = () => (
        <Box sx={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <CircularProgress/>
        </Box>);
    
    const handleCloseUsageTerms = (accept: boolean) => {

    }
    
    const handleCloseSellTerms = (accept: boolean) => {
        if(!accept)
        {
            UserService.logout();
        }
        else
        {
            UserService.acceptCGV().then(() => {
                UserService.get().then(res => setUser(res))
            })
        }
    }

    const UserProvider = () => {
        return (
            <UserContext.Provider value={user}>
                {props.children}
                {user && user.isLoggedIn && (<Fragment>
                    {/* <UsageTermsDialog open={!user.hasAcceptedUsageTerms} handleClose={handleCloseUsageTerms}/> */}
                    <SellTermsDialog open={!user.hasAcceptedSellTerms} handleClose={handleCloseSellTerms}/>
                </Fragment>)}
            </UserContext.Provider>
        )
        ;
    }

    return fetchDone ? UserProvider() : <Loader/>;
};