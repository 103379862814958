import { Enterprise } from "../models/Enterprise";


export class ReferenceService
{
    static async searchEnterprise(query: string) {
        const response = await fetch(`/api/reference/enterprise?q=` +  query);
        return await response.json() as Enterprise[];
    }
}

export default ReferenceService;