import { Account, Import } from "../models/Account";


export class AccountService
{
    static async get() {
        const response = await fetch(`/api/account`);
        return await response.json() as Account;
    }
    
    static async updateAccount(data: Account) {
        await fetch("/api/account", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async updateAccountImports(data: Import[]) {
        await fetch("/api/account/imports", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    
}

export default AccountService;