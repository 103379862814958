import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, CardActions, CardContent, CardHeader, CircularProgress, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";

interface IProps {
    title: string;
    subtitle?: string;
    objective: number;
    succesRatio: number;
    errorRatio: number;
    ariaLabel?: string;
    value: number;
    valueUnit?: string;
    label?: string;
}

const GaugeCard = ({ ariaLabel, title, subtitle, objective, value, succesRatio, errorRatio, label, valueUnit}: IProps) => {
    const theme = useTheme();

    const currentRatio = value / Math.max(1, objective);
    const progressColor =  currentRatio >= succesRatio ? "success" : (currentRatio <= errorRatio ? "error": "warning");
    const progressValue = Math.min(100, 100 * currentRatio);

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
                subheader={subtitle}
            />
            <CardContent>
                <Stack justifyContent="center" alignItems="center" justifyItems="center">
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress size={200} thickness={2} variant="determinate" value={progressValue} color={progressColor} />
                        <Box
                            sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="text.secondary">{`${value}${valueUnit ? valueUnit : ""}`}</Typography>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary">{`${label}`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default GaugeCard;
