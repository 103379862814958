import { Alert, styled, Theme, Typography } from '@mui/material';
import React, { Fragment, ReactNode, useContext, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { UserContext } from '../../functions/userContext';

import AppBar from './AppBar';
import Nav from './Nav';
import ToolbarDiv from './ToolbarDiv';

interface IProps {
    title?: string;
    children: ReactNode;
}

const FlexDiv = styled('div')(() => ({
    display: 'flex'
}));

const Main = styled('main')(({theme}) => ({
    flexGrow: 1,
    padding: theme.spacing(3)
}));

const AccountFooterAlert = () => {
    const intl = useIntl();
    const user = useContext(UserContext);
    const [opened, setOpened] = useState(true)

    const severity = user?.subscriptionStatus == "Inactive" ? "error" : "warning";

    return user?.subscriptionStatus == "Active" || !opened ? <Fragment/> : (
        <Alert sx={{position: 'fixed', justifySelf: 'center', bottom: 10, width: "80%"}} severity={severity} onClose={() => {setOpened(false)}}>
            <Typography variant='body1'>{intl.formatMessage({id: `footer.alert.${user?.subscriptionStatus?.toLowerCase()}`})}</Typography>
            <Link to={"/account/invoicing"}>{intl.formatMessage({id: `footer.alert.link`})}</Link>
        </Alert>);
}

const AppLayout = ({ children, title }: IProps) => {
    const [navIsOpen, setNavIsOpen] = useState(false);

    const toggleNav = (isOpen: boolean) => {
        setNavIsOpen(isOpen);
    };

    const barTitle = title ? "page." + title : "page.home";

    return (
        <FlexDiv>
            <AppBar title={<FormattedMessage id={barTitle} />} toggleNav={toggleNav} />
            <Nav isOpen={navIsOpen} toggle={toggleNav} />
            <Main sx={{maxWidth: "100%"}}>
                <ToolbarDiv/>
                {children ? children : null}
                <AccountFooterAlert/>
            </Main>
        </FlexDiv>);

}

export default AppLayout;