import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as _ from 'lodash';

import { CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, TextField } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Fab from "../../components/Fab";
import { useStorageState } from "../../functions/customHooks";
import PeopleService from "../../services/PeopleService";
import ContractService from "../../services/ContractService";
import VehicleService from "../../services/VehicleService";

import ExpenseDialog from "./ExpenseDialog";
import { People } from "../../models/People";
import { Contract } from "../../models/Contract";
import { Vehicle } from "../../models/Vehicle";
import { Expense } from "../../models/Expense";
import ExpenseService from "../../services/ExpenseService";

const ExpensePage = () => {
    const intl = useIntl();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [expenses, setExpenses] = useStorageState<Expense[]>('expenses', []);
    const [people, setPeople] = useState<People[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedExpense, setEditedExpense] = useState<Expense | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        PeopleService.get().then(setPeople);
        ContractService.get().then(setContracts);
        VehicleService.get().then(setVehicles);
    }, []);
    
    useEffect(() => {
        loadExpenses();
    }, [selectedDate]);

    const handleExpenseClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadExpenses();
        }
    }

    const loadExpenses = () => {
        setLoading(true);
        ExpenseService.get(selectedDate).then((data) => {
            setExpenses(data);
            setLoading(false);
        });
    }

    const deleteExpense = (expenseId: string) => {
        ExpenseService.deleteExpense(expenseId).then(() => loadExpenses());
    }

    const handleOpenDialog = (expense: Expense | undefined) => {
        setEditedExpense(expense);
        setOpenDialog(true);
    }
    
    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setSelectedDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + delta));
        setSelectedDate(newDate);
    }

    const ExpenseItem = (expense: Expense) => {

        return (<ListItem key={expense.id}>
            <ListItemButton onClick={() => handleOpenDialog(expense)}>
                <ListItemText primary={expense.name} secondary={expense.amount}/>
            </ListItemButton>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => deleteExpense(expense.id)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>);
    }

    return (
        <Fragment>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
                <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                    <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <MobileDatePicker
                    views={["month", "year"]}
                    label={intl.formatMessage({ id: 'expense.date' })}
                    inputFormat="MMMM yyyy"
                    value={selectedDate}
                    onChange={handleSelectedDateChange}
                    renderInput={(params: any) => <TextField {...params} />}
                />
                <IconButton aria-label="next"  onClick={() => handleChangeDate(1)}>
                    <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <List
                sx={{ width: '100%'}}
                component="nav">
                {expenses.map(ExpenseItem)}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <ExpenseDialog 
                open={openDialog}
                handleClose={handleExpenseClose}
                expense={editedExpense}
            />
        </Fragment>);
}


export default ExpensePage;