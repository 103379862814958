import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

import { AsyncButton }  from "../../components/AsyncButton";
import { TransitionUp } from "../../components/Transition";
import { handleTextChange, handleNumberChange, handleAutocompleteChange, handleSelectChange } from "../../functions/fieldChangeHandlers";
import { People, PeopleStatus } from "../../models/People";
import PeopleService from "../../services/PeopleService";
import { Contract } from "../../models/Contract";
import LoadingButton from "@mui/lab/LoadingButton";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  people?: People
  contracts: Contract[]
}

const PeopleDialog = ({ open, handleClose, people, contracts }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [name, setName] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [income, setIncome] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<PeopleStatus>("Employee");
  const [deliveryIncomes, setDeliveryIncomes] = useState<{[packageKind: string] : number} | undefined>({});
  const [contractId, setContractId] = useState<{id: string, label: string} | null>(null);

  const contractOptions = contracts.map(c => { return { id: c.id, label: c.name}});
  const [contractInput, setContractInput] = useState('');

  useEffect(() => {
    if(people){
      setName(people.name);
      setIncome(people.income);
      setDeliveryIncomes(people.deliveryIncomes);
      setContractId(contractOptions.find(p => p.id === people.contractId) ?? null);
      setStatus(people.status ?? "Employee")
    } else {
      razFields();
    }
  }, [people])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleDelete = () => {
    if(people) {
      setDeleteLoading(true);
      PeopleService.deletePeople(people.id).then(() => {
        setDeleteLoading(false);
        handleClose(true);
      })
    }
}

  const handleValidate = () => {
    if (people) {
      return PeopleService.editPeople({
        id: people.id,
        clientId: people.clientId,
        name: name,
        income: income,
        deliveryIncomes: deliveryIncomes,
        contractId: contractId?.id,
        status: status,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
    else {
      return PeopleService.addPeople({
        id: '',
        clientId: '',
        name: name,
        income: income,
        deliveryIncomes: deliveryIncomes,
        contractId: contractId?.id,
        status: status,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setName('');
    setIncome(undefined);
    setDeliveryIncomes(undefined);
    setContractId(null);
    setStatus("Employee")
  }

  const handleDeliveryIncomeKindChange = (idx: number, newKind: string, newValue: number) => {
    if (deliveryIncomes) {
      const keys = Object.keys(deliveryIncomes);
      keys[idx] = newKind;
      
      const newDeliveries : {[packageKind: string] : number} = {}

      keys.forEach((k, i) => {
        newDeliveries[k] = i === idx ? newValue : deliveryIncomes[k]
      });

      setDeliveryIncomes({...newDeliveries})
    } else {
      const newDeliveries : {[packageKind: string] : number} = {}
      newDeliveries[newKind] = newValue
      setDeliveryIncomes(newDeliveries)
    }
  }

  const handleDeliveryIncomeValueChange = (kind: string, newValue: number) => {
    if (deliveryIncomes) {;
      deliveryIncomes[kind] = newValue
      setDeliveryIncomes({...deliveryIncomes})
    } else {
      const newDeliveries : {[packageKind: string] : number} = {}
      newDeliveries[kind] = newValue
      setDeliveryIncomes(newDeliveries)
    }
  }

  const handleDeleteDeliveryIncome = (kind: string) => {
    if (deliveryIncomes) {
      delete deliveryIncomes[kind]
      setDeliveryIncomes({...deliveryIncomes})
    }
  }

  const DeliveryIncomeLine = (idx: number, kind: string, value: number, availablePackageKind: string[]) => {
    return <Stack direction="row" spacing={2}>
        <FormControl sx={{  width: 300 }}>
            <InputLabel id="deliveryIncomes">{intl.formatMessage({id: 'people.deliveryIncome.kind'})}</InputLabel>
            <Select
                labelId="deliveryIncomes"
                id="deliveryIncomes"
                fullWidth
                variant="standard"
                value={kind}
                onChange={(evt) => handleDeliveryIncomeKindChange(idx, evt.target.value, value)}>
              {availablePackageKind.map((n) => (
                  <MenuItem value={n}>{n}</MenuItem>
              ))}
            </Select>
        </FormControl>
        <TextField
          margin="dense"
          id="income"
          label={intl.formatMessage({ id: 'people.deliveryIncome.value' })}
          type="number"
          fullWidth
          variant="standard"
          value={value}
          onChange={(event) => {
            const val = event.target.value != '' ? (event.target.value as unknown) as number: 0;
            handleDeliveryIncomeValueChange(kind, val);
          }}
        />
        {idx > 0 && <IconButton onClick={() => handleDeleteDeliveryIncome(kind)}><ClearIcon/></IconButton>}
      </Stack>
  }

  const DeliveryIncomeFields = () => {

    const incomeTypes = deliveryIncomes ? Object.keys(deliveryIncomes) : []
    const availablePackageKind = contracts.flatMap(c => c.packageCosts.map(c => c.name))
        .filter((value, index, array) => array.indexOf(value) === index)

      const newLine = DeliveryIncomeLine(incomeTypes.length, '', 0, availablePackageKind)
      if (deliveryIncomes) {
          const lines = incomeTypes.map((t, idx) => DeliveryIncomeLine(idx, t, deliveryIncomes[t], availablePackageKind))
    
          return <Stack spacing={2}>
            {lines}
            {newLine}
          </Stack>
      }

    return newLine
  }

  return (<Dialog
      open={open}
      onClose={handleDismiss}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>
      {intl.formatMessage({ id: people !== undefined ? 'people.edit' :'people.add' })}
      {people !== undefined && <LoadingButton 
        aria-label="delete"
        loading={deleteLoading}
        onClick={handleDelete}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
        <DeleteIcon />
      </LoadingButton>}
    </DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label={intl.formatMessage({ id: 'people.name' })}
        fullWidth
        variant="standard"
        value={name}
        onChange={handleTextChange(setName)}
      />
      <TextField
        margin="dense"
        id="income"
        label={intl.formatMessage({ id: 'people.income' })}
        type="number"
        fullWidth
        variant="standard"
        value={income}
        onChange={handleNumberChange(setIncome)}
      />
      <FormControl fullWidth variant="standard" sx={{mt: 1}}>
        <InputLabel>{intl.formatMessage({ id: 'people.status' })}</InputLabel>
        <Select
            id="status"
            value={status}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'people.status' })}
            onChange={handleSelectChange(setStatus)}>
            <MenuItem value={'Employee'}>{intl.formatMessage({ id: 'people.status.employee' })}</MenuItem>
            <MenuItem value={'Contractor'}>{intl.formatMessage({ id: 'people.status.contractor' })}</MenuItem>
        </Select>
      </FormControl>
      <Autocomplete
        value={contractId}
        onChange={handleAutocompleteChange(setContractId)}
        inputValue={contractInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          setContractInput(newInputValue);
        }}
        id="contract"
        options={contractOptions}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'people.contractId' })} />}
      />
      {status === "Contractor" && (<Fragment>
        <Divider sx={{m: 3}}>{intl.formatMessage({ id: 'people.deliveryIncome' })}</Divider>
        {DeliveryIncomeFields()}
      </Fragment>)}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <AsyncButton onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</AsyncButton>
    </DialogActions>
  </Dialog>);
}

export default PeopleDialog;
