import { IntlShape } from "react-intl";

// export function ccyFormat(num: number) {
//     const result = typeof num === 'number' ? num.toFixed(2) : '' ;
//     return `${result}`;
// }

export function ccyFormat(num: number | undefined) {
    const appliedNum = num ?? 0;

    return appliedNum.toLocaleString('fr-FR', {
        style: "currency",
        currency: "EUR"
    });
}


export const formatDateOnly = (intl : IntlShape  , date: string | number | Date | undefined) => {
    return intl.formatDate(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}


export const formatDate = (intl : IntlShape  , date: string | number | Date | undefined) => {
    return intl.formatDate(date, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });
}

export const formatHours = (intl : IntlShape  , date: string | number | Date | undefined) => {
    return intl.formatDate(date, {
        hour: "numeric",
        minute: "numeric"
    });
}