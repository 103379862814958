import { Product } from "../models/Product";

export class ProductService
{
    static async get() {
        const response = await fetch(`/api/product`);
        return await response.json() as Product[];
    }
}

export default ProductService;