export class LanguageService
{
    static supportedLanguages = ["en", "fr", "es"];

    static get() {
        const storedLanguage = localStorage.getItem('uptolog_language') ;
        const userLanguage = storedLanguage ? storedLanguage : navigator.language.substr(0, 2);
        if(LanguageService.supportedLanguages.includes(userLanguage)) {
            return userLanguage;
        }

        return LanguageService.supportedLanguages[0];
    }

    static set(language: string) {
        localStorage.setItem('uptolog_language', language);
    }
}

export default LanguageService;