import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import * as _ from "lodash";

import {  Box, Button, CardActions, CardContent, CardHeader, LinearProgress, Stack, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";

import { ContractCAStat } from "../../models/Stats";
import { Period } from "../../models/Period";
import { getStartDate } from "../../functions/dateUtils";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    ariaLabel?: string;
    stats: ContractCAStat[];
}

const ContractCard = ({ ariaLabel, stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();

    const totalCA = Math.max(1, stats.reduce((s, c) => s + c.amount, 0));
    const totalMargin = Math.max(1, stats.reduce((s, c) => s + c.margin, 0));

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                {stats.length == 0 ? <Typography>{intl.formatMessage({id: "nodata"})}</Typography> : <></>}
                <Table  size="small" aria-label="simple table">
                    <TableBody>
                    {stats.map((stat) => {
                        return (
                        <TableRow key={stat.contractId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{minWidth: 35, maxWidth: 35, paddingLeft: 0}}>
                                <Button onClick={() => navigate(`/contract/${stat.contractId}`)} variant="text"><Typography sx={{textSizeAdjust: "auto"}}>{stat.contractName}</Typography></Button>
                            </TableCell>
                            <TableCell sx={{minWidth: 60, maxWidth: 60, paddingLeft: 2}} align="right">
                                <Stack>
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress value={stat.amount * 100 / totalCA} variant="determinate" />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{ccyFormat(stat.amount)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress value={stat.margin * 100 / totalMargin} color={stat.margin > 0 ? "success" : "error"} variant="determinate" />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography sx={{textSizeAdjust: "auto"}} variant="body2" color="text.secondary"><b>{ccyFormat(stat.margin)}</b></Typography>
                                        </Box>
                                    </Box>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )})}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default ContractCard;
