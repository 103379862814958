import { ForgotPasswordData, User, UserLoginData, UserRegisterData, ResetPasswordData, AddUserData, DeleteUserData } from "../models/User";

export class UserService
{
    static async get() {
        const response = await fetch(`/api/user/me`);
        return await response.json() as User;
    }

    static async loadAccountUsers() {
        const response = await fetch(`/api/user`);
        return await response.json() as User[];
    }

    static async addUser(data: AddUserData) {
        const response = await fetch(`/api/user`, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        return response;
    }

    static async editUserRole(data: AddUserData) {
        const response = await fetch(`/api/user/role`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        return response;
    }
    
    static async deleteUser(data: DeleteUserData) {
        const response = await fetch(`/api/user`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        return response;
    }

    static async register(data: UserRegisterData) {
        const response = await fetch("/api/user/register", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        if(response.ok) {
            window.location.replace('/login');
        }
    }

    static async login(data: UserLoginData) {
        const response = await fetch("/api/user/login", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        return response;
    }
    
    static async logout() {
        const response = await fetch("/api/user/logout", {
            method: "POST"
        });

        if(response.ok) {
            window.location.replace('/');
        }
    }

    static async forgotPassword(data: ForgotPasswordData) {
        const response = await fetch("/api/user/forgotPassword", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async resetPassword(data: ResetPasswordData) {
        const response = await fetch("/api/user/resetPassword", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        if(response.ok) {
            window.location.replace('/login');
        }
    }

    static async acceptCGV()
    {
        await fetch("/api/user/cgv", {
            method: "POST"
        });
    }
}

export default UserService;