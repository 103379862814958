import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import { Avatar, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Slide, Stack, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Fab from "../../components/Fab";
import UserService from "../../services/UserService";
import { User } from "../../models/User";
import { stringAvatar } from "../../functions/avatar";
import AddUserDialog from "./AddUserDialog";

const UsersPage = () => {
    const intl = useIntl();

    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedUser, setEditedUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        setLoading(true);
        UserService.loadAccountUsers().then((data) => {
            setUsers(data);
            setLoading(false);
        });
    }

    const handleUserClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadUsers();
        }
    }

    const deleteUser = (email: string) => {
        return UserService.deleteUser({email: email}).then(() => loadUsers());
    }

    const handleOpenDialog = (user: User | undefined) => {
        setEditedUser(user);
        setOpenDialog(true);
    }

    const UserItem = (user: User) => {
        return (<ListItem 
            key={user.email}
            secondaryAction={user.isRoot ? null : (<IconButton onClick={() => deleteUser(user.email)}><DeleteIcon /></IconButton>)}>
            <ListItemButton onClick={() => handleOpenDialog(user)} disabled={user.isRoot}>
                <ListItemAvatar>
                    <Avatar {...stringAvatar(user.fullname)} />
                </ListItemAvatar>
                <ListItemText primary={user.fullname} secondary={user.email}/>
            </ListItemButton>
        </ListItem>)
    }

    const administrators = users.filter(u => u.roles.includes("Administrator"));
    const managers = users.filter(u => u.roles.includes("Manager"));
    const contributors = users.filter(u => u.roles.includes("Contributor"));
    const members = users.filter(u => u.roles.includes("Member"));


    return (
    <Fragment>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box>
                <List
                    sx={{ width: '100%'}}
                    component="nav"
                    aria-labelledby="users">
                    <ListSubheader>{intl.formatMessage({ id: 'user.role.administrator'})}</ListSubheader>
                    {administrators.map(UserItem)}
                    {managers.length > 0 && <ListSubheader>{intl.formatMessage({ id: 'user.role.manager'})}</ListSubheader>}
                    {managers.map(UserItem)}
                    {contributors.length > 0 && <ListSubheader>{intl.formatMessage({ id: 'user.role.contributor'})}</ListSubheader>}
                    {contributors.map(UserItem)}
                    {members.length > 0 && <ListSubheader>{intl.formatMessage({ id: 'user.role.member'})}</ListSubheader>}
                    {members.map(UserItem)}
                </List>
            </Box>
        </Slide>
        <Fab disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
            {loading ? <CircularProgress /> : <AddIcon /> }
        </Fab>
        <AddUserDialog open={openDialog} user={editedUser} handleClose={handleUserClose} />
    </Fragment>);
}

export default UsersPage;