import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from "@mui/material";

import { handleTextChange, handleNumberChange, handleSelectChange, handleAutocompleteChange } from "../../functions/fieldChangeHandlers";
import { Vehicle, VehicleKind } from "../../models/Vehicle";
import VehicleService from "../../services/VehicleService";
import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";
import { Contract } from "../../models/Contract";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  vehicle?: Vehicle
  contracts: Contract[]
}

const VehicleDialog = ({ open, handleClose, vehicle, contracts }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [type, setType] = useState('');
  const [kind, setKind] = useState<VehicleKind>('Gasoline');
  const [matriculation, setMatriculation] = useState<string>('');
  const [ownership, setOwnership] = useState('Rental');
  const [rentalcost, setRentalCost] = useState<number | undefined>(undefined);
  const [insurance, setInsurance] = useState<number | undefined>(undefined);
  const [contractId, setContractId] = useState<{id: string, label: string} | null>(null);

  const contractOptions = contracts.map(c => { return { id: c.id, label: c.name}});
  const [contractInput, setContractInput] = useState('');

  useEffect(() => {
    if(vehicle){
      setType(vehicle.type);
      setKind(vehicle.kind);
      setMatriculation(vehicle.matriculation ?? '');
      setOwnership(vehicle.ownership);
      setRentalCost(vehicle.rentalcost);
      setInsurance(vehicle.insurance);
      setContractId(contractOptions.find(p => p.id === vehicle.contractId) ?? null);
    } else {
      razFields();
    }
  }, [vehicle])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    if (vehicle) {
      return VehicleService.editVehicle({
        id: vehicle.id,
        clientId: vehicle.clientId,
        type: type,
        kind: kind,
        matriculation: matriculation,
        ownership: ownership,
        rentalcost: rentalcost,
        insurance: insurance,
        contractId: contractId?.id,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
    else {
      return VehicleService.addVehicle({
        id: '',
        clientId: '',
        type: type,
        kind: kind,
        matriculation: matriculation,
        ownership: ownership,
        rentalcost: rentalcost,
        insurance: insurance,
        contractId: contractId?.id,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setType('');
    setMatriculation('');
    setKind('Gasoline');
    setOwnership('Rental');
    setRentalCost(undefined);
    setInsurance(undefined);
    setContractId(null);
  }

  return (<Dialog 
    open={open}
    onClose={handleDismiss}
    fullScreen={fullScreen}
    TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: vehicle !== undefined ? 'vehicle.edit' :'vehicle.add' })}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        id="type"
        label={intl.formatMessage({ id: 'vehicle.type' })}
        fullWidth
        variant="standard"
        value={type}
        onChange={handleTextChange(setType)}
      />
      <FormControl fullWidth variant="standard" sx={{mt: 1}}>
        <InputLabel>{intl.formatMessage({ id: 'vehicle.kind' })}</InputLabel>
        <Select
            id="kind"
            value={kind}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'vehicle.kind' })}
            onChange={handleSelectChange(setKind)}>
            <MenuItem value={'Gasoline'}>{intl.formatMessage({ id: 'vehicle.kind.gasoline' })}</MenuItem>
            <MenuItem value={'Electric'}>{intl.formatMessage({ id: 'vehicle.kind.electric' })}</MenuItem>
            <MenuItem value={'CargoBike'}>{intl.formatMessage({ id: 'vehicle.kind.cargobike' })}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        id="matriculation"
        label={intl.formatMessage({ id: 'vehicle.matriculation' })}
        fullWidth
        variant="standard"
        value={matriculation}
        onChange={handleTextChange(setMatriculation)}
      />
      <FormControl fullWidth variant="standard">
        <InputLabel>{intl.formatMessage({ id: 'vehicle.ownership' })}</InputLabel>
        <Select
            id="ownership"
            value={ownership}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'vehicle.ownership' })}
            onChange={handleSelectChange(setOwnership)}
          >
            <MenuItem value={'Rental'}>{intl.formatMessage({ id: 'vehicle.ownership.rental' })}</MenuItem>
            <MenuItem value={'Owner'}>{intl.formatMessage({ id: 'vehicle.ownership.owner' })}</MenuItem>
        </Select>
      </FormControl>
      
      {ownership == 'Rental' && <TextField
        margin="dense"
        id="income"
        label={intl.formatMessage({ id: 'vehicle.rentalcost' })}
        type="number"
        fullWidth
        variant="standard"
        value={rentalcost}
        onChange={handleNumberChange(setRentalCost)}
      />}
      <TextField
        margin="dense"
        id="income"
        label={intl.formatMessage({ id: 'vehicle.insurance' })}
        type="number"
        fullWidth
        variant="standard"
        value={insurance}
        onChange={handleNumberChange(setInsurance)}
      />
      
      <Autocomplete
        value={contractId}
        onChange={handleAutocompleteChange(setContractId)}
        inputValue={contractInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          setContractInput(newInputValue);
        }}
        id="contract"
        options={contractOptions}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'people.contractId' })} />}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <AsyncButton onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</AsyncButton>
    </DialogActions>
  </Dialog>);
}

export default VehicleDialog;