import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Checkbox, CircularProgress, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WarningIcon from '@mui/icons-material/Warning';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';

import { ContractStatPeople, ContractStat, ContractStatTourPackage, ContractStatVehicle, PackageCost, PriceLevel } from "../../models/Contract";
import ContractService from "../../services/ContractService";
import { Invoice } from "../../models/Invoice";
import { ccyFormat, formatDate } from "../../functions/formaters";
import InvoiceService from "../../services/InvoiceService";
import { AsyncIconButton } from "../../components/AsyncButton";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ContractInvoiceDialog from "./ContractInvoiceDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ContractDetailPage = () => {
    const { id } = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [availableTourCodes, setAvailableTourCodes] = useState<string[]>([]);
    const [selectedTourCodes, setSelectedTourCodes] = useState<string[]>([]);
    const [contract, setContract] = useState<ContractStat>();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);

    const loadInvoices = () => {
        InvoiceService.get(selectedDate, id).then(setInvoices);
    }

    useEffect(() => {
        if(id) {
            ContractService.getById(id, selectedDate).then(c => {
                setContract(c)
                const tourCodes = new Set(c.current.tourPackagesStats.map(a => a.tourCode))
                setAvailableTourCodes([...tourCodes]);
                setSelectedTourCodes([...tourCodes]);
            });
            loadInvoices();
        }
    }, [selectedDate]);

    if (contract === undefined)
    {
        return <Box sx={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <CircularProgress/>
        </Box>
    }

    const currentDate = new Date();
    const isPassedMonth = selectedDate.getMonth() !== currentDate.getMonth() ||  selectedDate.getFullYear() !== currentDate.getFullYear()

    const allTourSelected = selectedTourCodes.length == 0 || selectedTourCodes.length == availableTourCodes.length
    const scopedTourForfaitStats = contract.current.tourForfaitStats.filter(tfs => selectedTourCodes.includes(tfs.tourCode) || allTourSelected)
    const scopedTourPackagesStats = contract.current.tourPackagesStats.filter(tps => selectedTourCodes.includes(tps.tourCode) || allTourSelected)
    const scopedPeopleStats = contract.current.peopleStats.filter(ps => ps.tourAffectations.findIndex(ta => selectedTourCodes.includes(ta.id)) >= 0 || allTourSelected)
    const scopedVehicleStats = contract.current.vehicleStats.filter(ps => ps.tourAffectations.findIndex(ta => selectedTourCodes.includes(ta.id)) >= 0 || allTourSelected)
 
    const projectedScopedTourForfaitStats = contract.expected.tourForfaitStats.filter(tfs => selectedTourCodes.includes(tfs.tourCode) || allTourSelected)
    const projectedScopedTourPackagesStats = contract.expected.tourPackagesStats.filter(tps => selectedTourCodes.includes(tps.tourCode) || allTourSelected)

    const peopleChargeTotal = contract.current.peopleStats.reduce((s, ps) => {
        const affectationRatio = allTourSelected ? 1 : ps.tourAffectations.reduce((ratio, affectation) => ratio + (selectedTourCodes.includes(affectation.id) ? affectation.ratio : 0), 0)
        return s + (ps.income + 0.45 * ps.income) * ps.ratio * affectationRatio;
    },0);

    
    const peopleCurrentDeliveryIncome = contract.current.peopleStats.reduce((s, ps) => s + (ps.deliveryIncome ?? 0), 0);
    const peopleProjectedDeliveryIncome = contract.expected.peopleStats.reduce((s, ps) => s + (ps.deliveryIncome ?? 0), 0);

    const vehicleProjectedChargeTotal = contract.expected.vehicleStats.reduce((s, vs) => {
        const affectationRatio = allTourSelected ? 1 : vs.tourAffectations.reduce((ratio, affectation) => ratio + (selectedTourCodes.includes(affectation.id) ? affectation.ratio : 0), 0)
        const gasolineCost = vs.gasolineCost;
        const fixedCosts = (vs.ownership === 'Rental' ? vs.rentalcost : 0) + vs.insurance;
        const vehicleCost = (gasolineCost + fixedCosts * vs.ratio) * affectationRatio;

        return s + vehicleCost;
    }, 0);

    const vehicleCurrentChargeTotal = contract.current.vehicleStats.reduce((s, vs) => {
        const affectationRatio = allTourSelected ? 1 : vs.tourAffectations.reduce((ratio, affectation) => ratio + (selectedTourCodes.includes(affectation.id) ? affectation.ratio : 0), 0)
        const fixedCosts = (vs.ownership === 'Rental' ? vs.rentalcost : 0) + vs.insurance;
        const vehicleCost = (vs.gasolineCost + fixedCosts * vs.ratio) * affectationRatio;

        return s + vehicleCost;
    }, 0);

    const currentActivityTotal = scopedTourForfaitStats.reduce((s, ts) => s + ts.ca, 0) + scopedTourPackagesStats.reduce((s, ts) => s + ts.ca, 0);
    const projectedActivityTotal = projectedScopedTourForfaitStats.reduce((s, ts) => s + ts.ca, 0) + projectedScopedTourPackagesStats.reduce((s, ts) => s + ts.ca, 0);
    const contractCurrentMarginTotal = currentActivityTotal - peopleChargeTotal - vehicleCurrentChargeTotal - peopleCurrentDeliveryIncome;
    const contractProjectedMarginTotal = projectedActivityTotal - peopleChargeTotal - vehicleProjectedChargeTotal - peopleProjectedDeliveryIncome;

    const handleDeleteInvoice = (id: string) => {
        return InvoiceService.deleteInvoice(id).then(loadInvoices);
    }

    const handleViewInvoice = (id: string) => {
        return InvoiceService.getData(id);
    }
    
    const handleContractClose = (result: boolean) => {
        setInvoiceDialogOpen(false);
        if (result) {
            loadInvoices();
        }
    }

    const InvoiceRow = (invoice : Invoice) => {
        return <TableRow key={invoice.id}>
            <TableCell sx={{width: 20 }}>
                <AsyncIconButton
                    onClick={() => handleViewInvoice(invoice.id)}>
                    <VisibilityIcon/>
                </AsyncIconButton>
            </TableCell>
            <TableCell>{invoice.name}</TableCell>
            <TableCell align="right">{formatDate(intl, invoice.date)}</TableCell>
            <TableCell align="right">
                <AsyncIconButton
                    onClick={() => handleDeleteInvoice(invoice.id)}>
                    <DeleteIcon/>
                </AsyncIconButton>
            </TableCell>
        </TableRow>
    }

    const PeopleDetail = (currentPeopleStat : ContractStatPeople, expectedPeopleStat?: ContractStatPeople) => {

        if (expectedPeopleStat === undefined) {
            return <Fragment/>
        }

        const affectationRatio = allTourSelected ? 1 : currentPeopleStat.tourAffectations.reduce((ratio, affectation) => ratio + (selectedTourCodes.includes(affectation.id) ? affectation.ratio : 0), 0)
        const peopleCost = (currentPeopleStat.income + 0.45 * currentPeopleStat.income) * currentPeopleStat.ratio;
        const peopleDeliveryIncome = currentPeopleStat.deliveryIncome ?? 0;
        const peopleProjectedMargin = (expectedPeopleStat.ca -  peopleCost) * affectationRatio - peopleDeliveryIncome;

        return <Fragment>
            <TableRow key={`contract-people-stat-${currentPeopleStat.id}`}>
                <TableCell><IconButton onClick={() => navigate(`/people/${currentPeopleStat.id}`)}><PersonIcon/></IconButton></TableCell>
                <TableCell>{currentPeopleStat.name}</TableCell>
                <TableCell align="right">{Math.round(currentPeopleStat.ratio * affectationRatio * 100)} %</TableCell>
                <TableCell align="right">{Math.round(currentPeopleStat.activity * affectationRatio)}</TableCell>
                <TableCell align="right">
                    {isPassedMonth ? ccyFormat(currentPeopleStat.ca * affectationRatio) : 
                    <Fragment>{ccyFormat(currentPeopleStat.ca  * affectationRatio)} / {ccyFormat(expectedPeopleStat.ca  * affectationRatio)}</Fragment>}
                </TableCell>
                <TableCell align="right">{ccyFormat(peopleCost * affectationRatio + peopleDeliveryIncome)}</TableCell>
                <TableCell align="right" sx={{color: peopleProjectedMargin > 0 ? 'green' : 'red'}}>
                    <b>{ccyFormat(peopleProjectedMargin)}</b>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    const VehicleDetail = (currentVehiculeStats : ContractStatVehicle, expectedVehiculeStats? : ContractStatVehicle) => {
        
        if (expectedVehiculeStats === undefined) {
            return <Fragment/>
        }

        const affectationRatio = allTourSelected ? 1 : currentVehiculeStats.tourAffectations.reduce((ratio, affectation) => ratio + (selectedTourCodes.includes(affectation.id) ? affectation.ratio : 0), 0)
        const fixedCosts = (currentVehiculeStats.ownership === 'Rental' ? currentVehiculeStats.rentalcost : 0) + currentVehiculeStats.insurance ;
        const vehicleCurrentCost = currentVehiculeStats.gasolineCost + fixedCosts * currentVehiculeStats.ratio;
        const vehicleProjectedCost = expectedVehiculeStats.gasolineCost + fixedCosts * expectedVehiculeStats.ratio;

        return <Fragment>
            <TableRow key={`contract-vehicle-stat-${currentVehiculeStats.id}`}>
                <TableCell><LocalShippingIcon/></TableCell>
                <TableCell>{currentVehiculeStats.type}</TableCell>
                <TableCell>{currentVehiculeStats.matriculation}</TableCell>
                <TableCell align="right">{Math.round(currentVehiculeStats.ratio * affectationRatio* 100)} %</TableCell>
                <TableCell align="right">{currentVehiculeStats.distanceDone * affectationRatio}</TableCell>
                <TableCell align="right">
                    {isPassedMonth ? ccyFormat(-vehicleCurrentCost * affectationRatio) : 
                    <Fragment>{ccyFormat(-vehicleCurrentCost * affectationRatio)} / {ccyFormat(-vehicleProjectedCost * affectationRatio)}</Fragment>}
                </TableCell>
            </TableRow>
        </Fragment>
    }

    const PackageDetail = (pc : PackageCost) => {
        const packageDetails = scopedTourPackagesStats.filter(tps => tps.package === pc.id)
            .sort((tps1, tps2) => tps1.kind.localeCompare(tps2.kind))
            
        const packageKinds =  scopedTourPackagesStats.map(p => p.kind).filter((value, index, self) => self.indexOf(value) === index)

        const DetailRow = (level: string, kind: string) => {
            const tourPackages = packageDetails.filter(p => p.kind === kind && p.priceLevel === level)

            const totalCount = tourPackages.reduce((s, p) => s + p.count, 0)
            const totalCa = tourPackages.reduce((s, p) => s + p.ca, 0)

            const detailLabel = intl.formatMessage({id: `contract.detail.${kind}`}) + " " + level;
            return (
                <TableRow key={`${pc.id}-${kind}`}>
                    <TableCell>{detailLabel}</TableCell>
                    <TableCell align="right">{totalCount}</TableCell>
                    <TableCell align="right">{ccyFormat((100 * totalCa / totalCount) / 100)}</TableCell>
                    <TableCell align="right">{ccyFormat(totalCa)}</TableCell>
                </TableRow>);
        }

        const DetailKind = (kind: string) => {
            
            const packageLevels = packageDetails.filter(tps => tps.kind === kind).map(p => p.priceLevel).filter((value, index, self) => self.indexOf(value) === index)
            return packageLevels.map(l => DetailRow(l, kind));
        }

        return <Fragment>
            <TableRow key={`${pc.id}-name`}>
                <TableCell colSpan={4}><b>{`${intl.formatMessage({id: 'contract.detail.packages'})} ${pc.name}`}</b></TableCell>
            </TableRow>
            {packageKinds.map(k => DetailKind(k))}
        </Fragment>
    }

    const ForfaitDetail = () => {
        const tourDetails = scopedTourForfaitStats.reduce((s, tfs) => ({qty: s.qty + tfs.count, ca: s.ca + tfs.ca }) , {qty: 0, ca: 0});

        return (
            <Fragment>
                <TableRow key='contract-table-forfait'>
                    <TableCell colSpan={4}><b>{intl.formatMessage({id: 'contract.forfait'})}</b></TableCell>
                </TableRow>
                <TableRow key='contract-table-forfaitMode'>
                    <TableCell>{intl.formatMessage({id: 'contract.forfaits'})}</TableCell>
                    <TableCell align="right">{tourDetails.qty}</TableCell>
                    <TableCell align="right">{ccyFormat(tourDetails.ca / tourDetails.qty)}</TableCell>
                    <TableCell align="right">{ccyFormat(tourDetails.ca)}</TableCell>
                </TableRow>
            </Fragment>)
    }

    const invoiceBaseLines = () => {
        const packagesLines = contract.current.tourPackagesStats.map(tps => {
            const packageName = contract.packageCosts.find(pc => pc.id === tps.package)?.name;
            const lineKey = `${intl.formatMessage({id: 'activity.package'})} ${packageName} - ${intl.formatMessage({id: `contract.detail.${tps.kind}`})} ${tps.priceLevel}`
            return { key: lineKey, qty: tps.count, amnt: tps.ca };
        });
        
        const forfaitLines = contract.current.tourForfaitStats.map(tfs => {
            const lineKey = intl.formatMessage({id: `contract.forfaits`})
            return { key: lineKey, qty: tfs.count, amnt: tfs.ca };
        });

        const allLines = [...packagesLines, ...forfaitLines];

        const groupedLines = allLines.filter(l => l.qty > 0).reduce( (m, {key, qty, amnt}) => m.set(key, { qty: (m.get(key)?.qty || 0) + qty, amnt: (m.get(key)?.amnt || 0) + amnt }), new Map())

        return Array.from(groupedLines, ([key, value], i) => ({name: key, quantity: Math.round(value.qty), unitPrice: Math.round(100 * value.amnt / value.qty) / 100, value: value.amnt}))
            .sort((l1, l2) => l1.name.localeCompare(l2.name));
    }

    const handleTourCodeChange = (event: SelectChangeEvent<typeof selectedTourCodes>) => {
        const {
            target: { value },
        } = event;
        setSelectedTourCodes(
          // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setSelectedDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + delta));
        setSelectedDate(newDate);
    }

    const DateSelector = () => {
        return (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
            <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                <KeyboardArrowLeftIcon fontSize="inherit" />
            </IconButton>
            <MobileDatePicker
                views={["month", "year"]}
                label={intl.formatMessage({ id: 'reports.date' })}
                inputFormat="MMMM yyyy"
                value={selectedDate}
                onChange={handleSelectedDateChange}
                renderInput={(params: any) => <TextField {...params} />}
            />
            <IconButton aria-label="next"  onClick={() => handleChangeDate(1)}>
                <KeyboardArrowRightIcon fontSize="inherit" />
            </IconButton>
        </Stack>);
    }
    
    return (
        <Fragment>
            {DateSelector()}
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                <Box>
                    <Stack direction="row">
                        <IconButton aria-label="back" sx={{mr: 1, mt: 4}} size="large" onClick={() => navigate("/contracts")}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <Typography variant="h3" component="h3" sx={{mt: 4}}>
                            {contract.name}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle2" gutterBottom component="div"sx={{mb: 2}}>
                        {contract.siret} - {contract.email}
                    </Typography>
                    <Stack direction="row">
                        <Typography variant="subtitle1" gutterBottom component="div"sx={{mb: 2, mt: 2}}>
                            {intl.formatMessage({id: 'contract.detail.activity'})}
                        </Typography>
                        <FormControl sx={{ m: 1, ml: 3, width: 300 }}>
                            <InputLabel id="tourCodes">{intl.formatMessage({id: 'contract.detail.tourCodes'})}</InputLabel>
                            <Select
                                labelId="tourCodes"
                                id="tourCodes-checkbox"
                                multiple
                                value={selectedTourCodes}
                                onChange={handleTourCodeChange}
                                input={<OutlinedInput label={intl.formatMessage({id: 'contract.detail.tourCodes'})} />}
                                renderValue={(selected) => selected.length === availableTourCodes.length ? intl.formatMessage({id: 'all'}): selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                            {availableTourCodes.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={selectedTourCodes.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <TableContainer component={Paper}>
                        <Table aria-label="contract-table">
                            <TableHead>
                                <TableRow key="contract-table-header">
                                    <TableCell></TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'quantity'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'unit'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'sum'})}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contract.packageCosts.map(pc => PackageDetail(pc))}
                                {contract.forfaits && contract.forfaits.length > 0 && ForfaitDetail()}
                                <TableRow key='contract-table-total'>
                                    <TableCell colSpan={3} align="right">
                                        <Typography><b>{intl.formatMessage({id: 'total'})}</b></Typography>
                                    </TableCell>
                                    <TableCell align="right"><b>{ccyFormat(currentActivityTotal)}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="subtitle1" gutterBottom component="div"sx={{mb: 2, mt: 4}}>
                        {intl.formatMessage({id: 'contract.detail.people'})}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="contract-people-table">
                            <TableHead>
                                <TableRow key="contract-people-header">
                                    <TableCell></TableCell>
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.people.name'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.people.ratio'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.people.deliveries'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: isPassedMonth ? 'contract.detail.people.ca' : 'contract.detail.people.projectedca'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.people.charge'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: isPassedMonth ? 'contract.detail.people.margin' : 'contract.detail.people.projectedmargin'})}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scopedPeopleStats.map(ps => PeopleDetail(ps, contract.expected.peopleStats.find(eps => eps.id === ps.id)))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="subtitle1" gutterBottom component="div"sx={{mb: 2, mt: 4}}>
                        {intl.formatMessage({id: 'contract.detail.vehicle'})}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="contract-vehicle-table">
                            <TableHead>
                                <TableRow key="contract-vehicle-header">
                                    <TableCell></TableCell>
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.vehicle.type'})}</TableCell>
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.vehicle.matriculation'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.vehicle.ratio'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.vehicle.distancedone'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: isPassedMonth ? 'contract.detail.vehicle.charge' : 'contract.detail.vehicle.projectedcharge'})}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scopedVehicleStats.map(vs => VehicleDetail(vs, contract.expected.vehicleStats.find(evs => evs.id === vs.id)))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="subtitle1" gutterBottom component="div"sx={{mb: 2, mt: 4}}>
                        {intl.formatMessage({id: 'contract.detail.summary'})}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="contract-summary-table">
                            <TableHead>
                                <TableRow key="contract-summary-header">
                                    <TableCell/>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.summary.impact'})}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key="contract-summary-activity">
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.summary.activity'})}</TableCell>
                                    <TableCell align="right">
                                        {isPassedMonth ? ccyFormat(currentActivityTotal) :
                                        <Fragment>{ccyFormat(currentActivityTotal)} / {ccyFormat(projectedActivityTotal)}</Fragment>}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="contract-summary-people">
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.summary.people'})}</TableCell>
                                    <TableCell align="right">{ccyFormat(-peopleChargeTotal)}</TableCell>
                                </TableRow>
                                <TableRow key="contract-summary-deliveryIncome">
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.summary.peopleDeliveryIncome'})}</TableCell>
                                    <TableCell align="right">
                                        {isPassedMonth ? ccyFormat(-peopleCurrentDeliveryIncome) :
                                        <Fragment>{ccyFormat(-peopleCurrentDeliveryIncome)} / {ccyFormat(-peopleProjectedDeliveryIncome)}</Fragment>}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="contract-summary-vehicle">
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.summary.vehicle'})}</TableCell>
                                    <TableCell align="right">
                                        {isPassedMonth ? ccyFormat(-vehicleCurrentChargeTotal) :
                                        <Fragment>{ccyFormat(-vehicleCurrentChargeTotal)} / {ccyFormat(-vehicleProjectedChargeTotal)}</Fragment>}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="contract-summary-margin">
                                    <TableCell align="right">{intl.formatMessage({id: isPassedMonth ? 'contract.detail.summary.margin' : 'contract.detail.summary.projectedmargin'})}</TableCell>
                                    <TableCell align="right" sx={{color: contractProjectedMarginTotal > 0 ? 'green' : 'red'}}>
                                        {isPassedMonth ? <b>{ccyFormat(contractCurrentMarginTotal)}</b> :
                                        <Fragment>{ccyFormat(contractCurrentMarginTotal)} / <b>{ccyFormat(contractProjectedMarginTotal)}</b></Fragment>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="subtitle1" gutterBottom component="div" sx={{mt: 4, mb: 2}}>
                        {intl.formatMessage({id: 'contract.detail.invoices'})}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="invoices-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{maxWidth: 5 }}/>
                                    <TableCell>{intl.formatMessage({id: 'contract.detail.invoices.name'})}</TableCell>
                                    <TableCell align="right">{intl.formatMessage({id: 'contract.detail.invoices.date'})}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => setInvoiceDialogOpen(true)}>
                                            <AddIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map(InvoiceRow)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Slide>
            {id && <ContractInvoiceDialog open={invoiceDialogOpen} contractId={id} contract={contract} invoiceDate={selectedDate} initLines={invoiceBaseLines()} handleClose={handleContractClose} /> }
        </Fragment>);
}

export default ContractDetailPage;