import { ActivityStats } from "../models/Stats";


export class DashboardService
{
    static async getDashboardStats(period: string, contractId: string) {
        const response = await fetch(`/api/dashboard/stats?period=${period}&contractId=${contractId}`, {
            method: "GET"
        });
        return await response.json() as ActivityStats;
    }
}

export default DashboardService;