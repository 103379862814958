import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { handleNumberChange, handleDateChange, handleAutocompleteChange } from "../../functions/fieldChangeHandlers";
import { Activity, PackageActivity } from "../../models/Activity";
import ActivityService from "../../services/ActivityService";
import { People } from "../../models/People";
import { Contract } from "../../models/Contract";
import { Vehicle } from "../../models/Vehicle";
import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";
import { Planning } from "../../models/Planning";
import { ccyFormat } from "../../functions/formaters";

export type PlanningWithDate = Planning & {
  date: Date
}

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  activity?: Activity
  planning?: PlanningWithDate
  date: Date
  availablePeople: People[]
  availableContracts: Contract[]
  availableVehicles: Vehicle[]
  usedTourCodes: string[]
}

const ActivityDialog = ({ open, handleClose, activity, planning, date, availablePeople, availableContracts, availableVehicles, usedTourCodes }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const peopleOptions = availablePeople.map(p => { return { id: p.id, label: p.name}});
  const contractOptions = availableContracts.map(p => { return { id: p.id, label: p.name}});
  const vehicleOptions = availableVehicles.map(p => { return { id: p.id, label: p.matriculation ?? ''}});
  
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date);
  const [contractId, setContractId] = useState<{id: string, label: string} | null>(null);
  const [peopleId, setPeopleId] = useState<{id: string, label: string} | null>(null);
  const [tourCode, setTourCode] = useState<string|null>(null);
  const [vehicleId, setVehicleId] = useState<{id: string, label: string} | null>(null);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [forfait, setForfait] = useState<number | undefined>(undefined);
  const [pauseDuration, setPauseDuration] = useState<number | undefined>(undefined);
  const [distanceDone, setDistanceDone] = useState<number | undefined>(undefined);
  const [packages, setPackages] = useState<{[kind: string] : PackageActivity}>({});
  
  const tourOptions = availableContracts.find(c => c.id === contractId?.id)?.tours.map(t => t.code).filter(t => !usedTourCodes.includes(t)) ?? []
  const hasTourForfait = availableContracts.find(c => c.id === contractId?.id)?.forfaits?.findIndex(f => f.kind !== "Contract") ?? -1 >= 0

  const [peopleInput, setPeopleInput] = useState('');
  const [contractInput, setContractInput] = useState('');
  const [tourInput, setTourInput] = useState('');
  const [vehicleInput, setVehicleInput] = useState('');

  
  useEffect(() => {
    if(activity !== undefined){
      setSelectedDate(activity.date);
      setPeopleId(peopleOptions.find(p => p.id == activity.peopleId) ?? null);
      setContractId(contractOptions.find(p => p.id == activity.contractId) ?? null);
      setTourCode(activity.tourCode);
      setVehicleId(vehicleOptions.find(p => p.id == activity.vehicleId) ?? null);
      setStartTime(activity.startTime);
      setEndTime(activity.endTime);
      setPauseDuration(activity.pauseDuration);
      setPackages(activity.packages);
      setDistanceDone(activity.distanceDone);
      setForfait(activity.forfait);
    } else if (planning !== undefined) {
      setSelectedDate(planning.date);
      setPeopleId(peopleOptions.find(p => p.id == planning.peopleId) ?? null);
      setContractId(contractOptions.find(p => p.id == planning.contractId) ?? null);
      setTourCode(planning.tourCode);
      setVehicleId(vehicleOptions.find(p => p.id == planning.vehicleId) ?? null);
      setStartTime(planning.startTime);
      setEndTime(planning.endTime);
      setPauseDuration(planning.pauseDuration);
      setPackages(planning.packages);
      setDistanceDone(planning.distanceDone);
      setForfait(planning.forfait);
    } else {
      razFields();
    }
  }, [activity, planning])

  useEffect(() => {
    const newForfait = forfaitEstimate()
    setForfait(newForfait)
  }, [contractId, vehicleId, startTime, endTime , distanceDone])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    if(peopleId === null ||
      contractId === null ||
      vehicleId === null ||
      tourCode === null ||
      selectedDate === undefined)
    {
      return;
    }

    if (activity) {
      return ActivityService.editActivity({
        id: activity.id,
        clientId: activity.clientId,
        peopleId: peopleId?.id ?? '',
        contractId: contractId?.id ?? '',
        tourCode: tourCode  ?? '',
        vehicleId: vehicleId?.id ?? '',
        date: selectedDate,
        startTime: startTime ?? new Date(),
        endTime: endTime ?? new Date(),
        pauseDuration: pauseDuration ?? 0,
        packages: packages,
        distanceDone: distanceDone?? 0,
        forfait: forfait,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
    else {
      return ActivityService.addActivity({
        id: '',
        clientId: '',
        peopleId: peopleId?.id ?? '',
        contractId: contractId?.id ?? '',
        tourCode: tourCode ?? '',
        vehicleId: vehicleId?.id ?? '',
        date: selectedDate,
        startTime: startTime ?? new Date(),
        endTime: endTime ?? new Date(),
        pauseDuration: pauseDuration ?? 0,
        packages: packages,
        distanceDone: distanceDone?? 0,
        forfait: forfait,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setSelectedDate(date);
    setPeopleId(null);
    setContractId(null);
    setVehicleId(null);
    setTourCode(null);
    setStartTime(undefined);
    setEndTime(undefined);
    setPauseDuration(undefined);
    setPackages({});
    setDistanceDone(undefined);
    setForfait(undefined);
  }

  const onContractChange = (event: React.SyntheticEvent<Element, Event>, newValue: { id: string; label: string;} | null) => {
    const selectedContract = availableContracts.find(c => c.id === newValue?.id);
    if(selectedContract) {
      const packageMap : {[kind: string] : PackageActivity} = {};
      selectedContract.packageCosts.forEach(pc => packageMap[pc.id] = { relayDeliveries: 0, clientDeliveries: 0, clientPickups: 0, relayPickups: 0});
      setPackages(packageMap);
      setContractId(newValue);
      
      // if (selectedContract.forfaitMode === "Tour") {
      //   setForfait(selectedContract.forfait);
      // }
    }
  }

  const forfaitEstimate = () => {
    const selectedContract = availableContracts.find(c => c.id === contractId?.id);
    if(selectedContract && selectedContract.forfaits) {
      return selectedContract.forfaits.reduce((s, f) => {
        if (f.kind === "Tour") {
          return s + f.amount
        } else if (f.kind === "Distance") {
          const distances = Math.max((distanceDone ?? 0) - (f.level ?? 0), 0) / (f.quantity ?? 1)
          return s + distances * f.amount
        } else if (f.kind === "Time") {
          if ((startTime && startTime >= (f.startTime ?? startTime) && startTime <= (f.endTime ?? startTime)) ||
            (endTime && endTime >= (f.startTime ?? endTime) && endTime <= (f.endTime ?? endTime))
          ) {
            return s + f.amount
          }
          else {
            return s
          }
        } else if (f.kind === "VehicleKind") {
          const vehicleKind = availableVehicles.find(v => v.id === vehicleId?.id)?.kind

          if (vehicleKind === f.vehicleKind) {
            return s + f.amount
          } else {
            return s
          }
        } else {
          return s
        }
      }, 0)
    }

    return 0
  }

  const ListForfaitGains = () => {
    const prepareDate = (date: Date | undefined, isEndDate: boolean) => {
      const newDate = new Date()

      if (date) {
        newDate.setHours(date.getHours())
        newDate.setMinutes(date.getMinutes())
      } else {
        if (isEndDate) {
          newDate.setHours(24,0,0,0);
        } else {
          newDate.setHours(0,0,0,0);
        }
      }

      return newDate
    }

    const dateRangeOverlaps = (a_start : Date, a_end : Date, b_start : Date, b_end: Date) => {      
      if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
      if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
      if (b_start <  a_start && a_end   <  b_end) return true; // a in b
      return false;
    }

    const selectedContract = availableContracts.find(c => c.id === contractId?.id);

    return (
    <List>
      {selectedContract?.forfaits?.map(f => {
        var amount = 0
        if (f.kind === "Tour") {
          amount = f.amount
        } else if (f.kind === "Distance") {
          const distances = Math.max((distanceDone ?? 0) - (f.level ?? 0), 0) / (f.quantity ?? 1)
          amount = distances * f.amount
        } else if (f.kind === "Time") {
          if(dateRangeOverlaps(
            prepareDate(startTime, false),
            prepareDate(endTime, true),
            prepareDate(f.startTime, false),
            prepareDate(f.endTime, true))) {
            amount = f.amount
          }
        } else if (f.kind === "VehicleKind") {
          const vehicleKind = availableVehicles.find(v => v.id === vehicleId?.id)?.kind

          if (vehicleKind === f.vehicleKind) {
            amount = f.amount
          }
        }

        if ( amount > 0) {
          return (
          <ListItem>
            <ListItemText primary={f.name} secondary={ccyFormat(amount)}/>
          </ListItem>)
        } else {
          return <Fragment/>
        }

      })}
    </List>)
  }

  const handlePackageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    packageKind: string,
    update: (newValue: number | undefined, packageActivity: PackageActivity) => void) => {
      const newMap : {[kind: string] : PackageActivity} = {};
      Object.keys(packages).forEach(pc => newMap[pc] = { 
        relayDeliveries: packages[pc].relayDeliveries, 
        clientDeliveries: packages[pc].clientDeliveries, 
        clientPickups: packages[pc].clientPickups, 
        relayPickups: packages[pc].relayPickups
      });
      
      const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
      const existingPackage = newMap[packageKind];
      if (existingPackage) {
        update(val, existingPackage);
        newMap[packageKind] = existingPackage;
        setPackages(newMap);
      }
  }

  const DeliveryItem = (packageActivity: PackageActivity, packageKind: string) => {
    const selectedContract = availableContracts.find(c => c.id === contractId?.id);
    const packageKindName = selectedContract?.packageCosts.find(pc => pc.id === packageKind)?.name ?? '';
    return <Fragment>
      <Divider sx={{mt: 3}}>{`${intl.formatMessage({id: 'activity.package'})} ${packageKindName}`}</Divider>
      <TextField
        margin="dense"
        key={`${packageKind}-clientDeliveries`}
        label={intl.formatMessage({ id: 'activity.clientDeliveries' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.clientDeliveries}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.clientDeliveries = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-relayDeliveries`}
        label={intl.formatMessage({ id: 'activity.relayDeliveries' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.relayDeliveries}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.relayDeliveries = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-clientPickups`}
        label={intl.formatMessage({ id: 'activity.clientPickups' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.clientPickups}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.clientPickups = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-relayPickups`}
        label={intl.formatMessage({ id: 'activity.relayPickups' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.relayPickups}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.relayPickups = v)}
      />
    </Fragment>
  }

  return (<Dialog 
      open={open} 
      onClose={handleDismiss} 
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: activity !== undefined ? 'activity.edit' :'activity.add' })}</DialogTitle>
    <DialogContent sx={{marginTop: 2}}>
      <MobileDatePicker
        label={intl.formatMessage({ id: 'activity.date' })}
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange(setSelectedDate)}
        renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
      />
      <Autocomplete
        value={contractId}
        onChange={onContractChange}
        inputValue={contractInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          setContractInput(newInputValue);
        }}
        id="contractId"
        options={contractOptions}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'activity.contractId' })} />}
      />
      <Autocomplete
        value={peopleId}
        onChange={handleAutocompleteChange(setPeopleId)}
        inputValue={peopleInput}
        groupBy={(option) => availablePeople.find(p => p.id === option.id)?.contractId === contractId?.id ? intl.formatMessage({ id: 'assigned' }): intl.formatMessage({ id: 'other' })}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          setPeopleInput(newInputValue);
        }}
        id="peopleId"
        options={peopleOptions.sort(opt => availablePeople.find(p => p.id === opt.id)?.contractId === contractId?.id ? -1 : 1)}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'activity.peopleId' })} />}
      />
      <Autocomplete
        value={tourCode}
        onChange={handleAutocompleteChange(setTourCode)}
        inputValue={tourInput}
        onInputChange={(event, newInputValue) => {
          setTourInput(newInputValue);
        }}
        id="tourCode"
        options={tourOptions}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'activity.tourCode' })} />}
      />
      <Autocomplete
        value={vehicleId}
        onChange={handleAutocompleteChange(setVehicleId)}
        inputValue={vehicleInput}
        groupBy={(option) => availableVehicles.find(v => v.id === option.id)?.contractId === contractId?.id ? intl.formatMessage({ id: 'assigned' }): intl.formatMessage({ id: 'other' })}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValue) => {
          setVehicleInput(newInputValue);
        }}
        id="vehicleId"
        options={vehicleOptions.sort(opt => availableVehicles.find(v => v.id === opt.id)?.contractId === contractId?.id ? -1 : 1)}
        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'activity.vehicleId' })} />}
      />
      <TimePicker
        label={intl.formatMessage({ id: 'activity.startTime' })}
        value={startTime === undefined ? null : startTime}
        onChange={handleDateChange(setStartTime)}
        openTo="hours"
        renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
      />
      <TimePicker
        renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
        label={intl.formatMessage({ id: 'activity.endTime' })}
        value={endTime === undefined ? null : endTime}
        openTo="hours"
        onChange={handleDateChange(setEndTime)}
      />
      {ListForfaitGains()}
      <TextField
        margin="dense"
        id="forfait"
        label={intl.formatMessage({ id: 'activity.forfait' })}
        type="number"
        fullWidth
        variant="standard"
        value={forfait}
        onChange={handleNumberChange(setForfait)}
      />
      <TextField
        margin="dense"
        id="pauseDuration"
        label={intl.formatMessage({ id: 'activity.pauseDuration' })}
        type="number"
        fullWidth
        variant="standard"
        value={pauseDuration}
        onChange={handleNumberChange(setPauseDuration)}
      />
      <TextField
        margin="dense"
        id="distanceDone"
        label={intl.formatMessage({ id: 'activity.distanceDone' })}
        type="number"
        fullWidth
        variant="standard"
        value={distanceDone}
        onChange={handleNumberChange(setDistanceDone)}
      />
      {Object.keys(packages).map((p) => DeliveryItem(packages[p], p))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <AsyncButton onClick={handleValidate}> {intl.formatMessage({ id: 'ok' })}</AsyncButton>
    </DialogActions>
  </Dialog>);
}

export default ActivityDialog;