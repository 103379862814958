import { useState } from "react";
import { Box, Button, CircularProgress, IconButton, SxProps, useTheme } from "@mui/material";

interface IProps {
  variant?: "text" | "contained" | "outlined";
  children?:  React.ReactNode;
  sx?: SxProps;
  onClick?: () => Promise<any> | undefined;
  edge?: 'start' | 'end' | false;
  ariaLabel?: string;
  disabled?: boolean;
}

export const AsyncButton = ({variant, sx, onClick, children, disabled, ariaLabel} : IProps) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(onClick) {
      setLoading(true);
      const promise = onClick();
      if(promise !== undefined) {
        promise.then(() => setLoading(false));
      } else {
        setLoading(false);
      };
    }
  }

  return <Box sx={{ m: 1, position: 'relative' }}>
    <Button
      aria-label={ariaLabel}
      variant={variant}
      sx={sx}
      disabled={disabled || loading}
      onClick={handleClick}>
      {children}
    </Button>
    {loading && (
      <CircularProgress
        size={24}
        sx={{
          color: theme.palette.primary.light,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
}


export const AsyncIconButton = ({variant, sx, onClick, children, edge, ariaLabel, disabled} : IProps) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(onClick) {
      setLoading(true);
      const promise = onClick();
      if(promise !== undefined) {
        promise.then(() => setLoading(false));
      } else {
        setLoading(false);
      };
    }
  }

  return <Box sx={{ m: 1, position: 'relative' }}>
    <IconButton
        sx={sx}
        disabled={disabled || loading}
        edge={edge}
        aria-label={ariaLabel}
        onClick={handleClick}>
        {children}
    </IconButton>
    {loading && (
        <CircularProgress
          size={40}
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '10%',
            right: '0px',
            zIndex: 1,
          }}
        />
    )}
  </Box>
}