import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemButton,  ListItemText, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { MoreVert as MoreVertIcon } from "@mui/icons-material"

import { stringAvatar } from "../../functions/avatar"
import { useStorageState } from "../../functions/customHooks";
import { People } from "../../models/People";
import PeopleService from "../../services/PeopleService";
import PeopleDialog from "./PeopleDialog";
import Fab from "../../components/Fab";
import { Contract } from "../../models/Contract";
import { ContractService } from "../../services/ContractService";
import React from "react";
import { useIntl } from "react-intl";
import PeopleInvoicePopup from "./PeopleInvoicePopup";

const PeoplePage = () => {
    const navigate = useNavigate();
    const intl = useIntl()

    const [people, setPeople] = useStorageState<People[]>('people', []);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openPeopleInvoice, setOpenPeopleInvoice] = useState(false);
    const [editedPeople, setEditedPeople] = useState<People | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

    useEffect(() => {
        ContractService.get().then(setContracts);
        loadPeople();
    }, []);

    const handlePeopleClose = (result: boolean) => {
        setOpenDialog(false);
        setMenuAnchorEl(null);
        if (result) {
            loadPeople();
        }
    }
    
    const handleInvoiceClose = (result: boolean) => {
        setOpenPeopleInvoice(false);
        setMenuAnchorEl(null);
    }

    const loadPeople = () => {
        setLoading(true);
        PeopleService.get().then((data) => {
            setPeople(data);
            setLoading(false);
        });
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    
    const handleOpenMenu = (people: People, target: HTMLElement) => {
        setEditedPeople(people);
        setMenuAnchorEl(target);
    }

    const handleOpenInvoice = () => {
        setOpenPeopleInvoice(true);
    }

    const PeopleItem = (people: People) => {
        return (
        <Fragment>
            <ListItem
                key={people.id}
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="more"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => { handleOpenMenu(people, event.currentTarget);}}
                        size="large">
                        <MoreVertIcon />
                    </IconButton>} >
                <ListItemButton onClick={() => navigate("/people/" + people.id)}>
                    <ListItemAvatar>
                        <Avatar {...stringAvatar(people.name)} />
                    </ListItemAvatar>
                    <ListItemText primary={people.name} />
                </ListItemButton>
            </ListItem>
        </Fragment>
        )
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="nested-list-subheader">
                {people.map(PeopleItem)}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={loading} color="primary" aria-label="add" onClick={() => 
                {
                    setEditedPeople(undefined)
                }}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <Menu
                id="actions-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={() => setMenuAnchorEl(null)}>
                <MenuItem onClick={() => handleOpenDialog()}>{intl.formatMessage({ id: "people.edit" })}</MenuItem>
                {editedPeople?.status === "Contractor" && <MenuItem onClick={() => handleOpenInvoice()}>{intl.formatMessage({ id: "people.generateInvoice" })}</MenuItem>}
            </Menu>
            <PeopleDialog open={openDialog} people={editedPeople} contracts={contracts} handleClose={handlePeopleClose} />
            <PeopleInvoicePopup open={openPeopleInvoice} peopleId={editedPeople?.id} handleClose={handleInvoiceClose} />
        </Fragment>);
}


export default PeoplePage;