import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import { Avatar, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Slide, Stack, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Fab from "../../components/Fab";
import UserService from "../../services/UserService";
import { User } from "../../models/User";
import { stringAvatar } from "../../functions/avatar";
import AddApiKeyDialog from "./AddApiKeyDialog";
import { ApiKey } from "../../models/ApiKey";
import ApiKeyService from "../../services/ApiKeyService";

const AccountApiKeysDetail = () => {
    const intl = useIntl();

    const [keys, setKeys] = useState<ApiKey[]>([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedUser, setEditedUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        loadKeys();
    }, []);

    const loadKeys = () => {
        setLoading(true);
        ApiKeyService.get().then((data) => {
            setKeys(data);
            setLoading(false);
        });
    }

    const handleDialogClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadKeys();
        }
    }

    const deleteKey = (id: string) => {
        return ApiKeyService.deleteKey(id).then(() => loadKeys());
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const KeyItem = (key: ApiKey) => {
        return (<ListItem 
            key={key.id}
            secondaryAction={<IconButton onClick={() => deleteKey(key.id ?? "")}><DeleteIcon /></IconButton>}>
            <ListItemText primary={key.name}/>
        </ListItem>)
    }


    return (
    <Fragment>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box>
                <List
                    sx={{ width: '100%'}}
                    component="nav"
                    aria-labelledby="users">
                    {keys.map(KeyItem)}
                </List>
            </Box>
        </Slide>
        <Fab disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog()}>
            {loading ? <CircularProgress /> : <AddIcon /> }
        </Fab>
        <AddApiKeyDialog open={openDialog} handleClose={handleDialogClose} />
    </Fragment>);
}

export default AccountApiKeysDetail;