import { Expense } from "../models/Expense";


export class ExpenseService
{
    static async get(date: Date | undefined = undefined) {
        const dateParam = date !== undefined ? '?date=' + date?.toISOString() : '';
        const response = await fetch(`/api/expense` +  dateParam);
        return await response.json() as Expense[];
    }

    static async addExpense(data: Expense) {
        await fetch("/api/expense", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async editExpense(data: Expense) {
        await fetch("/api/expense", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deleteExpense(expenseId: string) {
        await fetch("/api/expense/" + expenseId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }
}

export default ExpenseService;