import { useState } from "react";
import { useIntl } from "react-intl";
import * as _ from "lodash";

import { CardActions, CardContent, CardHeader, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    BarController,
    LineController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';

import 'chartjs-adapter-date-fns';
import { Chart } from 'react-chartjs-2';
import { Period } from "../../models/Period";
import { DeliveryStat } from "../../models/Stats";
import { getStartDate } from "../../functions/dateUtils";
import { alpha, color } from "@mui/system";

ChartJS.register(
    TimeScale,
    LinearScale,
    BarController,
    LineController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

interface IProps {
    title: string;
    stats: DeliveryStat[]
}

const DeliveriesChart = ({ stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();

    const sortedStats = stats.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    const endDate = sortedStats.length > 0 ? new Date(sortedStats[sortedStats.length-1].date) : new Date();
    const startDate = sortedStats.length > 0 ? new Date(sortedStats[0].date) : new Date();

    const daysScale: Date[] = []
    for(const d=new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate()+1))
    { 
        daysScale.push(new Date(d));
    }
        
    const deliveriesData = daysScale.map(d => stats.find(del => new Date(del.date).getDate() === d.getDate())?.count ?? 0);
    const caData = daysScale.map(d => stats.find(del =>  new Date(del.date).getDate() === d.getDate())?.associatedCa ?? 0);
    
    const labels = daysScale.map(d => intl.formatDate(d, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }));

    const data = {
        datasets: [{
            type: 'bar' as const,
            label: 'Deliveries',
            data: deliveriesData,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            yAxisID: 'y'
        }, {
            type: 'line' as const,
            label: 'CA',
            data: caData,
            borderColor: theme.palette.error.main,
            tension: 0.5,
            yAxisID: 'y1'
        }],
        labels: labels
    };
    
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                position: 'left' as 'left',
                grid: {
                    drawOnChartArea: false
                }
            },
            y1: {
                position: 'right' as 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        }
    }

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Chart type='bar' data={data} options={options}/>
            </CardContent>
        </Card>
    );
}

export default DeliveriesChart;
