import { Fragment, useState } from "react";
import { useIntl } from "react-intl";

import { Divider, Icon, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TuneIcon from '@mui/icons-material/Tune';
import DeleteIcon from '@mui/icons-material/Delete';

import { PackageCost, PriceLevel } from "../../models/Contract";
import ChipInput from "../../components/ChipInput";

type PriceLevelItemProps = {
  name: string;
  packageCostId: string;
  priceLevels: PriceLevel[] | undefined;
  //handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePriceLevels: (priceLevels: PriceLevel[]) => void;
}

const PriceLevelItem = ({name, packageCostId, priceLevels, handleChangePriceLevels} : PriceLevelItemProps) => {
  const intl = useIntl();

  const [showLevels, setShowLevels] = useState(false);

  const baseDeliveryCost = (priceLevels === undefined || priceLevels.length === 0) ? 0 : priceLevels[0].amount;
  
  const updateLevel = (priceLevel: PriceLevel, level: number) => {
    const plIdx = priceLevels?.indexOf(priceLevel) ?? 0;
    const newPriceLevel = {
      level: level,
      amount: priceLevel.amount
    };

    updatePriceLevels(newPriceLevel, plIdx);
  }

  const updateAmount = (priceLevel: PriceLevel, amount: number) => {
    const plIdx = priceLevels?.indexOf(priceLevel) ?? 0;
    const newPriceLevel = {
      level: priceLevel.level,
      amount: amount
    };

    updatePriceLevels(newPriceLevel, plIdx);
  }

  const updateInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCost = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
    const newPriceLevel = {
      level: undefined,
      amount: newCost
    };

    updatePriceLevels(newPriceLevel, 0);
  }
  
  const addPriceLevel = () => {
    const newPriceLevel = {
      level: undefined,
      amount: 0
    };

    updatePriceLevels(newPriceLevel, -1);
  }

  const removePriceLevel = (priceLevel: PriceLevel) => {
    const plIdx = priceLevels?.indexOf(priceLevel) ?? 0;
    if(plIdx >= 0 && priceLevels !== undefined) {
      priceLevels.splice(plIdx, 1);
      handleChangePriceLevels([...priceLevels]);
    }
  }

  const updatePriceLevels = (newPriceLevel: PriceLevel, plIdx: number) => {
    if(plIdx >= 0 && priceLevels !== undefined) {
      priceLevels.splice(plIdx, 1, newPriceLevel);
      handleChangePriceLevels([...priceLevels]);
    } else {
      const newPriceLevels = priceLevels ?? [];
      handleChangePriceLevels([...newPriceLevels, newPriceLevel])
    }
  }

  const additionalPriceLevels = priceLevels?.slice(1);
  const displayedPriceLevels = additionalPriceLevels?.length == 0 ? [{level: undefined, amount: 0}] : additionalPriceLevels;

  return (
    <Fragment>
      <TextField
        margin="dense"
        id={`${packageCostId}-${name}`}
        label={intl.formatMessage({ id: `contract.${name}` })}
        type="number"
        fullWidth
        variant="standard"
        value={baseDeliveryCost}
        onChange={updateInputUpdate}
        InputProps={{
          endAdornment: (<InputAdornment position="end">
            <IconButton
              aria-label="show-levels"
              onClick={() => setShowLevels(!showLevels)}
              edge="end">
              <TuneIcon />
            </IconButton>
          </InputAdornment>),
        }}
        />
      {showLevels && displayedPriceLevels?.map((l, i) => {
        return (<Stack direction="row">
          <IconButton aria-label="remove" onClick={() => removePriceLevel(l)} size="small">
              <DeleteIcon />
          </IconButton>
          <TextField
            sx={{ml: 1, mr: 0}}
            margin="dense"
            id={`${packageCostId}-${name}-level`}
            label={intl.formatMessage({ id: `contract.pricelevel.level` })}
            type="number"
            variant="standard"
            value={l.level}
            onChange={(evt) => updateLevel(l, (evt.target.value as unknown) as number)}
            />
          <TextField
            sx={{ml: 1}}
            margin="dense"
            id={`${packageCostId}-${name}-amount`}
            label={intl.formatMessage({ id: `contract.pricelevel.amount` })}
            type="number"
            variant="standard"
            value={l.amount}
            onChange={(evt) => updateAmount(l, (evt.target.value as unknown) as number)}
            />
            {i === displayedPriceLevels?.length -1 && <IconButton aria-label="add" onClick={addPriceLevel} size="small">
              <AddCircleIcon />
            </IconButton>}
        </Stack>)
      })}
    </Fragment>
  );
}


interface IProps {
  handleUpdate: (packageCost: PackageCost) => void
  handleDelete: (id: string) => void
  packageCost: PackageCost
}

const ContractPackageCost = ({handleUpdate, handleDelete, packageCost} : IProps) => {
    const intl = useIntl();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      packageCost.name = event.target.value;
      handleUpdate(packageCost);
    }

    const handleClientDeliveryCostsChange = (levels: PriceLevel[]) => {
      const newPackageCost = {
        id: packageCost.id,
        name: packageCost.name,
        clientDeliveryCosts: levels,
        relayDeliveryCosts: packageCost.relayDeliveryCosts,
        clientPickupCosts: packageCost.clientPickupCosts,
        relayPickupCosts: packageCost.relayPickupCosts,
        tags: packageCost.tags,
      }

      handleUpdate(newPackageCost);
    }
    
    const handleRelayDeliveryCostsChange = (levels: PriceLevel[]) => {
      const newPackageCost = {
        id: packageCost.id,
        name: packageCost.name,
        clientDeliveryCosts: packageCost.clientDeliveryCosts,
        relayDeliveryCosts: levels,
        clientPickupCosts: packageCost.clientPickupCosts,
        relayPickupCosts: packageCost.relayPickupCosts,
        tags: packageCost.tags,
      }

      handleUpdate(newPackageCost);
    }
    
    const handleClientPickupCostChange = (levels: PriceLevel[]) => {
      const newPackageCost = {
        id: packageCost.id,
        name: packageCost.name,
        clientDeliveryCosts: packageCost.clientDeliveryCosts,
        relayDeliveryCosts: packageCost.relayDeliveryCosts,
        clientPickupCosts: levels,
        relayPickupCosts: packageCost.relayPickupCosts,
        tags: packageCost.tags,
      }

      handleUpdate(newPackageCost);
    }
    
    const handleRelayPickupCostChange = (levels: PriceLevel[]) => {
      const newPackageCost = {
        id: packageCost.id,
        name: packageCost.name,
        clientDeliveryCosts: packageCost.clientDeliveryCosts,
        relayDeliveryCosts: packageCost.relayDeliveryCosts,
        clientPickupCosts: packageCost.clientPickupCosts,
        relayPickupCosts: levels,
        tags: packageCost.tags,
      }

      handleUpdate(newPackageCost);
    }
    
    const handleTagsChange = (tags: string[]) => {
      const newPackageCost = {
        id: packageCost.id,
        name: packageCost.name,
        clientDeliveryCosts: packageCost.clientDeliveryCosts,
        relayDeliveryCosts: packageCost.relayDeliveryCosts,
        clientPickupCosts: packageCost.clientPickupCosts,
        relayPickupCosts: packageCost.relayPickupCosts,
        tags: tags,
      }

      handleUpdate(newPackageCost);
    }

    return (
      <Fragment>
        {packageCost.id !== 'standard' && <Divider sx={{mt: 3}} variant="middle" textAlign="right">
          <IconButton aria-label="delete" size="small" onClick={() => handleDelete(packageCost.id)}>
            <RemoveCircleIcon/>
          </IconButton>
        </Divider>}
        <TextField
          margin="dense"
          id={`${packageCost.id}-name`}
          label={intl.formatMessage({ id: 'contract.packageCostName' })}
          fullWidth
          variant="standard"
          value={packageCost.name}
          onChange={handleNameChange}
        />
        <PriceLevelItem name="clientDeliveryCost" packageCostId={packageCost.id} priceLevels={packageCost.clientDeliveryCosts}  handleChangePriceLevels={handleClientDeliveryCostsChange} />
        <PriceLevelItem name="relayDeliveryCost" packageCostId={packageCost.id} priceLevels={packageCost.relayDeliveryCosts}  handleChangePriceLevels={handleRelayDeliveryCostsChange} />
        <PriceLevelItem name="clientPickupCost" packageCostId={packageCost.id} priceLevels={packageCost.clientPickupCosts}  handleChangePriceLevels={handleClientPickupCostChange} />
        <PriceLevelItem name="relayPickupCost" packageCostId={packageCost.id} priceLevels={packageCost.relayPickupCosts}  handleChangePriceLevels={handleRelayPickupCostChange} />
        <ChipInput
            label={intl.formatMessage({id: 'contract.packageCost.tags'})}
            value={packageCost.tags ?? []}
            onValueChanged={(tags) => handleTagsChange(tags)}
            textFieldProps={{
              id: "tags",
              margin: "dense",
              label: intl.formatMessage({ id: "contract.packageCost.tags" }),
              variant:"standard",
              fullWidth: true
            }}
          />
      </Fragment>);
}

export default ContractPackageCost;