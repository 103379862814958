import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Slide } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import UserService from '../../services/UserService';
import { ValidatedTextField } from '../../components/ValidatedTextField';
import { handleTextChange } from '../../functions/fieldChangeHandlers';


const RegisterPage = () => {
    const intl = useIntl();

    const [validState, setValidState] = React.useState<{[key: string]: boolean}>({});
    const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [siret, setSiret] = useState<string>('');

    const isValid = () => !(["firstName", "lastName", "email", "password", "companyName", "siret"].map(s => validState[s]).some(v => !v));

    const toggleValidation = (key: string, value: boolean) => {
        setValidState({...validState, [key]: value});
    }

    const handleSubmit = () => {
        setSubmittedOnce(true);

        if(!isValid()) {
            return;
        }

        // eslint-disable-next-line no-console
        UserService.register({
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            siret: siret
        });
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {intl.formatMessage({id: "signup"})}
                </Typography>
                <Box sx={{ m: 1, mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <ValidatedTextField
                                id="firstName"
                                validationType="required"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={firstName}
                                onChange={handleTextChange(setFirstName)}
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                label={intl.formatMessage({id: "firstname"})}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ValidatedTextField
                                required
                                fullWidth
                                id="lastName"
                                label={intl.formatMessage({id: "lastname"})}
                                name="lastName"
                                autoComplete="family-name"
                                validationType="required"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={lastName}
                                onChange={handleTextChange(setLastName)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedTextField
                                required
                                fullWidth
                                id="email"
                                label={intl.formatMessage({id: "email"})}
                                name="email"
                                autoComplete="email"
                                validationType="email"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={email}
                                onChange={handleTextChange(setEmail)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedTextField
                                required
                                fullWidth
                                id="companyName"
                                label={intl.formatMessage({id: "companyname"})}
                                name="companyName"
                                autoComplete="companyName"
                                validationType="required"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={companyName}
                                onChange={handleTextChange(setCompanyName)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedTextField
                                required
                                fullWidth
                                id="siret"
                                label={intl.formatMessage({id: "siret"})}
                                name="siret"
                                autoComplete="siret"
                                validationType="required"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={siret}
                                onChange={handleTextChange(setSiret)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatedTextField
                                required
                                fullWidth
                                name="password"
                                label={intl.formatMessage({id: "password"})}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                validationType="password"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={password}
                                onChange={handleTextChange(setPassword)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {intl.formatMessage({id: "signup"})}
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2">
                                {intl.formatMessage({id: 'haveaccount'}) + " " + intl.formatMessage({id: "signin"})}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Slide>
    );
}

export default RegisterPage;
