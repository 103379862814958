import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Slide } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import UserService from '../../services/UserService';
import { ValidatedTextField } from '../../components/ValidatedTextField';
import { handleTextChange } from '../../functions/fieldChangeHandlers';
import { useQuery } from '../../functions/customHooks';

const ResetPasswordPage = () => {
    const intl = useIntl();
    const query = useQuery();
    const email = query.get("email");
    const token = query.get("token");

    const [validState, setValidState] = React.useState<{[key: string]: boolean}>({});
    const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

    const [password, setPassword] = useState<string>('');

    const isValid = () => !(["password"].map(s => validState[s]).some(v => !v));

    const toggleValidation = (key: string, value: boolean) => {
        setValidState({...validState, [key]: value});
    }

    const handleSubmit = () => {
        setSubmittedOnce(true);

        if(!isValid() || email === null || token === null) {
            return;
        }

        // eslint-disable-next-line no-console
        UserService.resetPassword({
            email: email,
            password: password,
            token: token
        });
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {intl.formatMessage({id: "passwordreset"})}
                </Typography>
                <Box sx={{ m: 1, mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ValidatedTextField
                                required
                                fullWidth
                                name="password"
                                label={intl.formatMessage({id: "password"})}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                validationType="password"
                                onValidate={toggleValidation}
                                submittedOnce={submittedOnce}
                                value={password}
                                onChange={handleTextChange(setPassword)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {intl.formatMessage({id: "reset"})}
                    </Button>
                </Box>
            </Box>
        </Slide>
    );
}

export default ResetPasswordPage;

