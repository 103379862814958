import { Vehicle } from "../models/Vehicle";


export class VehicleService
{
    static async get() {
        const response = await fetch(`/api/vehicle`);
        return await response.json() as Vehicle[];
    }

    static async addVehicle(data: Vehicle) {
        await fetch("/api/vehicle", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async editVehicle(data: Vehicle) {
        await fetch("/api/vehicle", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deleteVehicle(vehicleId: string) {
        await fetch("/api/vehicle/" + vehicleId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }
}

export default VehicleService;