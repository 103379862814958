import { Fragment } from "react";
import { useLocation } from "react-router-dom";

import { Drawer as MuiDrawer, Hidden, styled } from "@mui/material";

import Links from './Links';
import ToolbarDiv from "./ToolbarDiv";

interface INavProps {
    isOpen: boolean;
    toggle: (isOpen: boolean) => void;
}

const drawerWidth = 256;

const Drawer = styled(MuiDrawer)(
    ({ theme }) => ({
        flexShrink: 0,
        width: drawerWidth,
        paddingLeft: 8,
        paddingRight: 8,
        '& .MuiDrawer-paper': {
            paddingLeft: 8,
            paddingRight: 8,
            //position: 'absolute',
            //whiteSpace: 'nowrap',
            width: '256px',
            // transition: theme.transitions.create('width', {
            //   easing: theme.transitions.easing.sharp,
            //   duration: theme.transitions.duration.enteringScreen,
            // }),
            boxSizing: 'border-box',
            // ...(!open && {
            //   overflowX: 'hidden',
            //   transition: theme.transitions.create('width', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.leavingScreen,
            //   }),
            //   width: theme.spacing(7),
            //   [theme.breakpoints.up('sm')]: {
            //     width: theme.spacing(9),
            //   },
            // }),
        },
    }),
);

const Nav = ({ isOpen, toggle }: INavProps) => {
    const location = useLocation();

    const close = () => {
        toggle(false);
    };

    return (
        <Fragment>
            <Hidden lgDown implementation="css">
                <Drawer
                    variant="permanent">
                    <ToolbarDiv/>
                    <Links currentPath={location.pathname} />
                </Drawer>
            </Hidden>
            <Drawer
                onClose={close}
                open={isOpen}>
                <div onKeyDown={close} role="button">
                    <ToolbarDiv/>
                    <Links currentPath={location.pathname} onClick={close} />
                </div>
            </Drawer>
        </Fragment>);
};

export default Nav;