import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {  Autocomplete, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { handleNumberChange, handleDateChange, handleAutocompleteChange, handleToggleChange, handleNumberSelectChange } from "../../functions/fieldChangeHandlers";
import { Planning } from "../../models/Planning";
import PlanningService from "../../services/PlanningService";
import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";
import { Contract } from "../../models/Contract";
import { Vehicle } from "../../models/Vehicle";
import { People } from "../../models/People";
import { PackageActivity } from "../../models/Activity";
import { TimePicker } from "@mui/x-date-pickers";
import { getDayLabel } from "../../functions/dateUtils";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  planning?: Planning
  availablePeople: People[]
  availableContracts: Contract[]
  availableVehicles: Vehicle[]
}

const PlanningDialog = ({ open, handleClose, planning, availablePeople, availableContracts, availableVehicles }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [contractId, setContractId] = useState<{id: string, label: string} | null>(null);
  const [tourCode, setTourCode] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [vehicleId, setVehicleId] = useState<{id: string, label: string} | null>(null);
  const [peopleId, setPeopleId] = useState<{id: string, label: string} | null>(null);
  const [days, setDays] = useState<Day[]>([]);
  const [frequency, setFrequency] = useState<number>(1);
  const [autocreate, setAutocreate] = useState(false);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);
  const [endTime, setEndTime] = useState<Date | undefined>(undefined);
  const [forfait, setForfait] = useState<number | undefined>(undefined);
  const [pauseDuration, setPauseDuration] = useState<number | undefined>(undefined);
  const [distanceDone, setDistanceDone] = useState<number | undefined>(undefined);
  const [packages, setPackages] = useState<{[kind: string] : PackageActivity}>({});

  const [peopleInput, setPeopleInput] = useState('');
  const [contractInput, setContractInput] = useState('');
  const [tourInput, setTourInput] = useState('');
  const [vehicleInput, setVehicleInput] = useState('');
  
  const peopleOptions = availablePeople.map(p => { return { id: p.id, label: p.name}});
  const contractOptions = availableContracts.map(p => { return { id: p.id, label: p.name}});
  const vehicleOptions = availableVehicles.map(p => { return { id: p.id, label: p.matriculation ?? ""}});
  const tourOptions = availableContracts.find(c => c.id === contractId?.id)?.tours.map(t => t.code) ?? []
  // const hasTourForfait = availableContracts.find(c => c.id === contractId?.id)?.forfaitMode === "Tour"

  useEffect(() => {
    if(planning !== undefined) {
      setContractId(contractOptions.find(p => p.id === planning.contractId) ?? null);
      setTourCode(planning.tourCode);
      setStartDate(planning.startDate);
      setEndDate(planning.endDate);
      setVehicleId(vehicleOptions.find(v => v.id === planning?.vehicleId) ?? null);
      setPeopleId(peopleOptions.find(p => p.id === planning?.peopleId) ?? null);
      setDays(planning.days);
      setFrequency(planning.frequency);
      setAutocreate(planning.autocreate);
      setStartTime(planning.startDate)
      setEndTime(planning.endTime)
      setForfait(planning.forfait)
      setPauseDuration(planning.pauseDuration)
      setDistanceDone(planning.distanceDone)
      setPackages(planning.packages)
    } else {
      razFields();
    }
  }, [planning])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    if(contractId === undefined ||
      tourCode === undefined)
    {
      return;
    }

    if (planning) {
      return PlanningService.editPlanning({
        id: planning.id,
        clientId: planning.clientId,
        contractId: contractId?.id ?? '',
        tourCode: tourCode ?? '',
        startDate: startDate,
        endDate: endDate,
        vehicleId: vehicleId?.id ?? '',
        peopleId: peopleId?.id ?? '',
        days: days,
        frequency: frequency,
        autocreate: autocreate,
        distanceDone: distanceDone,
        startTime: startTime,
        endTime: endTime,
        forfait: forfait,
        pauseDuration: pauseDuration,
        packages: packages,
        deleted: false,
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
    else {
      return PlanningService.addPlanning({
        id: '',
        clientId: '',
        contractId: contractId?.id ?? '',
        tourCode: tourCode ?? '',
        startDate: startDate,
        endDate: endDate,
        vehicleId: vehicleId?.id ?? '',
        peopleId: peopleId?.id ?? '',
        days: days,
        frequency: frequency,
        autocreate: autocreate,
        distanceDone: distanceDone,
        startTime: startTime,
        endTime: endTime,
        forfait: forfait,
        pauseDuration: pauseDuration,
        packages: packages,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setContractId(null);
    setTourCode(null);
    setStartDate(undefined);
    setEndDate(undefined);
    setVehicleId(null);
    setPeopleId(null);
    setDays([]);
    setFrequency(1);
    setStartTime(undefined);
    setEndTime(undefined);
    setForfait(undefined);
    setPauseDuration(undefined);
    setDistanceDone(undefined);
    setPackages({});
  }

  const onContractChange = (event: React.SyntheticEvent<Element, Event>, newValue: { id: string; label: string;} | null) => {
    const selectedContract = availableContracts.find(c => c.id === newValue?.id);
    if(selectedContract) {
      const packageMap : {[kind: string] : PackageActivity} = {};
      selectedContract.packageCosts.forEach(pc => packageMap[pc.id] = { relayDeliveries: 0, clientDeliveries: 0, clientPickups: 0, relayPickups: 0});
      setPackages(packageMap);
      setContractId(newValue);
      
      // if (selectedContract.forfaitMode === "Tour") {
      //   setForfait(selectedContract.forfait);
      // }
    }
  }

  const daysArray : Day[] = [0, 1, 2, 3, 4, 5, 6];

  const DayButton = (day: Day) => {
    return (
    <ToggleButton value={day} sx={{ borderWidth: 0}}>
      <Avatar><Typography variant="body2">{getDayLabel(intl, day)}</Typography></Avatar>
    </ToggleButton>
    )
  }

  const handlePackageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    packageKind: string,
    update: (newValue: number | undefined, packageActivity: PackageActivity) => void) => {
      const newMap : {[kind: string] : PackageActivity} = {};
      Object.keys(packages).forEach(pc => newMap[pc] = { 
        relayDeliveries: packages[pc].relayDeliveries, 
        clientDeliveries: packages[pc].clientDeliveries, 
        clientPickups: packages[pc].clientPickups, 
        relayPickups: packages[pc].relayPickups
      });
      
      const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
      const existingPackage = newMap[packageKind];
      if (existingPackage) {
        update(val, existingPackage);
        newMap[packageKind] = existingPackage;
        setPackages(newMap);
      }
  }

  const DeliveryItem = (packageActivity: PackageActivity, packageKind: string) => {
    const selectedContract = availableContracts.find(c => c.id === contractId?.id);
    const packageKindName = selectedContract?.packageCosts.find(pc => pc.id === packageKind)?.name ?? '';
    return <Fragment>
      <Divider sx={{mt: 3}}>{`${intl.formatMessage({id: 'planning.package'})} ${packageKindName}`}</Divider>
      <TextField
        margin="dense"
        key={`${packageKind}-clientDeliveries`}
        label={intl.formatMessage({ id: 'planning.clientDeliveries' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.clientDeliveries}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.clientDeliveries = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-relayDeliveries`}
        label={intl.formatMessage({ id: 'planning.relayDeliveries' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.relayDeliveries}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.relayDeliveries = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-clientPickups`}
        label={intl.formatMessage({ id: 'planning.clientPickups' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.clientPickups}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.clientPickups = v)}
      />
      <TextField
        margin="dense"
        key={`${packageKind}-relayPickups`}
        label={intl.formatMessage({ id: 'planning.relayPickups' })}
        type="number"
        fullWidth
        variant="standard"
        value={packageActivity.relayPickups}
        InputProps={{
          inputProps: { min: 0 }
        }}
        onChange={(evt) => handlePackageChange(evt, packageKind, (v, pa) => pa.relayPickups = v)}
      />
    </Fragment>
  }

  
  return (<Dialog 
      open={open} 
      onClose={handleDismiss} 
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: planning !== undefined ? 'planning.edit' :'planning.add' })}</DialogTitle>
    <DialogContent sx={{marginTop: 2}}>
      
    <Autocomplete
      value={contractId}
      onChange={onContractChange}
      inputValue={contractInput}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(event, newInputValue) => {
        setContractInput(newInputValue);
      }}
      id="contractId"
      options={contractOptions}
      renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'planning.contractId' })} />}
    />
    <Autocomplete
      value={tourCode}
      onChange={handleAutocompleteChange(setTourCode)}
      inputValue={tourInput}
      onInputChange={(event, newInputValue) => {
        setTourInput(newInputValue);
      }}
      id="tourCode"
      options={tourOptions}
      renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'planning.tourCode' })} />}
    />
    <MobileDatePicker
      label={intl.formatMessage({ id: 'planning.startDate' })}
      inputFormat="dd/MM/yyyy"
      value={startDate}
      onChange={handleDateChange(setStartDate)}
      renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
    />
    <MobileDatePicker
      label={intl.formatMessage({ id: 'planning.endDate' })}
      inputFormat="dd/MM/yyyy"
      value={endDate}
      onChange={handleDateChange(setEndDate)}
      renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
    />
    <Autocomplete
      value={vehicleId}
      onChange={handleAutocompleteChange(setVehicleId)}
      inputValue={vehicleInput}
      groupBy={(option) => availableVehicles.find(v => v.id === option.id)?.contractId === contractId?.id ? intl.formatMessage({ id: 'assigned' }): intl.formatMessage({ id: 'other' })}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(event, newInputValue) => {
        setVehicleInput(newInputValue);
      }}
      id="vehicleId"
      options={vehicleOptions.sort(opt => availablePeople.find(v => v.id === opt.id)?.contractId === contractId?.id ? -1 : 1)}
      renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'planning.vehicleId' })} />}
    />
    <Autocomplete
      value={peopleId}
      onChange={handleAutocompleteChange(setPeopleId)}
      inputValue={peopleInput}
      groupBy={(option) => availablePeople.find(p => p.id === option.id)?.contractId === contractId?.id ? intl.formatMessage({ id: 'assigned' }): intl.formatMessage({ id: 'other' })}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(event, newInputValue) => {
        setPeopleInput(newInputValue);
      }}
      id="peopleId"
      options={peopleOptions.sort(opt => availablePeople.find(p => p.id === opt.id)?.contractId === contractId?.id ? -1 : 1)}
      renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={intl.formatMessage({ id: 'planning.peopleId' })} />}
    />
    <ToggleButtonGroup
        value={days}
        onChange={handleToggleChange(setDays)}>
      {daysArray.map(DayButton)}
    </ToggleButtonGroup>
    
    <FormControl fullWidth variant="standard" sx={{mt: 1}}>
        <InputLabel>{intl.formatMessage({ id: 'planning.frequency' })}</InputLabel>
        <Select
            id="kind"
            value={frequency.toString()}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'planning.frequency' })}
            onChange={handleNumberSelectChange(setFrequency)}>
              {Array.from(Array(56).keys()).map(n => <MenuItem value={n}>{n}</MenuItem>)}
        </Select>
    </FormControl>
    <FormControl>
      <FormControlLabel control={<Switch value={autocreate} onClick={() => setAutocreate(!autocreate)}/>} label={intl.formatMessage({id: 'planning.autocreate'})} />
    </FormControl>
    <TimePicker
        label={intl.formatMessage({ id: 'planning.startTime' })}
        value={startTime === undefined ? null : startTime}
        onChange={handleDateChange(setStartTime)}
        openTo="hours"
        renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
      />
      <TimePicker
        renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
        label={intl.formatMessage({ id: 'planning.endTime' })}
        value={endTime === undefined ? null : endTime}
        openTo="hours"
        onChange={handleDateChange(setEndTime)}
      />
      <TextField
        margin="dense"
        id="forfait"
        label={intl.formatMessage({ id: 'planning.forfait' })}
        type="number"
        fullWidth
        variant="standard"
        value={forfait}
        onChange={handleNumberChange(setForfait)}
      />
      <TextField
        margin="dense"
        id="pauseDuration"
        label={intl.formatMessage({ id: 'planning.pauseDuration' })}
        type="number"
        fullWidth
        variant="standard"
        value={pauseDuration}
        onChange={handleNumberChange(setPauseDuration)}
      />
      <TextField
        margin="dense"
        id="distanceDone"
        label={intl.formatMessage({ id: 'planning.distanceDone' })}
        type="number"
        fullWidth
        variant="standard"
        value={distanceDone}
        onChange={handleNumberChange(setDistanceDone)}
      />
      {Object.keys(packages).map((p) => DeliveryItem(packages[p], p))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <AsyncButton onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</AsyncButton>
    </DialogActions>
  </Dialog>);
}

export default PlanningDialog;