import styled from "@emotion/styled";

import { Button, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu';

import logo from './logo.png';
import { Fragment, useState } from "react";
import LanguageService from "../../services/LanguageService";
import ExpandMore from "@mui/icons-material/ExpandMore";

//const drawerWidth: number = 240;

//const MaterialUIAppBar = styled(MuiAppBar, {
//    shouldForwardProp: (prop) => prop !== 'open',
//})<IAppBarProps>(({ theme, open }) => ({
//    zIndex: theme.zIndex.drawer + 1,
//    transition: theme.transitions.create(['width', 'margin'], {
//        easing: theme.transitions.easing.sharp,
//        duration: theme.transitions.duration.leavingScreen,
//    }),
//    ...(open && {
//        marginLeft: drawerWidth,
//        width: `calc(100% - ${drawerWidth}px)`,
//        transition: theme.transitions.create(['width', 'margin'], {
//            easing: theme.transitions.easing.sharp,
//            duration: theme.transitions.duration.enteringScreen,
//        }),
//    }),
//}));


const Logo = styled('img')(({ theme }) => ({
    width: '40px',
    height: 'auto',
}));


const LanguageMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const theme = useTheme()

    const selectedLanguage = LanguageService.get()

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelectLanguage = (language: string) => {
        setAnchorEl(null)
        LanguageService.set(language)
        window.location.reload()
    }

    return (
        <Fragment>
            <Button
                color="primary"
                variant="contained"
                disableElevation
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.primary.main
                    }
                }}
                onClick={handleMenu}
                endIcon={<ExpandMore />}>
                {selectedLanguage}
            </Button>
            <Menu
                id="menu-language"
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top"
                }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top"
                }}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={() => handleSelectLanguage("fr")}>FR - Français</MenuItem>
                <MenuItem onClick={() => handleSelectLanguage("en")}>EN - English</MenuItem>
                <MenuItem onClick={() => handleSelectLanguage("es")}>ES - Espanol</MenuItem>
            </Menu>
        </Fragment>)
}


interface IAppBarProps {
    title: JSX.Element;
    toggleNav: (isOpen: boolean) => void;
}

const AppBar = ({ title, toggleNav }: IAppBarProps) => {
    const open = () => {
        toggleNav(true);
    };

    return (
        <MuiAppBar position="fixed">
            <Toolbar>
                <Hidden lgUp>
                    <IconButton
                        sx={{ position: 'absolute' }}
                        color="inherit"
                        onClick={open}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <Typography
                    color="inherit"
                    noWrap
                    flexGrow={1}
                    variant="h6"
                    textAlign="center">
                    {title}
                </Typography>
                <LanguageMenu />
                <Logo
                    src={logo}
                    alt="increase priority"
                />
            </Toolbar>
        </MuiAppBar>
    );
};

export default AppBar;