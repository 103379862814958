import { SelectChangeEvent } from "@mui/material";

export function handleTextChange(action: React.Dispatch<React.SetStateAction<string>>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => action(event.target.value);
};

export function handleAutocompleteChange<T>(action: React.Dispatch<React.SetStateAction<T | null>>) {
    return (event: React.SyntheticEvent<Element, Event>, newValue: T | null) => action(newValue);
};

export function handleNumberChange(action: React.Dispatch<React.SetStateAction<number | undefined>>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
        action(val);
    }
};

export function handleDateChange(action: React.Dispatch<React.SetStateAction<Date | undefined>>) {
    return (date: Date | null) => {
        action(date ?? undefined);
    }
};

export function handleSelectChange<T extends string>(action: React.Dispatch<React.SetStateAction<T>>) {
    return (event: SelectChangeEvent) => {
        action(event.target.value as T);
    }
};

export function handleNumberSelectChange<T extends number>(action: React.Dispatch<React.SetStateAction<T>>) {
    return (event: SelectChangeEvent) => {
        action(+event.target.value as T);
    }
};

export function handleToggleChange<T>(action: React.Dispatch<React.SetStateAction<T[]>>) {
    return (event: React.MouseEvent<HTMLElement>, newValues: T[]) => {
        action(newValues);
    }
};

// export function handleNumberChange(action: React.Dispatch<React.SetStateAction<number | undefined>>) {
//     return (event: React.ChangeEvent<HTMLInputElement>) => {
//         const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
//         action(val);
//     }
// };