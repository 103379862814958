import { useEffect, useState, useMemo, Fragment } from "react";
import { useIntl } from "react-intl";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";

import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";
import UserService from "../../services/UserService";
import AccountService from "../../services/AccountService";

interface IProps {
    open: boolean;
    handleClose: (result: boolean) => void
}

type LineProps = {
  children: any
}

const SellTitle = ({children}: LineProps) => {
  return <Typography variant="h3" sx={{margin: 2}}>{children}</Typography>
}

const SellClause = ({children}: LineProps) => {
  return <Typography variant="body1" sx={{margin: 2}}>{children}</Typography>
}

const SellTermsDialog = ({open, handleClose} : IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [accepted, setAccepted] = useState(false);

    const handleDismiss = () => {
      handleClose(false)
    }

    const handleValidate = () => {
      handleClose(true)
    }

    const Article1 = () => {
      return <Fragment>
        <SellTitle>Article 1 - Champ d'application</SellTitle>
        <SellClause>Les présentes Conditions Générales de Vente (dites "CGV") s'appliquent, sans restriction ni réserve,
        à tout achat de services tels que proposés par le Prestataire aux Clients (ou "Client") sur le site uptolog.com</SellClause>
        <SellClause>Les caractéristiques principales des services sont présentées sur le site uptolog.com</SellClause>
        <SellClause>Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est la seule responsabilité du client.</SellClause>
        <SellClause>Ces CGV sont accessibles à tout moment sur le site uptolog.com et prévaudront sur tout autre document.</SellClause>
        <SellClause>Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en cochant la case prévue à cet effet avant la mise en oeuvre de la procédure de commande en ligne du site uptolog.com</SellClause>
        <SellClause>Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire constituent la preuve de l'ensemble des transactions conclues avec le Client.</SellClause>
        <SellClause>Les coordonnées du Prestataire sont les suivantes : </SellClause>
        <SellClause>Uptolog, SAS<br/>Capital social de 1400 euros<br/>Immatriculé au RCS de Nanterre, sous le numéro 919506014<br/>257 Boulevard Jean Jaurès, 92100 Boulogne-Billancourt<br/>mail : contact@uptolog.com</SellClause>
      </Fragment>
    }

    const Article2 = () => {
      return <Fragment>
        <SellTitle>Article 2 - Prix</SellTitle>
          <SellClause>Les Services sont fournis aux tarifs en vigueur figurant sur le site uptolog.com lors de l'enregistrement de la commande par le Prestataire.</SellClause>
          <SellClause>Les prix sont exprimés en Euros, HT et TTC.</SellClause>
          <SellClause>Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire sur le site uptolog.com</SellClause>
          <SellClause>Ces tarifs sont fermes et non révisables pendant leur période de validité, mais le Prestataire se réserve le droit, hors période de validité, d'en modifier les prix à tout moment.</SellClause>
          <SellClause>Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services commandés.</SellClause>
      </Fragment>
    }

    const Article3 = () => {
      return <Fragment>
        <SellTitle>Article 3 - Commandes</SellTitle>
          <SellClause>Il appartient au Client de sélectionner sur le site uptolog.com les Services qu'il désire commander.</SellClause>
          <SellClause>Le client a la possibilité de souscrire à l’une des 2 offres suivantes :</SellClause>
          <SellClause>- Paiement à l’utilisation (prix fixe par tournée saisie, facturé tous les 30 jours à partir de la souscription).</SellClause>
          <SellClause>- Abonnement mensuel (enveloppe de tournées à un prix unitaire préférentiel). Le client ne pourra pas bénéficier d’un remboursement des tournées non consommées de son enveloppe mensuelle. En cas de dépassement de l’enveloppe, le nombre de tournées supplémentaire sera facturé selon les conditions tarifaires précisées sur le site le mois suivant.</SellClause>
          <SellClause>Toute commande passée sur le site uptolog.com constitue la formation d'un contrat conclu a distance entre le Client et le Prestataire.</SellClause>
          <SellClause>Dans le cas d'un abonnement mensuel, la commande fera l'objet d'un renouvellement tacite. Le Client a la possibilité de résilier son abonnement en cours de mois, la résiliation se effective le mois suivant.</SellClause>
      </Fragment>
    }

    const Article4 = () => {
      return <Fragment>
        <SellTitle>Article 4 - Conditions de Paiement</SellTitle>
          <SellClause>Le prix est payé par voie de paiement sécurisé, par carte bancaire.</SellClause>
          <SellClause>Le prix est payé à la souscription, puis mensuellement</SellClause>
          <SellClause>Les données de paiement sont échangées de façon sécurisée grâce au protocole défini par le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le site uptolog.com</SellClause>
          <SellClause>Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Prestataire.</SellClause>
          <SellClause>Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.</SellClause>
      </Fragment>
    }

    const Article5 = () => {
      return <Fragment>
        <SellTitle>Article 5 - Fourniture des prestations</SellTitle>
        <SellClause>Les Services commandés par le Client seront fournis selon les modalités suivantes :</SellClause>
        <SellClause>Lesdits Services seront fournis immédiatement à compter de la validation définitive de la Commande par le client, dans les conditions prévues par les présentes CGV.</SellClause>
        <SellClause>Le Prestataire s'engage a faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyer, dans le délai ci-dessus précisé.</SellClause>
        <SellClause>En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptée par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.</SellClause>
        <SellClause>En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptée par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.</SellClause>
      </Fragment>
    }

    const Article6 = () => {
      return <Fragment>
        <SellTitle>Article 6 - Responsabilité du Prestataire - Garanties</SellTitle>
        <SellClause>Les données insérées par le Client dans le Logiciel appartiennent au Client. Les calculs réalisés par la solution à partir de ces données sont réputés fiables, la le Prestataire ne s'engage pas à ce qu'ils soient exempts d'erreur.</SellClause>
        <SellClause>La solution comporte une fonctionnalité d'aide à la facturation, mais ne peut pas être considérée comme une outil juridique d'édition de facture conforme à la loi française.</SellClause>
        <SellClause>En cas d'anomalie constatée dans l'utilisation du logiciel, où d'impossiblité d'utiliser une fonctionnalité commanée, le Client pourra envoyer une demande de support par email à l'adresse support@uptolog.com. Le Prestataire s'engage à fournir une réponse aux demandes de support dans un délai de 24 heures ouvrées.</SellClause>
      </Fragment>
    }
    
    
    const Article7 = () => {
      return <Fragment>
        <SellTitle>Article 7 - Données personnelles</SellTitle>
        <SellClause>Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et leur réalisation et leur délivrance, ainsi qu'à leur transmission à des tiers intervenant dans la réalisation des Services. Ces données à caractère personnel sont récoltées uniquement pour l'exécution du contrat de prestations de services.</SellClause>
        <SellClause><b>7.1 Collecte des données à caractère personnel</b></SellClause>
        <SellClause>Les données à caractère personnel qui sont collectées sur le site uptolog.com sont les suivantes :</SellClause>
        <SellClause><b>Ouverture de compte</b></SellClause>
        <SellClause>Lors de la création du compte Client / Utilisateur, noms, prénoms et adresse e-mail</SellClause>
        <SellClause><b>Paiement</b></SellClause>
        <SellClause>Dans le cadre du paiement des Prestations proposées sur le site uptolog.com, celui-ci enregistre des données financières relatives au compte bancaire ou à la carte de crédit du Client / Utilisateur</SellClause>
        <SellClause><b>7.2 Destinataires des données à caractère personnel</b></SellClause>
        <SellClause>Les données à caractère personnel sont utilisées par le Prestataire et ses co-contractants pour l'exécution du contrat et pour assurer l'efficacité de la prestation de services, sa réalisation et sa délivrance.</SellClause>
        <SellClause>La ou les catégorie(s) de co-contractants est (sont) :</SellClause>
        <SellClause>- Les prestataires établissements de paiement</SellClause>
        <SellClause>Le responsable du traitement des données est le Prestataire, au sens de la loi informatique et libertés et à compter du 25 mai 2018 du Réglement 2016/679 sur la protection des données à caractère personnel.</SellClause>
        <SellClause><b>7.3 Limitation du traitement</b></SellClause>
        <SellClause>Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas utilisées à des fins publicitaires ou de marketing.</SellClause>
        <SellClause><b>7.4 Durée de conservation des données</b></SellClause>
        <SellClause>Le Prestataire conservera les données ainsi recueillies pendant un délai de 30 jours à compter de la cloture du compte du Client.</SellClause>
        <SellClause><b>7.5 Sécurité et confidentialité</b></SellClause>
        <SellClause>Le Prestataire met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. TOutefois, il est à signaler qu'Internet n'est pas un environnement complètement sécurisé, et le Prestataire ne peut garentir la sécurité de la transmission ou du stockage des informations sur Internet.</SellClause>
        <SellClause><b>7.6 Mise en oeuvre des droits des Clients et Utilisateurs</b></SellClause>
        <SellClause>En application de la réglementation applicable aux données à caractère personnel, les Clients et Utilisateurs du site uptolog.com disposent des droits suivants :</SellClause>
        <SellClause>Ils peuvent mettre à jour ou supprimer les données qui les concernent de la manière suivante: </SellClause>
        <SellClause>Envoi d'un email à l'adresse support@uptolog.com</SellClause>
        <SellClause>Suppression automatique 30 jours après la cloture du compte.</SellClause>
        <SellClause>Ils peuvent supprimer leur compte en écrivant à l'adresse support@uptolog.com.</SellClause>
        <SellClause>Ils peuvent exercer leur droit d'accès pour connaître les données personnelles les concernant en écrivant à l'adresse support@uptolog.com.</SellClause>
        <SellClause>Si les données à caractère personnel détenues par le Prestataire sont inexactes, ils peuvent demander la mise à jour des informations en écrivant à l'adresse support@uptolog.com.</SellClause>
        <SellClause>Ils peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données, en écrivant à l'adresse support@uptolog.com.</SellClause>
        <SellClause>Enfin, ils peuvent s'opposer au traitement de leurs données par le Prestataire</SellClause>
        <SellClause>Ces droits, dès lors qu'ils ne s'opposent pas à la finalité du traitement, peuvent être exercés en adressant une demande par courrier ou par email à l'adresse support@uptolog.com</SellClause>
        <SellClause>Une réponse devra être apportée dans un délai maximal d'un mois.</SellClause>
        <SellClause>En cas de refus de faire droit à la demande du CLient, celui-ci doit être motivé.</SellClause>
        <SellClause>Le Client est informé qu'en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.</SellClause>
    </Fragment>
    }

    const Article8 = () => {
      return <Fragment>
        <SellTitle>Article 8 - Propriété intellectuelle</SellTitle>
        <SellClause>Le contenu du site uptlog.com est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationnales relatives à la propriété intellectuelle.</SellClause>
        <SellClause>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.</SellClause>
      </Fragment>
    }
    
    const Article9 = () => {
      return <Fragment>
        <SellTitle>Article 9 - Droit applicable - Langue</SellTitle>
        <SellClause>Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.</SellClause>
        <SellClause>Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.</SellClause>
      </Fragment>
    }

    return (<Dialog 
      open={open}
      onClose={handleDismiss}
      fullScreen={fullScreen}
      maxWidth="md"
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
        <DialogTitle>
          {intl.formatMessage({id: 'cgv'})}
        </DialogTitle>
        <DialogContent >
          {Article1()}
          {Article2()}
          {Article3()}
          {Article4()}
          {Article5()}
          {Article6()}
          {Article7()}
          {Article8()}
          {Article9()}
        </DialogContent>
        <DialogActions>      
          <FormGroup>
            <FormControlLabel control={<Switch value={accepted} onClick={() => setAccepted(!accepted)}/>} label={intl.formatMessage({id: 'cgv.accept'})} />
          </FormGroup>
          <Button variant="contained" disabled={!accepted} onClick={handleValidate}>{intl.formatMessage({id: 'cgv.ok'})}</Button>
        </DialogActions>
      </Dialog>);
}

export default SellTermsDialog;