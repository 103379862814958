import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CostKind, GainKind, Report, ReportContract } from "../../models/Report";
import { ccyFormat } from "../../functions/formaters";
import ReportService from "../../services/ReportService";
import { useNavigate } from "react-router-dom";

const ReportsPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [report, setReport] = useState<Report>();
    
    useEffect(() => {
        loadData();
    }, [selectedDate]);

    const loadData = () => {
        ReportService.get(selectedDate).then(setReport);
    }
    
    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setSelectedDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + delta));
        setSelectedDate(newDate);
    }

    const isInPast = selectedDate.getMonth() < (new Date().getMonth()) || selectedDate.getFullYear() < (new Date().getFullYear());

    const total = report ? 
        (report.gains.reduce((sum, gain) => sum + gain.amount, 0) - 
            report.costs.reduce((sum, cost) => sum + cost.amount, 0)) : 0;

    const projectedGains = () => {
        if(report) {
            return report.expectedGains.reduce((sum, gain) => sum + gain.amount, 0)
        }
        else {
            return 0;
        }
    }
    
    const projectedCosts = () => {
        if(report) {
            return report.expectedCosts.reduce((sum, cost) => sum + cost.amount, 0)
        }
        else {
            return 0;
        }
    }
    
    const projectedTotal = projectedGains() - projectedCosts();

    const ContractRow = (contract: ReportContract) => {

        const peopleChargeTotal = contract.current.peopleStats.reduce((s, ps) => {
            const affectationRatio = ps.tourAffectations.length == 0 ? 1 : ps.tourAffectations.reduce((ratio, affectation) => ratio + affectation.ratio, 0)
            return s + (ps.income + 0.45 * ps.income) * ps.ratio * affectationRatio;
        }, 0);


        const peopleCurrentDeliveryIncome = contract.current.peopleStats.reduce((s, ps) => s + (ps.deliveryIncome ?? 0), 0);
        const peopleProjectedDeliveryIncome = contract.expected.peopleStats.reduce((s, ps) => s + (ps.deliveryIncome ?? 0), 0);

        const vehicleProjectedChargeTotal = contract.expected.vehicleStats.reduce((s, vs) => {
            const affectationRatio = vs.tourAffectations.reduce((ratio, affectation) => ratio + affectation.ratio, 0)
            const gasolineCost = vs.gasolineCost;
            const fixedCosts = (vs.ownership === 'Rental' ? vs.rentalcost : 0) + vs.insurance;
            const vehicleCost = (gasolineCost + fixedCosts * vs.ratio) * affectationRatio;

            return s + vehicleCost;
        }, 0);

        const vehicleCurrentChargeTotal = contract.current.vehicleStats.reduce((s, vs) => {
            const affectationRatio = vs.tourAffectations.reduce((ratio, affectation) => ratio + affectation.ratio, 0)
            const fixedCosts = (vs.ownership === 'Rental' ? vs.rentalcost : 0) + vs.insurance;
            const vehicleCost = (vs.gasolineCost + fixedCosts * vs.ratio) * affectationRatio;

            return s + vehicleCost;
        }, 0);

        const currentActivityTotal = contract.current.tourForfaitStats.reduce((s, ts) => s + ts.ca, 0) + contract.current.tourPackagesStats.reduce((s, ts) => s + ts.ca, 0);
        const projectedActivityTotal = contract.expected.tourForfaitStats.reduce((s, ts) => s + ts.ca, 0) + contract.expected.tourPackagesStats.reduce((s, ts) => s + ts.ca, 0);
        const contractCurrentMarginTotal = currentActivityTotal - peopleChargeTotal - vehicleCurrentChargeTotal - peopleCurrentDeliveryIncome;
        const contractProjectedMarginTotal = projectedActivityTotal - peopleChargeTotal - vehicleProjectedChargeTotal - peopleProjectedDeliveryIncome;

        return <TableRow key={contract.id}>
            <TableCell><IconButton onClick={() => navigate(`/contract/${contract.id}`)}><FolderOutlinedIcon/></IconButton></TableCell>
            <TableCell>{contract.name}</TableCell>
            <TableCell align="right">{ccyFormat(currentActivityTotal)}</TableCell>
            {!isInPast && <TableCell align="right">{ccyFormat(projectedActivityTotal)}</TableCell>}
            <TableCell align="right">{ccyFormat(contractCurrentMarginTotal)}</TableCell>
            {!isInPast && <TableCell align="right">{ccyFormat(contractProjectedMarginTotal)}</TableCell>}
        </TableRow>
    }
    
    const GainRow = (kind : GainKind) => {
        const matchingGain = report?.gains.find(r => r.kind === kind)?.amount ?? 0;

        return <TableRow key={kind.toLowerCase()}>
            <TableCell>{intl.formatMessage({id: `reports.gains.${kind.toLowerCase()}`})}</TableCell>
            <TableCell align="right" sx={{color: 'green'}}>{ccyFormat(matchingGain)}</TableCell>
        </TableRow>
    }
    
    const CostRow = (kind : CostKind) => {
        const matchingCost = report?.costs.find(r => r.kind === kind)?.amount ?? 0;

        return <TableRow key={kind.toLowerCase()}>
            <TableCell>{intl.formatMessage({id: `reports.costs.${kind.toLowerCase()}`})}</TableCell>
            <TableCell align="right" sx={{color: 'red'}}>- {ccyFormat(matchingCost)}</TableCell>
        </TableRow>
    }

    return <Fragment>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
                <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                    <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <MobileDatePicker
                    views={["month", "year"]}
                    label={intl.formatMessage({ id: 'reports.date' })}
                    inputFormat="MMMM yyyy"
                    value={selectedDate}
                    onChange={handleSelectedDateChange}
                    renderInput={(params: any) => <TextField {...params} />}
                />
                <IconButton aria-label="next"  onClick={() => handleChangeDate(1)}>
                    <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
            </Stack>

            <TableContainer component={Paper}>
                <Table aria-label="report-table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">{intl.formatMessage({id: 'amount'})}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='gains'>
                            <TableCell colSpan={2}><b>{intl.formatMessage({id: 'reports.gains'})}</b></TableCell>
                        </TableRow>
                        {GainRow("Deliveries")}
                        {GainRow("Forfaits")}
                        <TableRow key='costs'>
                            <TableCell colSpan={2}><b>{intl.formatMessage({id: 'reports.costs'})}</b></TableCell>
                        </TableRow>
                        {CostRow("Vehicle")}
                        {CostRow("Gasoline")}
                        {CostRow("Salary")}
                        {CostRow("Subcontracting")}
                        {CostRow("Expense")}
                        {CostRow("Bonus")}
                        <TableRow>
                            <TableCell align="right">
                                <Typography><b>{intl.formatMessage({id: (isInPast ? 'total' : 'reports.currenttotal')})}</b></Typography>
                            </TableCell>
                            <TableCell align="right"  sx={{color: isInPast ? (total > 0 ? 'green' : 'red') : 'black'}}>
                                <b>{ccyFormat(total)}</b>
                                </TableCell>
                        </TableRow>
                        {!isInPast && 
                            <Fragment>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography><b>{intl.formatMessage({id: 'reports.projectedgains'})}</b></Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{color: 'green'}}>
                                        <b>{ccyFormat(projectedGains())}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography><b>{intl.formatMessage({id: 'reports.projectedcosts'})}</b></Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{color: 'red'}}>
                                        <b>{ccyFormat(projectedCosts())}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography><b>{intl.formatMessage({id: 'reports.projectedmargin'})}</b></Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{color: projectedTotal > 0 ? 'green' : 'red'}}>
                                        <b>{ccyFormat(projectedTotal)}</b></TableCell>
                                </TableRow>
                            </Fragment>
                        }
                    </TableBody>
                </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="contract-table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{intl.formatMessage({ id: 'page.contract' })}</TableCell>
                        <TableCell align="right">{intl.formatMessage({ id: 'reports.contract.gains' })}</TableCell>
                        {!isInPast && <TableCell align="right">{intl.formatMessage({ id: 'reports.projectedgains' })}</TableCell>}
                        <TableCell align="right">{intl.formatMessage({ id: 'reports.contract.margin' })}</TableCell>
                        {!isInPast && <TableCell align="right">{intl.formatMessage({ id: 'reports.projectedmargin' })}</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {report?.contracts.map(ContractRow)}
                </TableBody>
            </Table>
        </TableContainer>
    </Fragment>
}


export default ReportsPage;