import { useTheme } from '@emotion/react';
import { Badge, Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from '@mui/material';
import React, { ComponentType, Fragment, useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { UserContext } from '../functions/userContext';
import { SubscriptionProduct } from '../models/Account';
import { Role } from '../models/User';

interface IProps {
    children: JSX.Element;
    className?: string;
    disabled: boolean;
    to: string;
}

const LinkButton = styled(Button)(({theme}) => ({
    '&:active': {
        boxShadow: 'none'
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    marginBottom: 2,
    marginTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    textTransform: 'none' as 'none'
}));

const IconContainer = styled(Box)(({theme}) => ({
    marginRight: theme.spacing(4)
}));


const RouterLink = styled(ReactLink)(() => ({
    textDecoration: 'none'
}));

const ActivableLink = ({ children, className, disabled, to }: IProps) =>
    disabled ? (
        <span>{children}</span>
    ) : (<RouterLink className={className} to={to}>
            {children}
        </RouterLink>);
    
interface ILinkProps {
    currentPath: string;
    disabled?: boolean;
    icon?: ComponentType;
    label: JSX.Element;
    nested?: boolean;
    to: string;
    badge?: number;
    roles?: Role[];
    subscriptions?: SubscriptionProduct[];
    onClick?: VoidFunction;
}

const Link = ({ currentPath, disabled, icon: Icon, label, nested, to, badge, roles, subscriptions, onClick }: ILinkProps) => {
    const theme = useTheme();
    
    const user = useContext(UserContext);
    const hasSubscription = subscriptions && subscriptions.length > 0 ? subscriptions.includes(user?.subscriptionProduct ?? "trial") : true
    const hasRole = roles && roles.length > 0 ? 
        (roles.findIndex(r => user?.roles.includes(r)) >= 0) :
        true

    if(!hasSubscription || !hasRole) {
        return <Fragment/>
    }

    return <ActivableLink disabled={false} to={to}>
        <LinkButton
            onClick={onClick}
            variant="contained"
            //color="secondary"
            fullWidth
            disableRipple
            disableFocusRipple>
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%'}}>
                    <IconContainer sx={{
                        alignItems: 'center',
                        display: 'flex'}}>
                        {Icon && <Badge badgeContent={badge} color="error">
                            <Icon />
                        </Badge>
                        }
                    </IconContainer>
                    <Typography>{label}</Typography>
                </Box>
        </LinkButton>
    </ActivableLink>;
};

export default Link;