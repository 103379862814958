import { Fragment, useEffect, useState } from "react";

import { Avatar, CircularProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import EvStationIcon from '@mui/icons-material/EvStation';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';

import { useStorageState } from "../../functions/customHooks";
import { Vehicle, VehicleKind } from "../../models/Vehicle";
import VehicleService from "../../services/VehicleService";
import VehicleDialog from "./VehicleDialog";
import Fab from "../../components/Fab";
import { AsyncIconButton } from "../../components/AsyncButton";
import { Contract } from "../../models/Contract";
import { ContractService } from "../../services/ContractService";

const FleetPage = () => {
    const theme = useTheme();
    const [fleet, setFleet] = useStorageState<Vehicle[]>('fleet', []);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedVehicle, setEditedVehicle] = useState<Vehicle | undefined>(undefined);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ContractService.get().then(setContracts);
        loadFleet();
    }, []);

    const handleVehicleClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadFleet();
        }
    }

    const loadFleet = () => {
        setLoading(true);
        VehicleService.get().then((data) => {
            setFleet(data);
            setLoading(false);
        });
    }

    const deleteVehicle = (vehicleId: string) => {
        return VehicleService.deleteVehicle(vehicleId).then(() => loadFleet());
    }

    const handleOpenDialog = (vehicle: Vehicle | undefined) => {
        setEditedVehicle(vehicle);
        setOpenDialog(true);
    }

    const iconFromKind = (kind: VehicleKind) => {
        if (kind === "Electric") {
            return <Avatar sx={{backgroundColor: theme.palette.success.main}}><EvStationIcon /></Avatar>
        } else if (kind === "CargoBike") {
            return <Avatar sx={{backgroundColor: theme.palette.success.light}}><BikeScooterIcon /></Avatar>
        }else {
            return <Avatar sx={{backgroundColor: theme.palette.warning.main}}><LocalGasStationIcon /></Avatar>
        }
    }

    const VehicleItem = (vehicle: Vehicle) => {
        return (<ListItem 
            key={vehicle.id}
            secondaryAction={<AsyncIconButton edge="end" ariaLabel="delete" onClick={() => deleteVehicle(vehicle.id)}>
                    <DeleteIcon />
                </AsyncIconButton>}>
            <ListItemButton onClick={() => handleOpenDialog(vehicle)}>
                <ListItemAvatar>
                    {iconFromKind(vehicle.kind)}
                </ListItemAvatar>
                <ListItemText primary={vehicle.matriculation} secondary={vehicle.type}/>
            </ListItemButton>
        </ListItem>)
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="nested-list-subheader">
                {fleet.map(VehicleItem)}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <VehicleDialog open={openDialog} vehicle={editedVehicle} contracts={contracts} handleClose={handleVehicleClose} />
        </Fragment>);
}


export default FleetPage;