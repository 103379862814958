import { Alert, Snackbar, Typography } from "@mui/material";

type IProps = {
    open: boolean;
    handleClose: VoidFunction;
    message: string;
    detail?: string;
}

const AlertSnackbar = ({open, handleClose, message, detail} : IProps) => {
    return <Snackbar 
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            <Typography variant="subtitle2">{message}</Typography>
            {detail && <Typography variant="body1">{detail}</Typography>}
        </Alert>
    </Snackbar>
}


export default AlertSnackbar;