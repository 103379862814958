import React, { Fragment, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useOutletContext } from "react-router-dom";


import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { UserContext } from "../../functions/userContext";
import { Account } from "../../models/Account";
import { hasAccountAccess } from "../../functions/userFunctions";

const AccountIndex = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const user = useContext(UserContext);
    const account = useOutletContext<Account | undefined>();

    return (
        <Fragment>
            <Typography sx={{m : 2}} variant="subtitle2">{intl.formatMessage({id: 'account.informations'}) }</Typography>
            <Paper>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user?.fullname} secondary={user?.email} />
                    </ListItem>
                    <Divider/>
                    {hasAccountAccess(user) && <Fragment>
                        <ListItem>
                            <ListItemText primary={intl.formatMessage({id: 'account.client'})} secondary={account?.name} />
                        </ListItem>
                        <ListItemButton  onClick={() => navigate("/account/company")}>
                            <ListItemText secondary={intl.formatMessage({id: 'account.enterprise'})} />
                            <ChevronRightIcon /> 
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate("/account/invoicing")}>
                            <ListItemText secondary={intl.formatMessage({id: 'account.invoicing'})} />
                            <ChevronRightIcon /> 
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate("/account/apikeys")}>
                            <ListItemText secondary={intl.formatMessage({id: 'account.apikeys'})} />
                            <ChevronRightIcon /> 
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate("/account/imports")}>
                            <ListItemText secondary={intl.formatMessage({id: 'account.imports'})} />
                            <ChevronRightIcon /> 
                        </ListItemButton>
                    </Fragment>}
                </List>
            </Paper>
        </Fragment>);
}


export default AccountIndex;