import { useIntl } from "react-intl";
import { Avatar, Box, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';

import { Chart, Bar } from 'react-chartjs-2';
import { ProfitStat } from "../../models/Stats";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarController,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

interface IProps {
    title: string;
    stats: ProfitStat[]
}

const ProfitChart = ({stats , title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();

    const labels = stats.map(s => intl.formatDate(s.date, {
        year: "numeric",
        month: "short"
    }));

    const data = {
        datasets: [{
            type: 'bar' as const,
            label: intl.formatMessage({id: 'dashboard.profitChart'}),
            data: stats.map(s => s.amount),
            backgroundColor: theme.palette.primary.main,
            tension: 0.1
        }],
        labels: labels
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                ticks: {
                    display: true,
                },
                grid: {
                    drawOnChartArea: false
                },
                beginAtZero: true
            }
        }
    }

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Bar data={data} height={90} options={options}/>
            </CardContent>
        </Card>
    );
}

export default ProfitChart;
