import React, { useEffect } from "react";
import { Chip, Box, TextField, TextFieldProps, Theme, styled } from "@mui/material";
import Downshift from "downshift";

// const Chip = styled(MUIChip)((theme: Theme) => ({
//     margin: theme.spacing(0.5)
//   }));

interface IProps {
    value: string[];
    onValueChanged: (values: string[]) => void;
    label?: string;
    textFieldProps?: TextFieldProps
}

export default function ChipInput({ label, value, onValueChanged, textFieldProps }: IProps) {

    const [inputValue, setInputValue] = React.useState<string>("");
    const [selectedItem, setSelectedItem] = React.useState<string[]>([]);

    useEffect(() => {
        setSelectedItem(value);
    }, [value]);

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleAddTag(event.target.value);
        }
        if (selectedItem.length && !inputValue.length && event.key === "Backspace") {
            const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1);
            setSelectedItem(newSelectedItem);
            onValueChanged(newSelectedItem);
        }
    }

    const handleAddTag = (tag: string) => {
        const newSelectedItem = [...selectedItem];
        const duplicatedValues = newSelectedItem.indexOf(
            tag.trim()
        );

        if (duplicatedValues !== -1) {
            setInputValue("");
            return;
        }
        if (!tag.replace(/\s/g, "").length) return;

        newSelectedItem.push(tag.trim());
        setSelectedItem(newSelectedItem);
        setInputValue("");

        onValueChanged(newSelectedItem);
    }

    const handleChange = (item: any) => {
        if (item) {
            let newSelectedItem = [...selectedItem];
            if (newSelectedItem.indexOf(item) === -1) {
                newSelectedItem = [...newSelectedItem, item];
            }
            setInputValue("");
            setSelectedItem(newSelectedItem);
        }
    }

    const handleDelete = (item: string) => {
        const newSelectedItem = [...selectedItem].filter(t => t !== item);
        setSelectedItem(newSelectedItem);
        onValueChanged(newSelectedItem);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    }
    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps }: TextFieldProps = getInputProps({
                        onKeyDown: handleKeyDown,
                        label
                    }) as TextFieldProps;
                    return (
                        <div>
                            <TextField
                                InputProps={{
                                    style: { display: 'flex', flexDirection: value.length > 0 ? 'column' : 'row', alignItems: 'flex-start' } ,
                                    startAdornment: value.length > 0 && <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: 1 }}>
                                        {
                                            selectedItem.map(item => (
                                                <Chip 
                                                    id={item}
                                                    sx={{margin: 0.5}}
                                                    key={item}
                                                    tabIndex={-1}
                                                    label={item}
                                                    onDelete={() => handleDelete(item)}
                                                />
                                            ))
                                        }
                                    </Box>,
                                    onBlur: () => handleAddTag(inputValue),
                                    onChange: event => {
                                        handleInputChange(event);
                                    },
                                    onFocus
                                }}
                                {...textFieldProps}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}
