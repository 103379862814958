import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { handleTextChange, handleSelectChange } from "../../functions/fieldChangeHandlers";
import { TransitionUp } from "../../components/Transition";
import { Role, User } from "../../models/User";
import UserService from "../../services/UserService";
import LoadingButton from "@mui/lab/LoadingButton";
import ApiKeyService from "../../services/ApiKeyService";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
}

const AddApiKeyDialog = ({ open, handleClose }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [saving, setSaving] = useState(false);
  
  const [name, setName] = useState('');
  const [keyValue, setKeyValue] = useState('');

  useEffect(() => {
      razFields();
    }
  , [open])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    setSaving(true);
    return ApiKeyService.addKey({
      name: name,
      id: "",
      userId: "",
      clientId: "",
      hashValue: ""
    }).then((data) => {
      if (data.hashValue) {
        setKeyValue(data.hashValue)
      }
      setSaving(false);
    });
  }

  const razFields = () => {
    setName('');
    setKeyValue('');
  }

  return (<Dialog 
    open={open}
    onClose={handleDismiss}
    fullScreen={fullScreen}
    TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: 'account.apikeys.add' })}</DialogTitle>
    <DialogContent>
      {keyValue === '' && (
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={intl.formatMessage({ id: 'account.apikeys.name' })}
          fullWidth
          variant="standard"
          value={name}
          autoComplete='off'
          onChange={handleTextChange(setName)}
        />) }
      {keyValue !== '' && 
        <Typography>{keyValue}</Typography>}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <LoadingButton disabled={keyValue !== ''} loading={saving} onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</LoadingButton>
    </DialogActions>
  </Dialog>);
}

export default AddApiKeyDialog;