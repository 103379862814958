import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { useStorageState } from "../../functions/customHooks";
import { Contract } from "../../models/Contract";
import ContractService from "../../services/ContractService";
import ContractDialog from "./ContractDialog";
import Fab from "../../components/Fab";

const ContractsPage = () => {
    const navigate = useNavigate();
    
    const [contracts, setContracts] = useStorageState<Contract[]>('contracts', []);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedContract, setEditedContract] = useState<Contract | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadContracts();
    }, []);

    const handleContractClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadContracts();
        }
    }

    const loadContracts = () => {
        setLoading(true);
        ContractService.get().then((data) => {
            setContracts(data);
            setLoading(false);
        });
    }

    const handleOpenDialog = (contract: Contract | undefined) => {
        setEditedContract(contract);
        setOpenDialog(true);
    }
    
    const handleOpenDetail = (contract: Contract) => {
        navigate("/contract/" + contract.id);
    }

    const ContractItem = (contract: Contract) => {
        return (<ListItem key={contract.id}>
            <ListItemButton onClick={() => handleOpenDetail(contract)}>
                <ListItemText primary={contract.name} secondary={contract.siret}/>
            </ListItemButton>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(contract)}>
                    <EditIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>);
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                {contracts.map(ContractItem)}
            </List>
            <Fab sx={{ position: 'fixed', right: 16, bottom: 16 }} disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <ContractDialog open={openDialog} handleClose={handleContractClose} contract={editedContract}/>
        </Fragment>);
}

export default ContractsPage;