import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
    Chart as ChartJS,
    Colors,
    DoughnutController,
    ArcElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Button, CardActions, CardContent, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import { PackageStat } from "../../models/Stats";

interface IProps {
    title: string;
    stats: PackageStat[];
    ariaLabel?: string;
    displayBy: "contract" | "package"
}

ChartJS.register(
    ArcElement,
    Colors,
    DoughnutController,
    Legend,
    Tooltip
);

const PackageStatsCard = ({ ariaLabel, title, stats, displayBy}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();

    const labels = displayBy === "contract" ? [...new Set(stats.map(s => s.contractName))] : [...new Set(stats.map(s => s.packageKind))]

    const values = labels.map(l => {
        const selectedValues = displayBy === "contract" ? stats.filter(s => s.contractName == l) : stats.filter(s => s.packageKind == l)
        return selectedValues.reduce((s, t) => t.deliveryCount + s, 0)
    })

    const data = {
        labels:labels,
        datasets: [{
          data: values,
          hoverOffset: 4,
          cutout: "90%"
        }]
      };

    const options = {
        plugins: {
            colors: {
              enabled: true,
              forceOverride: true
            }
        }
    }

    const total = stats.reduce((s, t) => t.deliveryCount + s, 0)

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                <Stack justifyContent="center" alignItems="center" justifyItems="center">
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <Doughnut data={data} options={options} />
                        <Box
                            sx={{
                            top: '55%',
                            left: '50%',
                            bottom: '60%',
                            right: '60%',
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                            }}
                        >
                            <Typography
                            variant="h5"
                            component="div"
                            color="text.secondary"
                            >{total}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default PackageStatsCard;
