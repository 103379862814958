import LoadingButton from "@mui/lab/LoadingButton";
import { useMediaQuery, useTheme, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { AsyncButton } from "../../components/AsyncButton";
import { TransitionUp } from "../../components/Transition";
import { handleTextChange, handleNumberChange, handleAutocompleteChange, handleDateChange } from "../../functions/fieldChangeHandlers";
import PeopleService from "../../services/PeopleService";

type IBonusProps = {
    open: boolean;
    handleClose: (result: boolean) => void
    peopleId: string | undefined;
}

const BonusDialog = ({ open, handleClose, peopleId }: IBonusProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [saving, setSaving] = useState(false);
    const [date, setDate] = useState<Date>();
    const [amount, setAmount] = useState<number>();

    useEffect(() => {
        razFields();
    }, [peopleId])

    const handleDismiss = () => {
        handleClose(false);
    }

    const handleValidate = () => {
        if (amount && date && peopleId) {
            setSaving(true)
            PeopleService.addBonus({
                id: '',
                clientId: '',
                date: date,
                peopleId: peopleId,
                amount: amount
            }).then(() => {
                setSaving(false)
                razFields();
                handleClose(true);
            });
        }
    }

    const razFields = () => {
        setAmount(undefined);
        setDate(new Date());
    }

    return (<Dialog
        open={open}
        onClose={handleDismiss}
        fullScreen={fullScreen}
        TransitionComponent={fullScreen ? TransitionUp : undefined}>
        <DialogTitle>
            {intl.formatMessage({ id: 'bonus.add' })}
        </DialogTitle>
        <DialogContent>
            <MobileDatePicker
                label={intl.formatMessage({ id: 'bonus.date' })}
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={handleDateChange(setDate)}
                renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
            />
            <TextField
                margin="dense"
                id="income"
                label={intl.formatMessage({ id: 'bonus.amount' })}
                type="number"
                fullWidth
                variant="standard"
                value={amount}
                onChange={handleNumberChange(setAmount)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
            <LoadingButton loading={saving} onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</LoadingButton>
        </DialogActions>
    </Dialog>);
}

export default BonusDialog;