import { Planning } from "../models/Planning";


export class PlanningService
{
    static async get() {
        const response = await fetch(`/api/planning`);
        return await response.json() as Planning[];
    }

    static async getForDate(date: Date) {
        const response = await fetch(`/api/planning?date=${date?.toISOString()}`);
        return await response.json() as Planning[];
    }

    static async addPlanning(data: Planning) {
        await fetch("/api/planning", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async editPlanning(data: Planning) {
        await fetch("/api/planning", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deletePlanning(planningId: string) {
        await fetch("/api/planning/" + planningId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }
}

export default PlanningService;