import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Grid, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";

import CACard from "./CACard";
import ContractCard from "./ContractCard";
import DeliveriesChart from "./DeliveriesChart";
import ProfitChart from "./ProfitChart";
import EmployeeCard from "./EmployeeCard";
import ExpenseCard from "./ExpenseCard";

import DashboardService from "../../services/DashboardService";
import { ActivityStats } from "../../models/Stats";
import ContractService from "../../services/ContractService";
import { Contract } from "../../models/Contract";
import PackageStatsCard from "./PackageStatsCard";
import { Period } from "../../models/Period";

const DashboardPage = () => {
    const defaultStat = { 
        monthCosts: 0, 
        caStat: {amount: 0}, 
        packageStats: [],
        contractCAStats: [],
        profitStats: [],
        expenseStats: [],
        peopleStats: [],
        deliveryStats: []
    };

    const [stats, setStats] = useState<ActivityStats>(defaultStat);
    const [period, setPeriod] = useState<Period>("month");
    const [selectedContract, setSelectedContract] = useState<string>("");
    const [contracts, setContracts] = useState<Contract[]>([]);
    const intl = useIntl();

    useEffect(() => {
        ContractService.get().then(setContracts)
    }, []);

    useEffect(() => {
        DashboardService.getDashboardStats(period, selectedContract).then(setStats);
    }, [selectedContract, period])
    
    return (
        <Fragment>
            <Stack sx={{mb: 2}} direction="row" justifyContent="space-between" spacing={3} flex={100}>
                <Select
                    sx={{minWidth: 250, alignItems:"end", justifyContent:"end"}}
                    label={intl.formatMessage({ id: 'dashboard.period' })}
                    onChange={(event: SelectChangeEvent) => setPeriod(event.target.value as Period)}
                    value={period}>
                        <MenuItem value="yesterday">{intl.formatMessage({ id: 'period.yesterday' })}</MenuItem>
                        <MenuItem value="week">{intl.formatMessage({ id: 'period.week' })}</MenuItem>
                        <MenuItem value="month">{intl.formatMessage({ id: 'period.month' })}</MenuItem>
                </Select>
                <Select
                    sx={{minWidth: 250, alignItems:"end", justifyContent:"end"}}
                    label={intl.formatMessage({ id: 'dashboard.contract' })}
                    onChange={(event: SelectChangeEvent) => setSelectedContract(event.target.value)}
                    value={selectedContract}>
                        <MenuItem value="">{intl.formatMessage({ id: 'all' })}</MenuItem>
                        {contracts.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                </Select>
            </Stack>
            <Grid container spacing={2}>
                <Grid item
                    xl={3}
                    lg={6}
                    sm={6}
                    xs={12}>
                    <CACard
                        title={intl.formatMessage({id: 'dashboard.ca'})} 
                        stats={stats.caStat}
                        objective={2000} 
                        ariaLabel="ca"/>
                </Grid>
                <Grid item
                    xl={3}
                    lg={6}
                    sm={6}
                    xs={12}>
                    <PackageStatsCard
                        title={intl.formatMessage({id: 'dashboard.packageStats'})} 
                        stats={stats.packageStats}
                        displayBy={selectedContract !== "" ? "package" : "contract"}
                        ariaLabel="packages-stats"/>
                </Grid>
                <Grid item
                    xl={3}
                    lg={3}
                    sm={6}
                    xs={12}>
                    <ContractCard
                        title={intl.formatMessage({id: 'dashboard.contractStats'})}
                        ariaLabel="contract-stats"
                        stats={stats.contractCAStats}
                        />
                </Grid>
                <Grid item
                    xl={3}
                    lg={3}
                    sm={6}
                    xs={12}>
                    <ProfitChart
                        stats={stats.profitStats}
                        title={intl.formatMessage({id: 'dashboard.profitChart'})}/>
                </Grid>
                <Grid item
                    xl={6}
                    lg={6}
                    sm={12}
                    xs={12}>
                    <DeliveriesChart 
                        stats={stats.deliveryStats}
                        title={intl.formatMessage({id: 'dashboard.deliveriesChart'})}/>
                </Grid>
                <Grid item
                    xl={3}
                    lg={6}
                    sm={6}
                    xs={12}>
                    <ExpenseCard
                        title={intl.formatMessage({id: 'dashboard.expenseCard'})} 
                        stats={stats.expenseStats}/>
                </Grid>
                <Grid item
                    xl={3}
                    lg={6}
                    sm={6}
                    xs={12}>
                    <EmployeeCard
                        title={intl.formatMessage({id: 'dashboard.employeeStats'})} 
                        stats={stats.peopleStats}/>
                </Grid>
            </Grid>
        </Fragment>
    );
}


export default DashboardPage;