import { replacer } from "../functions/mapReplacers";
import { Activity } from "../models/Activity";
import { Period } from "../models/Period";


export class ActivityService
{
    static async get(date: Date | undefined = undefined) {
        const dateParam = date !== undefined ? '?date=' + date?.toISOString() : '';
        const response = await fetch(`/api/activity` +  dateParam);
        return await response.json() as Activity[];
    }

    static async addActivity(data: Activity) {
        await fetch("/api/activity", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data, replacer)
        });
    }

    static async editActivity(data: Activity) {
        await fetch("/api/activity", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data, replacer)
        });
    }
    
    static async deleteActivity(activityId: string) {
        await fetch("/api/activity/" + activityId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }
}

export default ActivityService;