import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as _ from 'lodash';

import { CircularProgress, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, TextField, Typography } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Fab from "../../components/Fab";
import { useStorageState } from "../../functions/customHooks";
import PeopleService from "../../services/PeopleService";
import ContractService from "../../services/ContractService";
import VehicleService from "../../services/VehicleService";

import PlanningDialog from "./PlanningDialog";
import { People } from "../../models/People";
import { Contract } from "../../models/Contract";
import { Vehicle } from "../../models/Vehicle";
import { Planning } from "../../models/Planning";
import PlanningService from "../../services/PlanningService";
import { getDayLabel } from "../../functions/dateUtils";

const PlanningPage = () => {
    const intl = useIntl();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [plannings, setPlannings] = useStorageState<Planning[]>('plannings', []);
    const [people, setPeople] = useState<People[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedPlanning, setEditedPlanning] = useState<Planning | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [openedContracts, setOpenedContracts] = useState<string[]>([]);

    useEffect(() => {
        PeopleService.get().then(setPeople);
        ContractService.get().then(setContracts);
        VehicleService.get().then(setVehicles);
    }, []);
    
    useEffect(() => {
        loadPlannings();
    }, [selectedDate]);

    const handlePlanningClose = (result: boolean) => {
        setOpenDialog(false);
        if (result) {
            loadPlannings();
        }
    }

    const loadPlannings = () => {
        setLoading(true);
        PlanningService.get().then((data) => {
            setPlannings(data);
            setLoading(false);
        });
    }

    const deletePlanning = (planningId: string) => {
        PlanningService.deletePlanning(planningId).then(() => loadPlannings());
    }

    const handleOpenDialog = (planning: Planning | undefined) => {
        setEditedPlanning(planning);
        setOpenDialog(true);
    }
    
    const handleContractClick = (contractId: string) => {
        const idx = openedContracts.indexOf(contractId);

        if (idx >= 0) {
            openedContracts.splice(idx, 1);
            setOpenedContracts([...openedContracts]);
        } else {
            setOpenedContracts([...openedContracts, contractId]);
        }
    }

    const PlanningItem = (planning: Planning) => {
        return (<ListItem key={planning.id}>
            <ListItemButton onClick={() => handleOpenDialog(planning)}>
                <ListItemText primary={planning.days.map(d => getDayLabel(intl, d)).join(" ")} />
            </ListItemButton>
        </ListItem>);
    }

    const TourItem = (contractId: string, tourCode: string) => {
        const tourPlannings = plannings
            .filter(p => p.contractId === contractId && p.tourCode === tourCode)

        return (
            <Fragment>
                <ListSubheader>{tourCode}</ListSubheader>
                {tourPlannings.map(PlanningItem)}
                {tourPlannings.length === 0 && <Typography>
                    {intl.formatMessage({id: "planning.none"})}
                </Typography>}
            </Fragment>
        );
    }

    const ContractItem = (contract: Contract) => {
        return (
        <Fragment>
            <ListItem key={contract.id}>
                <ListItemButton onClick={() => handleContractClick(contract.id)} sx={{width: "100%"}}>
                    <ListItemText primary={contract.name} />
                    {openedContracts.includes(contract.id) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={openedContracts.includes(contract.id)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {contract.tours.map(t => TourItem(contract.id, t.code))}
                </List>
            </Collapse>
        </Fragment>);
    }

    return (
        <Fragment>
            <List
                sx={{ width: '100%'}}
                component="nav">
                {contracts.map(ContractItem)}
            </List>
            <Fab disabled={loading} color="primary" aria-label="add" onClick={() => handleOpenDialog(undefined)}>
                {loading ? <CircularProgress /> : <AddIcon /> }
            </Fab>
            <PlanningDialog 
                open={openDialog}
                handleClose={handlePlanningClose}
                planning={editedPlanning}
                availableContracts={contracts}
                availablePeople={people}
                availableVehicles={vehicles}
            />
        </Fragment>);
}


export default PlanningPage;