import { Contract, ContractStat, Tour } from "../models/Contract";


export class ContractService
{
    static async get() {
        const response = await fetch(`/api/contract`);
        return await response.json() as Contract[];
    }

    static async getById(id: string, date: Date) {
        const response = await fetch(`/api/contract/` + id + '?date=' + date.toISOString());
        return await response.json() as ContractStat;
    }

    static async addContract(data: Contract) {
        await fetch("/api/contract", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async updateContract(data: Contract) {
        await fetch("/api/contract", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async deleteContract(contractId: string) {
        await fetch("/api/contract/" + contractId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
        });
    }
}

export default ContractService;