import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, Stack,  TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {  InvoiceLine } from "../../models/Invoice";
import { ccyFormat } from "../../functions/formaters";
import InvoiceService from "../../services/InvoiceService";
import { TransitionUp } from "../../components/Transition";
import LoadingButton from "@mui/lab/LoadingButton";
import { handleDateChange, handleTextChange } from "../../functions/fieldChangeHandlers";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { PeopleStats } from "../../models/People";
import { deliveriesPerIncomeKind } from "../../functions/compute";
import PeopleService from "../../services/PeopleService";
import { Contract } from "../../models/Contract";
import { toLocalDate } from "../../functions/dateUtils";

interface IProps {
    open: boolean;
    handleClose: (result: boolean, invoiceId?: string) => void;
    initialDate: Date;
    peopleId: string;
    contracts: Contract[]
}

const PeopleInvoiceDialog = ({open, handleClose, initialDate, peopleId, contracts} : IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //const [from, setFrom] = useState('');
    const defaultFooter = "Pas d'escompte pour règlement anticipé. En cas de retard de paiement, une pénalité égale à 3 fois le taux intérêt légal Sera exigible\n"
        + "(Article L 441-6 Alinéa 12 du Code de Commerce).Pour tout professionnel, en sus des indemnités de retard, toute somme , y compris\n"
        + "l'acompte, non payé à sa date d'exigibilité produira de plein droit le paiement d'une indemnité forfaitaire de 40 euros due Au titre des frais\n"
        + "de recouvrement (Art. 441-6, I al. 12 du code de commerce et D. 441-5 ibidem).";

    const [invoiceDate, setInvoiceDate] = useState<Date>(initialDate);
    const [people, setPeople] = useState<PeopleStats>();
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [dueDate, setDueDate] = useState<Date | undefined>();
    const [to, setTo] = useState('');
    const [footer, setFooter] = useState(defaultFooter);
    const [paymentInfo, setPaymentInfo] = useState('');
    const [lines, setLines] = useState<InvoiceLine[]>([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    
    const defaultStartDate = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 1);
    const defaultEndDate = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth()+1, 0);
    const defaultDueDate = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth()+2, 0);

    useEffect(() => {
        if (invoiceDate) {
            setStartDate(defaultStartDate);
            setEndDate(defaultEndDate);
            setDueDate(defaultDueDate);
            setFooter(defaultFooter);
            setLoading(true)
            PeopleService.getById(peopleId, invoiceDate).then(ps => {
                setLoading(false)
                setPeople(ps)
            });
        }
    }, [invoiceDate]);

    useEffect(() => {
        if (people) {            
            setTo(people?.name)
            setLines(invoiceBaseLines())
        }
    }, [people])
    
    const handleDismiss = () => {
        handleClose(false);
    }
    
    const handleValidate = () => {
        setSaving(true);
        const data = {
            startDate: toLocalDate(startDate ?? defaultStartDate),
            endDate: toLocalDate(endDate ?? defaultEndDate),
            dueDate: toLocalDate(dueDate ?? defaultDueDate),
            to: to,
            lines: lines,
            paymentInfo: paymentInfo,
            vatRatio: 0.2,
            footer: footer,
        };

        InvoiceService.addInvoice(data, invoiceDate, undefined, peopleId).then((invoiceId) => {
            setSaving(false);
            handleClose(true, invoiceId);
        });
    }

    const handleLineNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, line: InvoiceLine) => {     
        line.name = event.target.value;
        setLines([...lines]);
    }

    const handleLineQuantityChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, line: InvoiceLine) => {        
        line.quantity = event.target.value != '' ? (event.target.value as unknown) as number: undefined;

        if(line.quantity && line.unitPrice) {
            line.value = line.quantity * line.unitPrice;
        }

        setLines([...lines]);
    }

    const handleLineUnitPriceChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, line: InvoiceLine) => {        
        line.unitPrice = event.target.value != '' ? (event.target.value as unknown) as number: undefined;

        if(line.quantity && line.unitPrice) {
            line.value = line.quantity * line.unitPrice;
        }

        setLines([...lines]);
    }

    // const handleLineValueChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, line: InvoiceLine) => {        
    //     line.value = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
    //     setLines([...lines]);
    // }

    const handleDeleteLine = (line: InvoiceLine) => {        
        const lIdx = lines.indexOf(line);
        if(lIdx >= 0) {
            lines.splice(lIdx, 1);
            setLines([...lines]);
        }
    }

    const handleAddLine = () => {
        lines.push({ name: "" });
        setLines([...lines]);
    }

    const handleSelectedDateChange = (date: Date | null) => {
        if (date !== null) {
            setInvoiceDate(date)
        }
    }

    const handleChangeDate = (delta: number) => {
        const newDate = new Date(invoiceDate.setMonth(invoiceDate.getMonth() + delta));
        setInvoiceDate(newDate);
    }    
    
    const invoiceBaseLines = () => {
        if (people === undefined) {
            return []
        }

        const deliveryIncomeValues = deliveriesPerIncomeKind(people, contracts)

        const allLines = []

        Object.keys(people.deliveryIncomes ?? {}).forEach(key => {
            if (people.deliveryIncomes && key in deliveryIncomeValues && deliveryIncomeValues[key] > 0) {
                allLines.push({name: intl.formatMessage({ id: 'people.deliveryIncome'}) + ' - ' + key, quantity: Math.round(deliveryIncomeValues[key]), unitPrice: Math.round(100 * people.deliveryIncomes[key]) / 100, value: Math.round(100 * deliveryIncomeValues[key] * people.deliveryIncomes[key]) / 100})
            }
        }); 
        
        if (people.bonuses.length > 0) {
            const bonus = Math.round(100 * people.bonuses.reduce((s, b) => b.amount + s, 0)) / 100;
            allLines.push({name: intl.formatMessage({ id: "people.detail.bonuses"}), quantity: 1, unitPrice: bonus, value: bonus})
        }
        
        return allLines
    }

    
    const total = lines.reduce((s, l) => s + (l.value ?? 0), 0);

    const InvoiceLine = (line: InvoiceLine) => {
        return (
            <Stack direction="row" sx={{width: '100%'}}>
                    <TextField
                        sx={{display: 'flex', width: '60%', mr: 1, mt: 1}}
                        margin="dense"
                        label={intl.formatMessage({id: 'contract.invoice.line.name'})}
                        variant="standard"
                        value={line.name}
                        onChange={(evt) => handleLineNameChange(evt, line)}
                        />
                    <TextField
                        sx={{width: '10%', mr: 1, mt: 1}}
                        margin="dense"
                        label={intl.formatMessage({id: 'contract.invoice.line.quantity'})}
                        variant="standard"
                        type="number"
                        value={line.quantity}
                        onChange={(evt) => handleLineQuantityChange(evt, line)}
                        />
                    <TextField
                        sx={{width: '10%', mr: 1, mt: 1}}
                        margin="dense"
                        label={intl.formatMessage({id: 'contract.invoice.line.unitPrice'})}
                        variant="standard"
                        type="number"
                        value={line.unitPrice}
                        onChange={(evt) => handleLineUnitPriceChange(evt, line)}
                        />
                    <Typography margin="dense" sx={{width: '10%', m: 1, mt: 3, mr: 3, textAlign: "right"}}>{ccyFormat(line.value)}</Typography>
                    <IconButton onClick={() => handleDeleteLine(line)}><DeleteIcon/></IconButton>
            </Stack>
        );
    }

    return (<Dialog
        open={open}
        onClose={handleDismiss}
        fullScreen={true}
        TransitionComponent={TransitionUp}>
            <DialogTitle>
                {intl.formatMessage({ id: 'contract.invoice' })}
            </DialogTitle>
            
        <DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} flex={100}>
                <IconButton aria-label="previous" onClick={() => handleChangeDate(-1)}>
                    <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <MobileDatePicker
                    views={["month", "year"]}
                    label={intl.formatMessage({ id: 'reports.date' })}
                    inputFormat="MMMM yyyy"
                    value={invoiceDate}
                    onChange={handleSelectedDateChange}
                    renderInput={(params: any) => <TextField {...params} />}
                />
                <IconButton aria-label="next" onClick={() => handleChangeDate(1)}>
                    <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Divider sx={{ m: 2}}/>

            <TextField
                id="invoice-to"
                label={intl.formatMessage({id: "contract.invoice.to"})}
                sx={{mb: 2}}
                multiline
                fullWidth
                rows={4}
                value={to}
                onChange={handleTextChange(setTo)}
                variant="standard"
            />
            <MobileDatePicker
                label={intl.formatMessage({ id: 'contract.invoice.startDate' })}
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={handleDateChange(setStartDate)}
                renderInput={(params: any) => <TextField fullWidth variant="standard" {...params} />}
            />
            <MobileDatePicker
                label={intl.formatMessage({ id: 'contract.invoice.endDate' })}
                inputFormat="dd/MM/yyyy"
                value={endDate}
                onChange={handleDateChange(setEndDate)}
                renderInput={(params: any) => <TextField fullWidth variant="standard" {...params} />}
            />
            <MobileDatePicker
                label={intl.formatMessage({ id: 'contract.invoice.dueDate' })}
                inputFormat="dd/MM/yyyy"
                value={dueDate}
                onChange={handleDateChange(setDueDate)}
                renderInput={(params: any) => <TextField fullWidth variant="standard" {...params} />}
            />
            <Divider sx={{m: 3}} variant="middle" />
            {lines.map(InvoiceLine)}
            <Divider sx={{mt: 3}} variant="middle" textAlign="right">
                <IconButton aria-label="add" size="small" onClick={() => handleAddLine()}>
                    <AddCircleIcon/>
                </IconButton>
            </Divider>
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <TextField
                        id="invoice-paymentInfo"
                        sx={{mb: 2}}
                        label={intl.formatMessage({id: "contract.invoice.paymentInfo"})}
                        multiline
                        fullWidth
                        rows={4}
                        value={paymentInfo}
                        onChange={handleTextChange(setPaymentInfo)}
                        variant="standard"
                    />
                </Grid>
                <Grid item sm={5} xs={12} sx={{m: 1}}>
                    <Typography textAlign="end" variant="body1">{intl.formatMessage({id: "htTotal"})} : {ccyFormat(total)}</Typography>
                    <Typography textAlign="end" variant="body1">{intl.formatMessage({id: "vat"})} : 20%</Typography>
                    <Typography textAlign="end" variant="h5"><b>{intl.formatMessage({id: "ttcTotal"})} : {ccyFormat(total * 1.2)}</b></Typography>
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        id="invoice-footer"
                        sx={{mb: 2}}
                        label={intl.formatMessage({id: "contract.invoice.footer"})}
                        multiline
                        fullWidth
                        rows={4}
                        value={footer}
                        onChange={handleTextChange(setFooter)}
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
            <LoadingButton loading={saving || loading} onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</LoadingButton>
        </DialogActions>
    </Dialog>);
}

export default PeopleInvoiceDialog;