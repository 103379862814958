import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, Slide, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import UserService from '../../services/UserService';
import { handleTextChange } from '../../functions/fieldChangeHandlers';

import PasswordInput from '../../components/PasswordInput'

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const LoginPage = () => {
    const intl = useIntl();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const handleSubmit = () => {
        UserService.login({
            email: email,
            password: password,
            rememberMe: rememberMe,
        }).then(response => {
            if(response.ok) {
                setShowError(false);
                window.location.replace('/');
            } else /* if(response.status == 401) */ {
                setShowError(true);
            };
        });
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    m: 1,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {intl.formatMessage({id: 'signin'})}
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label={intl.formatMessage({id: "email"})}
                        autoComplete="email"
                        autoFocus
                        value={email}
                        error={showError}
                        onChange={handleTextChange(setEmail)}
                    />
                    <PasswordInput
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        name="password"
                        label={intl.formatMessage({id: "password"})}
                        autoComplete="current-password"
                        value={password}
                        helperText={showError ? intl.formatMessage({id: 'login.failed'}) : ''}
                        onChange={handleTextChange(setPassword)}
                        error={showError}
                    />
                    <FormControlLabel
                        control={<Checkbox 
                            value="remember" 
                            color="primary" 
                            checked={rememberMe}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked)}
                        />}
                        id="rememberme"
                        name="rememberme"
                        label={intl.formatMessage({id: 'rememberme'})}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {intl.formatMessage({id: 'signin'})}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/forgot-password">
                                {intl.formatMessage({id: 'forgotpassword'})}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Slide>
    );
}

export default LoginPage;