import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Slide, Stack, TextareaAutosize, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';

import { handleTextChange } from "../../functions/fieldChangeHandlers";
import { SupportService } from "../../services/SupportService";

const SupportPage = () => {
    const intl = useIntl();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    const handleSend = () => {
        setSending(true);
        SupportService.send(title, message).then(() => {
            setSending(false);
            setSent(true);
        });
    }

    return <Fragment>
        <Stack spacing={3} sx={{width: fullScreen ? "100%" : "50%"}}>
            <Typography>{intl.formatMessage({id: 'support.header'})}</Typography>
            <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="title"
                label={intl.formatMessage({id: 'support.title'})}
                variant="standard"
                value={title}
                onChange={handleTextChange(setTitle)}
            />
            
            <TextareaAutosize
                id="message"
                aria-label="message"
                placeholder={intl.formatMessage({id: 'support.message'})}
                minRows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            
            <LoadingButton
                id="send"
                disabled={sent}
                sx={{
                    width: 200
                }}
                loading={sending}
                variant="contained"
                endIcon={ sent ? <DoneIcon/> : <SendIcon />}
                onClick={handleSend}>
                {sent ? intl.formatMessage({id: 'sent'}) : intl.formatMessage({id: 'send'})}
            </LoadingButton>
        </Stack>
    </Fragment>
}

export default SupportPage;