import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MailIcon from '@mui/icons-material/Mail';

import { Account } from "../../models/Account";
import { Product } from "../../models/Product";
import { useStorageState } from "../../functions/customHooks";
import { Fragment, useEffect, useState } from "react";
import ProductService from "../../services/ProductService";
import PaymentService from "../../services/PaymentService";
import { max } from "lodash";
import { ccyFormat } from "../../functions/formaters";
import ContractCard from "../Dashboard/ContractCard";
import SupportService from "../../services/SupportService";

const AccountInvoicingDetail = () => {
    const intl = useIntl();
    const theme = useTheme();
    const [account, handleAccountUpdate] = useOutletContext<[Account | undefined, () => void]>();

    const [products, setProducts] = useStorageState<Product[]>("products", []);
    const [usage, setUsage] = useState<number>(0);

    const [contactSent, setContactSent] = useState(false)

    useEffect(() => {
        ProductService.get().then(setProducts);
        PaymentService.getUsage().then(setUsage);
    }, [])

    const ProductItem = (product: Product) => {
        return <Grid item md={4}>
            <form action={`/api/payment/subscribe?product=${product.code}`} method="POST">
                <Card sx={{ width: 345, borderRadius: 2 }} variant="outlined">
                    <CardHeader
                        sx={{ 
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.primary.main,
                            textAlign: "center"
                        }}
                        title={product.displayName}
                        subheader={
                            <Fragment>
                                <Divider sx={{mt:1, mb: 2}}/>
                                <Stack direction="row" >
                                    <Typography variant="h3" color="text.primary"><b>{product.price}</b></Typography>
                                    <Typography sx={{alignSelf: "end"}} variant="body2" color="text.secondary">{product.priceModel}</Typography>
                                </Stack>
                            </Fragment>
                        }/>
                    <Divider sx={{m: 2}}/>
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">{product.description}</Typography>
                        <ul>
                        {product.characteristics.map(c => <Typography variant="body2" color="text.secondary"><li>{c}</li></Typography>)}
                        </ul>
                    </CardContent>
                    <CardActions>
                            <Button 
                                disabled={product.code === account?.subscriptionProduct && account?.subscriptionStatus !== "Pending"}
                                variant={product.code === "standard" ? "outlined": "contained"}
                                type='submit'
                                endIcon={<ArrowForwardIcon/>}
                                fullWidth>
                                {intl.formatMessage({id: 'select'})}
                            </Button>
                    </CardActions>
                </Card>
            </form>
        </Grid>
    }

    const PaymentMethods = () => {
        return <form action="/api/payment/methods" method="POST">
            <Button 
                disabled={account?.subscriptionId === null || account?.subscriptionId === undefined}
                startIcon={<ExitToAppIcon />}
                variant="outlined"
                type='submit'>
                {intl.formatMessage({id: 'account.invoicing.paymentMethodsLink'})}
            </Button>
        </form>
    }

    const ContactCard = () => {
        return (
        <Grid item md={4}>
            <Card sx={{ width: 345, borderRadius: 2 }}>
                <CardHeader
                    sx={{ 
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.primary.main,
                        textAlign: "center"
                    }}
                    title={"Sur mesure"}
                    subheader={
                        <Fragment>
                            <Divider sx={{mt:1, mb: 2}}/>
                            <Stack direction="row">
                                <Typography variant="h5" color="text.primary"><b>Tarification adaptée à vos besoins</b></Typography>
                            </Stack>
                        </Fragment>
                    }/>
                <Divider sx={{m: 2}}/>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">Besoin d'une offre à l'échelle de votre business ?</Typography>
                    <Typography variant="body2" color="text.secondary">Dialoguez avec l'équipe commerciale</Typography>
                </CardContent>
                <CardActions>
                        <Button 
                            disabled={contactSent}
                            sx={{display: "flex-end", bottom: 2}}
                            onClick={() => {
                                SupportService.send("Demande de contact commercial", `Demande de contact commercial ${account?.email}`)
                                setContactSent(true)
                            }}
                            variant="contained"
                            endIcon={<MailIcon/>}
                            fullWidth>
                            Être contacté
                        </Button>
                </CardActions>
            </Card>
        </Grid>)
    }

    const currentProductName = products.find(p => p.code === account?.subscriptionProduct )?.displayName ?? "Trial";

    const usageCost = account?.subscriptionProduct === "privilege" ? Math.max(usage - 50, 0) * 9.89 :
        account?.subscriptionProduct === "pro" ? Math.max(usage - 20, 0) * 9.89 :
        (account?.subscriptionProduct === "gold" ? Math.max(usage - 100, 0) * 7.89 : Math.max(usage - 1, 0) * 19.89)

    return <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box>
            <Stack  sx={{mt: 2, mb: 1}}  direction="row">
                <Typography variant="subtitle1">{intl.formatMessage({id: 'account.invoicing.currentLevel'})} : </Typography>
                <Typography variant="subtitle1" sx={{ml: 1}} >{currentProductName}</Typography>
            </Stack>
            <Typography sx={{mt: 2, mb: 1}} variant="subtitle1">{intl.formatMessage({id: 'account.invoicing.usage'})} :</Typography>
            <Card sx={{ width: 150, textAlign: "center" }}>
                <CardContent>
                    <Stack>
                        <Typography variant="h3">{usage}</Typography>
                        <Typography variant="caption">{ccyFormat(usageCost)}</Typography>
                    </Stack>
                </CardContent>
            </Card>
            <Divider sx={{mt: 4, mb: 4}}/>
            <Typography sx={{mb: 1}} variant="subtitle1">{intl.formatMessage({id: 'account.invoicing.level'})}</Typography>
            {account && <Grid container spacing={2}>
                {products.map(ProductItem)}
                {ContactCard()}
            </Grid>}
            <Divider sx={{mt: 4, mb: 4}}/>
            <Typography sx={{mb: 1}} variant="subtitle1">{intl.formatMessage({id: 'account.invoicing.paymentmethods'})}</Typography>
            <Typography>{PaymentMethods()}</Typography>
        </Box>
    </Slide>
}

export default AccountInvoicingDetail;