import { Button, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { handleDateChange } from "../../functions/fieldChangeHandlers";
import { useIntl } from "react-intl";
import { getStartOfMonth } from "../../functions/dateUtils";
import { addDays } from "date-fns";

const ActivityExportMenu = ({ anchorEl, handleClose, handleCsvExport }: { anchorEl: Element | ((element: Element) => Element) | null | undefined, handleClose: VoidFunction, handleCsvExport: (startDate?: Date, endDate?: Date) => void }) => {

    const intl = useIntl();
    const open = Boolean(anchorEl);

    const currentDate = new Date();
    const startOfMonth = getStartOfMonth(currentDate);

    const [startDate, setStartDate] = useState<Date | undefined>(startOfMonth);
    const [endDate, setEndDate] = useState<Date | undefined>(currentDate);
    

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'export-button',
            }}
        >
            <MenuItem onClick={() => handleCsvExport(addDays(currentDate, -7), currentDate)}>{intl.formatMessage({ id: "activity.export.week" })}</MenuItem>
            <MenuItem onClick={() => handleCsvExport(startOfMonth, currentDate)}>{intl.formatMessage({ id: "activity.export.month" })}</MenuItem>
            <MenuItem onClick={() => handleCsvExport(getStartOfMonth(addDays(startOfMonth, -1)), addDays(startOfMonth, -1))}>{intl.formatMessage({ id: "activity.export.lastMonth" })}</MenuItem>
            <MenuItem component="div">
                <Stack direction="column" spacing={2}>
                    <Typography>{intl.formatMessage({ id: "activity.export.custom" })}</Typography>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        <DatePicker
                            label={intl.formatMessage({ id: "activity.export.startDate" })}
                            inputFormat="dd/MM/yyyy"
                            value={startDate}
                            onChange={handleDateChange(setStartDate)}
                            renderInput={(params) => <TextField {...params} />} />
                        <DatePicker
                            label={intl.formatMessage({ id: "activity.export.endDate" })}
                            inputFormat="dd/MM/yyyy"
                            value={endDate}
                            onChange={handleDateChange(setEndDate)}
                            renderInput={(params) => <TextField {...params} />} />
                        <Button disabled={startDate === undefined || endDate === undefined} onClick={() => handleCsvExport(startDate, endDate)}>{intl.formatMessage({ id: "ok" })}</Button>
                    </Stack>
                </Stack>
            </MenuItem>
        </Menu>
    )
}

export default ActivityExportMenu;