export class PaymentService
{
    static subscribe() {
        fetch(`/api/payment/subscribe`, { method: "PUT"})
        .then(response => {
            if (response.redirected) {
                window.location.replace(response.url);
            }
        });
    }
    
    static async getUsage() {
        const response = await fetch(`/api/payment/usage`);
        return await response.json() as number;
    }
}

export default PaymentService;