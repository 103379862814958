import { Report } from "../models/Report";


export class ReportService
{
    static async get(date: Date | undefined = undefined) {
        const dateParam = date !== undefined ? '?date=' + date?.toISOString() : '';
        const response = await fetch(`/api/report` +  dateParam);
        return await response.json() as Report;
    }
}

export default ReportService;