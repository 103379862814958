import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';

import { i18nConfig } from '../config/i18n';
import { LanguageService } from '../services/LanguageService';

interface IIntlWrapperProps {
    children: JSX.Element;
}

const localeMap = {
    en: enLocale,
    fr: frLocale
};

class ConcreteIntlWrapper extends React.Component<IIntlWrapperProps, any> {
    public render() {
        const locale = LanguageService.get();
        return (
            <IntlProvider key={locale} locale={locale} messages={i18nConfig[locale]}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale as keyof typeof localeMap]}>
                    {this.props.children}
                </LocalizationProvider>
            </IntlProvider>
        );
    }
}

export const IntlWrapper = (ConcreteIntlWrapper);
