import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import { Configuration } from './Configuration';
import ProtectedRoute from '../components/ProtectedRoute';
import HomePage from './Dashboard/DashboardPage';
import LoginPage from './Login/LoginPage';
import RegisterPage from './Register/RegisterPage';
import ContractsPage from './Contracts/ContractsPage';
import PeoplePage from './People/PeoplePage';
import FleetPage from './Fleet/FleetPage';
import ActivityPage from './Activity/ActivityPage';
import ReportsPage from './Reports/ReportsPage';
import ContractDetailPage from './Contracts/ContractDetailPage';
import AccountPage from './Account/AccountPage';
import ExpensePage from './Expense/ExpensePage';
import { ErrorBoundary } from './ErrorBoundary';
import AppLayout from './Layout/AppLayout';
import ForgotPasswordPage from './Login/ForgotPasswordPage';
import ResetPasswordPage from './Login/ResetPasswordPage';
import SupportPage from './Support/SupportPage';
import AccountIndex from './Account/AccountIndex';
import AccountCompanyDetail from './Account/AccountCompanyDetail';
import AccountInvoicingDetail from './Account/AccountInvoicingDetail';
import PeopleDetailPage from './People/PeopleDetailPage';
import UsersPage from './Users/UsersPage';
import PlanningPage from './Planning/PlanningPage';
import AccountApiKeysDetail from './Account/AccountApiKeysDetail';
import AccountImportsDetail from './Account/AccountImportsDetail';


const theme = createTheme({
    palette: {
        primary: {
            contrastText: '#fff',
            dark: '#04062e',
            light: '#565ef0',
            main: '#0a0f71'
        },
        secondary: {
            contrastText: '#000',
            dark: '#7a4500',
            light: '#ffca85',
            main: '#ff9100'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    zIndex: {
        appBar: 1201
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1400,
            xl: 1600,
        },
    }
});

type IAppRouteProps = {
    children: JSX.Element;
    title?: string;
} 

const Page = ({children, title} : IAppRouteProps) => {
    return <AppLayout title={title}>{children}</AppLayout>;
}

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Configuration>
                        <ErrorBoundary>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<ProtectedRoute />} >
                                        <Route path="/" element={<ProtectedRoute roles={["Administrator", "Manager"]}/>}>
                                            <Route path="/" element={<Page title={"home"}><HomePage /></Page>} />
                                            <Route path="/contracts" element={<Page title={"contracts"}><ContractsPage /></Page>} />
                                            <Route path="/contract/:id" element={<Page title={"contract"}><ContractDetailPage /></Page>} />
                                            <Route path="/users" element={<Page title={"users"}><UsersPage /></Page>} />
                                            <Route path="/reports" element={<Page title={"reports"}><ReportsPage /></Page>} />
                                            <Route path="/people/:id" element={<Page title={"peopleDetail"}><PeopleDetailPage /></Page>} />
                                        </Route>
                                        <Route path="/" element={<ProtectedRoute roles={["Administrator", "Manager", "Contributor"]}/>}>
                                            <Route path="/people" element={<Page title={"people"}><PeoplePage /></Page>} />
                                            <Route path="/fleet" element={<Page title={"fleet"}><FleetPage /></Page>} />
                                            <Route path="/expenses" element={<Page title={"expenses"}><ExpensePage /></Page>} />
                                        </Route>
                                        <Route path="/" element={<ProtectedRoute subscriptions={["pro", "privilege", "gold"]} roles={["Administrator", "Manager", "Contributor"]}/>}>
                                            <Route path="/planning" element={<Page title={"planning"}><PlanningPage /></Page>} />
                                        </Route>
                                        <Route path="/activity" element={<Page title={"activity"}><ActivityPage /></Page>} />
                                        <Route path="/support" element={<Page title={"support"}><SupportPage /></Page>} />
                                        <Route path="/account" element={<ProtectedRoute roles={["Administrator"]}/>}>
                                            <Route path="/account" element={<Page title={"account"}><AccountPage /></Page>}>
                                                <Route index element={<AccountIndex />} />
                                                <Route path="company" element={<AccountCompanyDetail />} />
                                                <Route path="invoicing" element={<AccountInvoicingDetail />} />
                                                <Route path="apikeys" element={<AccountApiKeysDetail />} />
                                                <Route path="imports" element={<AccountImportsDetail />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path="/login" element={<LoginPage />} />
                                    <Route path="/register" element={<RegisterPage />} />
                                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                    <Route path="/password-reset" element={<ResetPasswordPage />} />
                                    {/* <Route path="*" element={<PageNotFound />} /> */}
                                </Routes>
                            </BrowserRouter>
                        </ErrorBoundary>
                    </Configuration>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}
