import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import { Box, Divider, Fab, Slide, Stack, TextField, Typography } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { Account } from "../../models/Account";
import { handleTextChange } from "../../functions/fieldChangeHandlers";
import AccountService from "../../services/AccountService";
import LoadingButton from "@mui/lab/LoadingButton";

const AccountCompanyDetail = () => {
    const intl = useIntl();
    const [account, handleAccountUpdate] = useOutletContext<[Account | undefined, () => void]>();
    
    const [name, setName] = useState('');
    const [siret, setSiret] = useState('');
    const [vatCode, setVatCode] = useState('');
    const [invoiceHeader, setInvoiceHeader] = useState('');
    const [paymentInfo, setPaymentInfo] = useState('');

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(account) {
            setName(account.name);
            setSiret(account.siret);
            setVatCode(account.vatCode ?? '');
            setInvoiceHeader(account.invoiceHeader ?? '');
            setPaymentInfo(account.paymentInfo ?? '');
        }
    }, [account]);

    const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement> ) => {
        if(event?.target?.files && event.target.files.length > 0)
        {
            var file = event.target.files[0];
            const reader = new FileReader();
            var url = reader.readAsDataURL(file);
        
            reader.onloadend = (e) => {
                if(typeof reader.result === "string") {
                    setInvoiceHeader(reader.result);
                }
            } 
        }
    };

    const onSave = () => {
        if(account) {
            setSaving(true);
            AccountService.updateAccount({
                id: account.id,
                email: account.email,
                name: name,
                siret: siret,
                vatCode: vatCode,
                invoiceHeader: invoiceHeader,
                paymentInfo: paymentInfo
            }).then(() => {
                setSaving(false);
                handleAccountUpdate();
            });
        }
    }

    return <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box>
            <Typography sx={{ mb: 2}} variant="h6">{intl.formatMessage({id: 'account.enterprise.informations'})}</Typography>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={intl.formatMessage({id: 'account.enterprise.name'})}
                fullWidth
                variant="standard"
                value={name}
                onChange={handleTextChange(setName)}
            />
            <TextField
                margin="dense"
                id="siret"
                label={intl.formatMessage({id: 'account.enterprise.siret'})}
                type="siret"
                fullWidth
                variant="standard"
                value={siret}
                onChange={handleTextChange(setSiret)}
            />
            <TextField
                margin="dense"
                id="vatcode"
                label={intl.formatMessage({id: 'account.enterprise.vatcode'})}
                type="vatcode"
                fullWidth
                variant="standard"
                value={vatCode}
                onChange={handleTextChange(setVatCode)}
            />
            <Divider/>
            <Typography sx={{mt: 2, mb: 2}} variant="h6">{intl.formatMessage({id: 'account.enterprise.invoicesettings'})}</Typography>
            <TextField
                id="invoice-to"
                label={intl.formatMessage({id: "account.enterprise.paymentinfo"})}
                sx={{mb: 2}}
                multiline
                fullWidth
                rows={4}
                value={paymentInfo}
                onChange={handleTextChange(setPaymentInfo)}
                variant="standard"
            />
            <Stack direction="row">
                { invoiceHeader != "" ? <img src={invoiceHeader} alt={intl.formatMessage({id: "account.enterprise.noheader"})} height="200px" width="auto"/> : <Typography>{intl.formatMessage({id: "account.enterprise.noheader"})}</Typography>}
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                />
                <label htmlFor="contained-button-file">
                    <Fab component="span" sx={{ml: 2}}>
                        <AddPhotoAlternateIcon />
                    </Fab>
                </label>
            </Stack>
            <LoadingButton sx={{mt: 2}} loading={saving} id="save" variant="contained" onClick={onSave}>{intl.formatMessage({id: "save"})}</LoadingButton>
        </Box>
    </Slide>
}

export default AccountCompanyDetail;