import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";

import { Box, Checkbox, FormControl, FormControlLabel, Slide, Stack, Switch, TextField, Typography } from "@mui/material";

import { Account, Import } from "../../models/Account";
import { handleTextChange } from "../../functions/fieldChangeHandlers";
import AccountService from "../../services/AccountService";
import LoadingButton from "@mui/lab/LoadingButton";


interface ImportLineProps {
    title: string;
    importLine: Import;
    onUpdate: (data: Import) => void
}

const AcoountImportLine = ({ importLine, title, onUpdate} : ImportLineProps) => {

    const intl = useIntl();

    const handleSwitchActive = () => {
        onUpdate({
            ...importLine,
            active: !importLine.active
        })
    }

    const handleAccountNameChange = (name: string) => {
        onUpdate({
            ...importLine,
            accountName: name
        })
    }
    
    const handleSwitchAutoCreateMembers = () => {
        onUpdate({
            ...importLine,
            autoCreateMembers: !importLine.autoCreateMembers
        })
    }


    return <Fragment>
        <Typography>{title}</Typography>
        <Stack direction="row" spacing={2}>
            <FormControl sx={{ justifyContent: "end"}}>
                <FormControlLabel control={<Switch checked={importLine.active} onClick={() => handleSwitchActive()} />} label={intl.formatMessage({id: 'account.imports.active'})} />
            </FormControl>

            <TextField
                margin="dense"
                id="accountName"
                disabled={!importLine.active}
                sx={{ justifyContent: "center"}}
                label={intl.formatMessage({ id: 'account.imports.accountName' })}
                variant="standard"
                value={importLine.accountName ?? ""}
                onChange={(evt) => handleAccountNameChange(evt.target.value)}
            />

            <FormControl  sx={{ justifyContent: "end"}}>
                <FormControlLabel control={<Checkbox disabled={!importLine.active} checked={importLine.autoCreateMembers} onClick={() => handleSwitchAutoCreateMembers()} />} label={intl.formatMessage({id: 'account.imports.autoCreateMembers'})} />
            </FormControl>
        </Stack>
    </Fragment>
}

const AccountImportsDetail = () => {
    const intl = useIntl();
    const [account, handleAccountUpdate] = useOutletContext<[Account | undefined, () => void]>();

    const [imports, setImports] = useState<Import[]>(account?.imports ?? [])
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setImports(account?.imports ?? [])
    }, [account])
 
    const onSave = () => {
        if(account && imports) {
            setSaving(true);
            AccountService.updateAccountImports(imports).then(() => {
                setSaving(false);
                handleAccountUpdate();
            });
        }
    }

    const handleUpdateImport = (importLine: Import) => {
        const otherImports = account?.imports?.filter(i => i.id !== importLine.id) ?? []
        setImports([...otherImports, importLine])
    }

    const paackLine = imports?.find(i => i.id === "paack") ?? { id: "paack", active: false, autoCreateMembers: false};

    return <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box>
            <Typography sx={{ mb: 2}} variant="h6">{intl.formatMessage({id: 'account.imports'})}</Typography>
            <AcoountImportLine title="PAACK" importLine={paackLine} onUpdate={handleUpdateImport} />
            <LoadingButton sx={{mt: 4}} loading={saving} id="save" variant="contained" onClick={onSave}>{intl.formatMessage({id: "save"})}</LoadingButton>
        </Box>
    </Slide>
}

export default AccountImportsDetail;