import { Fragment, useState } from "react";
import { useIntl } from "react-intl";

import { Divider, FormControl, FormControlLabel, FormLabel, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SelectChangeEvent } from "@mui/material";

import { ContractForfait, ForfaitMode, PackageCost, PriceLevel } from "../../models/Contract";
import { handleDateChange, handleSelectChange } from "../../functions/fieldChangeHandlers";
import { TimePicker } from "@mui/x-date-pickers";
import { VehicleKind } from "../../models/Vehicle";


interface IProps {
  idx: number
  handleUpdate: (idx: number, forfait: ContractForfait) => void
  handleDelete: (idx: number) => void
  forfait: ContractForfait
}

const ContractForfaitItem = ({handleUpdate, handleDelete, forfait, idx} : IProps) => {
    const intl = useIntl();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      forfait.name = event.target.value;
      handleUpdate(idx, forfait);
    }

    const handleChangeForfaitKind = (event: SelectChangeEvent) => {
      forfait.kind = event.target.value as ForfaitMode;
      handleUpdate(idx, forfait);
    }

    const handleChangeVehicleKind = (event: SelectChangeEvent) => {
      forfait.vehicleKind = event.target.value as VehicleKind;
      handleUpdate(idx, forfait);
    }

    const handleChangeStartTime = (date: Date | null) => {
      forfait.startTime = date ?? undefined;
      handleUpdate(idx, forfait);
    }

    const handleChangeEndTime = (date: Date | null) => {
      forfait.endTime = date ?? undefined;
      handleUpdate(idx, forfait);
    }
    
    const handleChangeLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
        forfait.level = val;
        handleUpdate(idx, forfait);
    }
    
    const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
        forfait.amount = val ?? 0;
        handleUpdate(idx, forfait);
    }
    
    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value != '' ? (event.target.value as unknown) as number: undefined;
        forfait.quantity = val;
        handleUpdate(idx, forfait);
    }

    return (
      <Fragment>
        {idx !== 0 && <Divider sx={{mt: 3}} variant="middle" textAlign="right">
          <IconButton aria-label="delete" size="small" onClick={() => handleDelete(idx)}>
            <RemoveCircleIcon/>
          </IconButton>
        </Divider>}
        <TextField
          margin="dense"
          id={`forfait-${idx}-name`}
          label={intl.formatMessage({ id: 'contract.forfaitName' })}
          fullWidth
          variant="standard"
          value={forfait.name}
          onChange={handleNameChange}
        />
        <FormControl>
          <FormLabel id="forfait-mode">{intl.formatMessage({ id: 'contract.forfaitMode' })}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="forfait-mode"
            name="row-forfait-mode"
            value={forfait.kind}
            onChange={handleChangeForfaitKind}
          >
            <FormControlLabel value="Contract" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.contract' })} />
            <FormControlLabel value="Tour" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.tour' })} />
            <FormControlLabel value="VehicleKind" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.vehiclekind' })} />
            <FormControlLabel value="Distance" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.distance' })} />
            <FormControlLabel value="Time" control={<Radio />} label={intl.formatMessage({ id: 'contract.forfaitMode.time' })} />
          </RadioGroup>
        </FormControl>
        {forfait.kind === "VehicleKind" && 
          <FormControl fullWidth variant="standard" sx={{mt: 1}}>
          <InputLabel>{intl.formatMessage({ id: 'vehicle.kind' })}</InputLabel>
          <Select
              id="kind"
              value={forfait.vehicleKind}
              fullWidth
              variant="standard"
              label={intl.formatMessage({ id: 'vehicle.kind' })}
              onChange={handleChangeVehicleKind}>
              <MenuItem value={'Gasoline'}>{intl.formatMessage({ id: 'vehicle.kind.gasoline' })}</MenuItem>
              <MenuItem value={'Electric'}>{intl.formatMessage({ id: 'vehicle.kind.electric' })}</MenuItem>
              <MenuItem value={'CargoBike'}>{intl.formatMessage({ id: 'vehicle.kind.cargobike' })}</MenuItem>
          </Select>
        </FormControl>
        }
        {forfait.kind === "Time" && 
          <Fragment>
            <TimePicker
              label={intl.formatMessage({ id: 'activity.startTime' })}
              value={forfait.startTime === undefined ? null : forfait.startTime}
              onChange={handleChangeStartTime}
              openTo="hours"
              renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
            />
            <TimePicker
              renderInput={(props: any) => <TextField variant="standard" fullWidth {...props} />}
              label={intl.formatMessage({ id: 'activity.endTime' })}
              value={forfait.endTime === undefined ? null : forfait.endTime}
              openTo="hours"
              onChange={handleChangeEndTime}
            />
          </Fragment>
        }
        {forfait.kind === "Distance" && 
          <Fragment>
            <TextField
              margin="dense"
              id="forfait"
              label={intl.formatMessage({ id: 'contract.forfait.distanceQty' })}
              type="number"
              fullWidth
              variant="standard"
              value={forfait.quantity}
              onChange={handleChangeQuantity}
              InputProps={{
                endAdornment: <InputAdornment position="end">km</InputAdornment>,
              }}
            />
            <TextField
              margin="dense"
              id="forfait"
              label={intl.formatMessage({ id: 'contract.forfait.distanceFrom' })}
              type="number"
              fullWidth
              variant="standard"
              value={forfait.level}
              onChange={handleChangeLevel}
              InputProps={{
                endAdornment: <InputAdornment position="end">km</InputAdornment>,
              }}
            />
          </Fragment>
        }
        
        <TextField
              margin="dense"
              id="forfait"
              label={intl.formatMessage({ id: 'contract.forfait.amount' })}
              type="number"
              fullWidth
              variant="standard"
              value={forfait.amount}
              onChange={handleChangeAmount}
            />

      </Fragment>);
}

export default ContractForfaitItem;