import { ApiKey } from "../models/ApiKey";

export class UserService
{
    static async get() {
        const response = await fetch(`/api/apikey`);
        return await response.json() as ApiKey[];
    }

    static async addKey(data: ApiKey) {
        const response = await fetch(`/api/apikey`, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        return await response.json() as ApiKey;
    }
    
    static async deleteKey(id: string) {
        const response = await fetch(`/api/user/${id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
        });

        return response;
    }
}

export default UserService;