import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";

import { Avatar, Box, Button, CardActions, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import PeopleIcon from '@mui/icons-material/People';

import { PeopleDeliveryStats, PeopleStat } from "../../models/Stats";
import { Period } from "../../models/Period";
import { getStartDate } from "../../functions/dateUtils";
import { ccyFormat } from "../../functions/formaters";

interface IProps {
    title: string;
    stats: PeopleStat[];
}

const EmployeeCard = ({stats, title}: IProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const navigate = useNavigate();
    
    const sortedResults = stats.sort((a, b) => b.deliveryCount - a.deliveryCount);

    return (
        <Card sx={{minWidth: 250}}>
            <CardHeader sx={{backgroundColor: theme.palette.common.white}}
                title={<Typography gutterBottom variant="h5" sx={{color: theme.palette.primary.main}}>{title}</Typography>}
            />
            <CardContent>
                {sortedResults.length == 0 ? <Typography>{intl.formatMessage({id: "nodata"})}</Typography> : <></>}
                <Table size="small" aria-label="simple table">
                    <TableBody>
                    {sortedResults.map((row) => {
                        return (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{minWidth: 40}}>
                                    <Button onClick={() => navigate(`/people/${row.id}`)} variant="text"><Typography>{row.name}</Typography></Button>
                                </TableCell>
                                <TableCell sx={{minWidth: 35}} align="right">
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{row.deliveryCount} ({ccyFormat(row.associatedCa)})</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                    )})}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default EmployeeCard;
