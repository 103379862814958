import { Bonus, People } from "../models/People";
import { PeopleStats } from "../models/People";


export class PeopleService
{
    static async get() {
        const response = await fetch(`/api/people`);
        return await response.json() as People[];
    }

    static async getById(id: string, date: Date) {
        const response = await fetch(`/api/people/` + id + '?date=' + date.toISOString());
        return await response.json() as PeopleStats;
    }


    static async addPeople(data: People) {
        await fetch("/api/people", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }

    static async editPeople(data: People) {
        await fetch("/api/people", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deletePeople(peopleId: string) {
        await fetch("/api/people/" + peopleId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }

    static async addBonus(data: Bonus) {
        await fetch("/api/people/bonus", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    }
    
    static async deleteBonus(bonusId: string) {
        await fetch("/api/people/bonus" + bonusId, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        });
    }

}

export default PeopleService;