import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { handleNumberChange, handleDateChange,  handleTextChange, handleSelectChange } from "../../functions/fieldChangeHandlers";
import { Expense, ExpenseKind } from "../../models/Expense";
import ExpenseService from "../../services/ExpenseService";
import { TransitionUp } from "../../components/Transition";
import { AsyncButton }  from "../../components/AsyncButton";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  expense?: Expense
}

const ExpenseDialog = ({ open, handleClose, expense }: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [name, setName] = useState<string>('');
  const [kind, setKind] = useState<ExpenseKind>('Unknown');
  const [amount, setAmount] = useState<number | undefined>(undefined);
  
  useEffect(() => {
    if(expense !== undefined){
      setSelectedDate(expense.date);
      setName(expense.name);
      setKind(expense.kind);
      setAmount(expense.amount);
    } else {
      razFields();
    }
  }, [expense])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    if(selectedDate === undefined)
    {
      return;
    }

    if (expense) {
      return ExpenseService.editExpense({
        id: expense.id,
        clientId: expense.clientId,
        date: selectedDate,
        kind: kind,
        name: name,
        amount: amount ?? 0,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
    else {
      return ExpenseService.addExpense({
        id: '',
        clientId: '',
        date: selectedDate,
        kind: kind,
        name: name,
        amount: amount ?? 0,
        deleted: false
      }).then(() => {
        razFields();
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setSelectedDate(new Date());
    setName('');
    setKind('Unknown');
    setAmount(undefined);
  }
  
  return (<Dialog 
      open={open} 
      onClose={handleDismiss} 
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: expense !== undefined ? 'expense.edit' :'expense.add' })}</DialogTitle>
    <DialogContent sx={{marginTop: 2}}>
      <MobileDatePicker
        label={intl.formatMessage({ id: 'expense.date' })}
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange(setSelectedDate)}
        renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
      />
      <TextField
        margin="dense"
        id="name"
        label={intl.formatMessage({ id: 'expense.name' })}
        fullWidth
        variant="standard"
        value={name}
        onChange={handleTextChange(setName)}
      />
      <FormControl fullWidth variant="standard" sx={{mt: 1}}>
        <InputLabel>{intl.formatMessage({ id: 'expense.kind' })}</InputLabel>
        <Select
            id="kind"
            value={kind}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'expense.kind' })}
            onChange={handleSelectChange(setKind)}>
            <MenuItem value={'Unknown'}>{intl.formatMessage({ id: 'expense.kind.unknown' })}</MenuItem>
            <MenuItem value={'Supply'}>{intl.formatMessage({ id: 'expense.kind.supply' })}</MenuItem>
            <MenuItem value={'Maintenance'}>{intl.formatMessage({ id: 'expense.kind.maintenance' })}</MenuItem>
            <MenuItem value={'Storage'}>{intl.formatMessage({ id: 'expense.kind.storage' })}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        id="amount"
        label={intl.formatMessage({ id: 'expense.amount' })}
        fullWidth
        type="number"
        variant="standard"
        value={amount}
        onChange={handleNumberChange(setAmount)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <AsyncButton onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</AsyncButton>
    </DialogActions>
  </Dialog>);
}

export default ExpenseDialog;