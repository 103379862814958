export class SupportService
{
    static async send(title: string, message: string) {
        await fetch("/api/support", {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                title: title,
                message: message
            })
        });
    }
}

export default SupportService;