import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import AppLayout from '../containers/Layout/AppLayout';
import { UserContext } from '../functions/userContext';
import { SubscriptionProduct } from '../models/Account';
import { Role } from '../models/User';

interface IProps {
    subscriptions?: SubscriptionProduct[];
    roles?: Role[];
}

const ProtectedRoute = ({subscriptions, roles}: IProps) => {
    const location = useLocation();
    
    const user = useContext(UserContext);

    //   console.log("authLogin", authLogin);
    const isLoggedIn = user != null && user.isLoggedIn
    const hasSubscription = subscriptions && subscriptions.length > 0 ? subscriptions.includes(user?.subscriptionProduct ?? "trial") : true
    const hasRole = roles && roles.length > 0 ? 
        (roles.findIndex(r => user?.roles.includes(r)) >= 0) :
        true

    const hasDahboardAccess = user?.roles.includes("Administrator") || user?.roles.includes("Manager")
    const loggedInRedirect = hasDahboardAccess ? "/" : "/activity"

    return isLoggedIn && hasSubscription && hasRole
        ? <Outlet />
        : <Navigate to={isLoggedIn ? loggedInRedirect : "/login"} replace state={{ from: location }} />;
}

export default ProtectedRoute;