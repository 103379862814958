import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { handleTextChange, handleSelectChange } from "../../functions/fieldChangeHandlers";
import { TransitionUp } from "../../components/Transition";
import { Role, User } from "../../models/User";
import UserService from "../../services/UserService";
import LoadingButton from "@mui/lab/LoadingButton";

interface IProps {
  open: boolean;
  handleClose: (result: boolean) => void
  user?: User
}

const AddUserDialog = ({ open, handleClose, user}: IProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [saving, setSaving] = useState(false);
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<Role>('Contributor');

  useEffect(() => {
    if(user){
      setName(user.fullname);
      setEmail(user.email);
      setRole(user.roles.length > 0 ? user.roles[0] : 'Contributor');
    } else {
      razFields();
    }
  }, [user])

  const handleDismiss = () => {
    handleClose(false);
  }

  const handleValidate = () => {
    setSaving(true);
    if (user) {
      return UserService.editUserRole({
        email: email,
        fullName: name,
        role: role
      }).then(() => {
        razFields();
        setSaving(false);
        handleClose(true);
      });
    }
    else {
      return UserService.addUser({
        email: email,
        fullName: name,
        role: role
      }).then(() => {
        razFields();
        setSaving(false);
        handleClose(true);
      });
    }
  }

  const razFields = () => {
    setName('');
    setEmail('');
    setRole('Contributor');
  }

  return (<Dialog 
    open={open}
    onClose={handleDismiss}
    fullScreen={fullScreen}
    TransitionComponent={fullScreen ? TransitionUp : undefined}>
    <DialogTitle>{intl.formatMessage({ id: user !== undefined ? 'user.edit' :'user.add' })}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        disabled={user !== undefined}
        margin="dense"
        id="name"
        label={intl.formatMessage({ id: 'user.name' })}
        fullWidth
        variant="standard"
        value={name}
        autoComplete='off'
        onChange={handleTextChange(setName)}
      />
      <TextField
        disabled={user !== undefined}
        margin="dense"
        id="email"
        label={intl.formatMessage({ id: 'user.email' })}
        fullWidth
        variant="standard"
        autoComplete='off'
        value={email}
        onChange={handleTextChange(setEmail)}
      />
      <FormControl fullWidth variant="standard" sx={{mt: 1}}>
        <InputLabel>{intl.formatMessage({ id: 'user.role' })}</InputLabel>
        <Select
            id="role"
            value={role}
            fullWidth
            variant="standard"
            label={intl.formatMessage({ id: 'user.role' })}
            onChange={handleSelectChange(setRole)}>
            <MenuItem value={'Administrator'}>{intl.formatMessage({ id: 'user.role.administrator' })}</MenuItem>
            <MenuItem value={'Manager'}>{intl.formatMessage({ id: 'user.role.manager' })}</MenuItem>
            <MenuItem value={'Contributor'}>{intl.formatMessage({ id: 'user.role.contributor' })}</MenuItem>
            <MenuItem value={'Member'}>{intl.formatMessage({ id: 'user.role.member' })}</MenuItem>
        </Select>
      </FormControl>
      <Typography>{intl.formatMessage({ id: `user.role.explain.${role.toLocaleLowerCase()}` })}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss}>{intl.formatMessage({ id: 'cancel' })}</Button>
      <LoadingButton loading={saving} onClick={handleValidate}>{intl.formatMessage({ id: 'ok' })}</LoadingButton>
    </DialogActions>
  </Dialog>);
}

export default AddUserDialog;