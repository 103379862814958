import { Fragment, useEffect, useState } from "react";

import PeopleInvoiceDialog from "./PeopleInvoiceDialog";
import { Contract } from "../../models/Contract";
import ContractService from "../../services/ContractService";
import InvoiceService from "../../services/InvoiceService";

interface IProps {
    open: boolean;
    handleClose: (result: boolean) => void;
    peopleId?: string;
}

const PeopleInvoicePopup = ({peopleId, open, handleClose}: IProps) => {
    const selectedDate = new Date();

    const [contracts, setContracts] = useState<Contract[]>([]);

    useEffect(() => {
        ContractService.get().then(setContracts);
    }, []);

    const handleInvoiceClose = (result: boolean, invoiceId?: string) => {
        handleClose(result)

        if (invoiceId) {
            InvoiceService.getData(invoiceId)
        }
    }

    if (peopleId) {
        return <PeopleInvoiceDialog open={open} peopleId={peopleId} initialDate={selectedDate} contracts={contracts} handleClose={handleInvoiceClose} />;
    }
    else {
        return <Fragment />
    }

}


export default PeopleInvoicePopup;