import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, Slide, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import UserService from '../../services/UserService';
import { handleTextChange } from '../../functions/fieldChangeHandlers';

const theme = createTheme();

const ForgotPasswordPage = () => {
    const intl = useIntl();

    const [email, setEmail] = useState<string>('');
    const [sent, setSent] = useState(false);

    const handleSubmit = () => {
        setSent(true);
        UserService.forgotPassword({
            email: email
        });
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    m: 1,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {intl.formatMessage({id: 'forgotpassword'})}
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label={intl.formatMessage({id: "email"})}
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleTextChange(setEmail)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={sent}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {sent ? intl.formatMessage({id: 'sent'}) : intl.formatMessage({id: 'reset'})}
                    </Button>
                </Box>
            </Box>
        </Slide>
    );
}

export default ForgotPasswordPage;